import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { IChoice } from '../../types';

interface ISelectChoice {
  key: string;
  label: string;
}

interface ISelectDropdownPROPS {
  choices: IChoice[];
  className?: string;
  classNameContainer?: string;
  classNameInput?: string;
  classNameInputContainer?: string;
  classNameLabel?: string;
  label?: string;
  onChange: (choice: IChoice) => void;
  placeholder?: string;
  selectedChoice: IChoice | null;
}

/**
 * -----------------------------------------------------------------------------
 * This provides options for selecting a choice from a list.
 * It replaces the native unstyled dropdown component.
 * NOTE: By default it is styled to appear inline.
 */
export function ChoicesSelectDropdown({
  choices,
  className = '',
  classNameContainer = '',
  classNameInput = '',
  classNameInputContainer = '',
  classNameLabel = '',
  label,
  onChange,
  placeholder,
  selectedChoice,
}: ISelectDropdownPROPS) {
  const PLACEHOLDER_CHOICE: IChoice = {
    key: '',
    label: placeholder ?? '... Select',
  };

  const [selectedItem, setSelectedItem] = useState(
    selectedChoice ?? PLACEHOLDER_CHOICE
  );

  const placeholderClassName = !selectedItem.key ? 'text-gray-400' : '';

  const handleOnSelect = (item: ISelectChoice) => {
    setSelectedItem(item);
    onChange(item);
  };

  return (
    <Listbox value={selectedItem} onChange={handleOnSelect}>
      {({ open }) => (
        <div className={classNameContainer}>
          {label ? (
            <Listbox.Label
              className={`block text-sm text-gray-700 ${classNameLabel}`}
            >
              {label}
            </Listbox.Label>
          ) : null}
          <div className={`relative mt-2 ${classNameInputContainer}`}>
            <Listbox.Button
              className={`
                relative -mt-1.5 w-40 cursor-default border-l border-gray-100
                bg-white/10 py-1.5 pr-3 text-left text-sm  text-gray-900 ring-1
                ring-inset ring-gray-50 focus:outline-none focus:ring-1
                focus:ring-red-50 sm:text-xs sm:leading-6
                ${className}
              `}
            >
              <span
                className={`
                  block truncate ps-4 rtl:text-right
                  ${classNameInput} ${placeholderClassName}
                `}
              >
                {selectedItem.label}
              </span>
              <span
                className="
                  pointer-events-none absolute inset-y-0 flex items-center pr-2
                  ltr:right-0 rtl:left-0
                "
              >
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="
                  absolute z-10 mt-1 max-h-60 w-full overflow-auto
                  rounded-b-lg bg-white py-1 text-xs shadow-md shadow-gray-200
                  ring-1 ring-gray-700 ring-opacity-5 focus:outline-none
                "
              >
                {choices.map((choice) => (
                  <Listbox.Option
                    key={choice.key}
                    className="
                      ui-active:bg-red-100  ui-active:text-red-900 relative
                      cursor-default select-none py-2 pl-2 pr-4 text-left text-gray-900
                      transition-all duration-300 ease-linear rtl:text-right
                    "
                    value={choice}
                  >
                    <>
                      <span className="ui-selected:font-medium block truncate font-normal">
                        {choice.label}
                      </span>
                      <span
                        className="
                          ui-active:text-red-700 ui-selected:flex absolute inset-y-0
                          hidden items-center justify-end pl-1.5 pr-1.5 ltr:right-0 rtl:left-0
                        "
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    </>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
