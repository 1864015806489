import Lottie from 'lottie-react';

import securityPasswordLockAnimationFile from './raw-json/security-password-lock.json';

interface ISecurityPasswordLockAnimationProps {
  autoplay: boolean;
}

export function SecurityPasswordLockAnimation({
  autoplay,
}: ISecurityPasswordLockAnimationProps) {
  return (
    <div className="w-full">
      <Lottie
        animationData={securityPasswordLockAnimationFile}
        autoplay={autoplay}
        className="flex h-72 w-full"
        loop
      />
    </div>
  );
}
