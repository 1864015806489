export function stringHashToHue(str: string) {
  let sum = 0;
  for (let i = 0; i < str.length; i += 1) {
    sum += (str.codePointAt(i) || 0) * i;
  }
  return sum % 360;
}

export function HSLToHex(hue: number, sat: number, lig: number): string {
  const s = sat / 100;
  const l = lig / 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (hue >= 0 && hue < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (hue >= 60 && hue < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (hue >= 120 && hue < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (hue >= 180 && hue < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (hue >= 240 && hue < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (hue >= 300 && hue < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let _r = Math.round((r + m) * 255).toString(16);
  let _g = Math.round((g + m) * 255).toString(16);
  let _b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (_r.length === 1) _r = `0${r}`;
  if (_g.length === 1) _g = `0${g}`;
  if (_b.length === 1) _b = `0${b}`;

  return `#${_r}${_g}${_b}`.toUpperCase();
}
