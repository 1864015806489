import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * An illustration showing a team of people pulling up a growth arrow.
 */
export function IllustrationTeamworkPullingUp(props: TSvgComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 340"
      xmlSpace="preserve"
      width="25em"
      height="21.25rem"
      id="create-org-channel-illustration"
      {...props}
    >
      <style>
        {
          '.st0{fill:#ffebeb}.st3{fill:#5f0224}.st4{fill:#bf4883}.st5{fill:#dc2626}.st6{fill:#f99680}.st7{fill:#212121}.st9{fill:#fff}.st10{fill:#f4d9e6}.st11{fill:#a80038}.st13{fill:#f5e6ed}.st15{fill:#8cddd9}.st17{fill:#ea6591}.st18{fill:#bc2a5f}.st21{fill:#9e5959}.st22{fill:#474f50}.st23{fill:#1e706e}.st24{fill:#343a3a}.st25{fill:#ffbbb0}'
        }
      </style>
      <g id="background">
        <path
          d="M341.6 91.2c-8.3 0-15.2 6.9-16 15.9-.3 3.3-.4 6.6-.4 10 0 24.8 6.8 55 21.4 55 17 0 17.1-26 17.1-29.7.1-9.8-3-51.2-22.1-51.2z"
          className="st0"
        />
        <circle cx={319.3} cy={190.2} r={60.4} className="st0" />
        <path
          d="M205.4 71.9c-17.6 0-32.9 1.2-32.9 1.2-56.9 2.1-118.8 30.3-118.8 73.3 0 78.2 117.7 64.6 147.8 102.3 14.5 18.1 46.6 33.3 76 33.3 64.4 0 60-66.6 60-68.6.1-46.3-27.1-141.5-132.1-141.5z"
          className="st0"
        />
      </g>
      <g id="people-pulling">
        <path
          fill="#fd0054"
          d="M122.9 284.6c-.4-1.9-.5-3.8-.5-5.7 0-6.6 1.8-17.7 12-27.2 0 0 .1 1.7.1 4.4 0 7-.9 20.8-7.7 28.2l-3.9.3z"
        />
        <defs>
          <path
            id="SVGID_1_"
            d="M122.9 284.6c-.4-1.9-.5-3.8-.5-5.7 0-6.6 1.8-17.7 12-27.2 0 0 .1 1.7.1 4.4 0 7-.9 20.8-7.7 28.2l-3.9.3z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_)">
          <path
            d="m129.7 263.7.9-8.3h.4l-.7 6.6 4-6.3.3.2-4.9 7.8z"
            className="st3"
          />
          <path
            d="m127.5 270.6-1.4-8.2.4-.1 1.3 7.6 6-4.6.2.3-6.5 5z"
            className="st3"
          />
          <path
            d="m125.5 279.1-1.7-11 .4-.1 1.6 10.3 6.6-5.4.2.3-7.1 5.9z"
            className="st3"
          />
          <path
            d="M124.7 284.9v-.5c.7-12.7 9.4-32.6 9.5-32.8l.3.2c-.1.2-8.5 19.6-9.4 32.2l4.4-3.9.2.3-5 4.5z"
            className="st3"
          />
          <path d="m124.8 284.5-2.5-5.6.2-.1 2.5 5.6-.2.1z" className="st4" />
        </g>
        <path
          d="M146.8 197.1c2.3.1 6.5 0 8.3 4l-6.1 5.5-4.8-8.6 2.6-.9z"
          className="st5"
        />
        <path
          d="m142.9 200.5-.6-3.5s-1.1-.4-1.1-1.4c0-.5.2-1 .7-1-.3-.5-.4-1-.4-1.6 0-1.6 1.2-2.9 2.6-2.9 1.2 0 2.3.9 2.6 2.2 0 .1 0 .2.1.3.1.9.2 1.8.2 2.6 0 2.5-.4 2.6-.6 2.6h-.9l1.3 4.3-3.9-1.6z"
          className="st6"
        />
        <path d="m145.4 198-1.4-.3 2 1.3-.6-1z" className="st5" />
        <path
          d="M145.3 195.8c-.5 0-.9-.6-1-1.4v-.2c0-.5.2-1.4.9-1.4.6 0 1 .7 1 1.6-.1.6-.3 1.4-.9 1.4zm-.2-2.5c-.2 0-.5.2-.5.9v.2c.1.6.3 1 .6 1 .2 0 .5-.3.5-1 0-.6-.3-1.1-.6-1.1zm1.9 2.7c-.5 0-.5-1.4-.5-1.6 0-.2 0-1.4.5-1.4s.5 1.4.5 1.6c-.1.2-.1 1.4-.5 1.4zm-.2-1.6c0 .3 0 .7.1 1 .1-.3.1-.6.1-.9 0-.3 0-.7-.1-1 0 .4-.1.6-.1.9z"
          className="st3"
        />
        <path
          d="m145.9 194.5.1-.4.7.1-.1.4-.7-.1zm-4.2-.6.1-.4 2.7.5-.1.4-2.7-.5z"
          className="st3"
        />
        <path
          d="M146.7 196.6c2.3.1 6.5-.1 8.3 4l-6 6-5-9.1 2.7-.9z"
          className="st7"
        />
        <path
          d="m142.8 200-.6-3.5s-1.1-.4-1.1-1.4c0-.5.2-1 .7-1-.3-.5-.4-1-.4-1.6 0-1.6 1.2-2.9 2.6-2.9 1.2 0 2.3.9 2.6 2.2 0 .1 0 .2.1.3.1.9.2 1.8.2 2.6 0 2.5-.4 2.6-.6 2.6h-.9l1.3 4.3-3.9-1.6z"
          className="st6"
        />
        <path
          d="M146.6 192.2c0 .2-.2.4-.4.4-.5 0-.9-.6-1.8-.6h-.2c-.2 0-.4.2-.4.4v.2c0 .3-.1.6-.4.7-.7.4-.5 1.7-.9 1.7-.3 0-.7-1-.7-1-.1 0-.7 0-.7.9 0 1 1.1 1.5 1.1 1.5s.6 3.2.6 3.8c0 2.2-2.5 14.1-2.5 14.1l-11.5 2.1s-4.2-1.4-4.2-5.1c0-.6.1-1.2.3-1.7 1.7-5.3 7-3.4 7-6.2 0-1.3-1.5-1.6-1.5-2.7 0-.9.8-1.1 2.4-1.1h1.1c1.9 0 2-.4 2-1.1 0-.9-.3-2.4-.3-3.9 0-5.8 5.7-5 9-6.9.5-.3 1.1-.5 1.7-.5 1.5 0 2.8 1.4 2.8 3.1v.2c0 1.8-.8 3.5-2.2 4.4v-1.3c-.1-.2-.3-1.4-.3-1.4z"
          className="st7"
        />
        <path fill="#e27f6f" d="m145.3 197.4-1.4-.3 1.6.8-.2-.5z" />
        <path
          d="M145.1 195.3c-.5 0-.9-.6-1-1.4v-.2c0-.5.2-1.4.9-1.4.6 0 1 .8 1 1.6 0 .6-.2 1.4-.9 1.4zm-.6-1.4c.1.6.4 1 .6 1 .4 0 .5-.8.5-.9 0-.8-.4-1.2-.6-1.2-.4 0-.5.8-.5 1v.1zm2.3 1.6c-.3 0-.5-.7-.5-1.5v-.1c0-.4 0-1.4.5-1.4.2 0 .5.2.5 1.6 0 .2 0 1.4-.5 1.4zm-.1-1.5c0 .3 0 .6.1.9.1-.3.1-.6.1-.9 0-.3 0-.7-.1-1 0 .3-.1.6-.1 1z"
          className="st3"
        />
        <path
          d="m145.8 193.9.1-.4.7.1-.1.4-.7-.1zm-4.2-.6.1-.4 2.7.5-.1.4-2.7-.5z"
          className="st3"
        />
        <path
          d="M160.9 196.1s-11 4.9-13.8 4.9c-2.8 0-5.3-2.7-5.3-2.7-.4-.1-.9-.2-1.3-.2-1.2 0-3.1.5-4.7 3-2.6 4.1-6.8 9.5-7.5 17.6l15.5.1c-.5-1.5-.7-3.1-.8-4.7 0-7.7 7-6 18.7-12.9l-.8-5.1z"
          className="st9"
        />
        <path
          d="m146.2 200.6 13.9-4.8.1.6s-8.6 4.9-12.8 4.9c-.4 0-.8-.1-1.2-.2v-.5z"
          className="st9"
        />
        <path
          d="M146.3 200.9c-2.2-.1-4.5-2.6-4.5-2.6l.5-.7.2.2c.8 1.1 1.9 2 3.2 2.5l.5.2.1.4zm-18 17.8c-.1.5-.1 1.1-.1 1.6 0 6.9 7 14.9 17.5 23.6 1.8 2.7 3.9 14.1 4.4 14.1h7.4c.1 0 .2-.1.2-.2s-4.4-15.3-5.4-17.2c-1.1-2-5.5-8-5.5-8s9.6 5.4 10.3 6.7c1.4 2.7 4.3 13 5.1 13h7.9c.1 0 .2-.1.2-.2s-6.7-17.5-7.6-19.3c-1-2-16.5-10.1-18.7-14l-15.7-.1z"
          className="st5"
        />
        <path
          d="m139.8 213.4-.4-.1c0-.1.4-1.4 3.8-3.7l.2.4c-3.1 2.1-3.6 3.3-3.6 3.4z"
          className="st3"
        />
        <path
          d="M145.5 231.2c-2.1-2.9-4.1-5.3-4.2-5.3l-.1-.1.8-2.5.4.1-.7 2.3c.4.5 2.2 2.6 4.1 5.2l-.3.3z"
          className="st10"
        />
        <path
          d="m153.2 258 .2 1.3h3.6l-.3-1.3h-3.5zm12.6-5.8.4 1.7h4.1l-.6-1.7h-3.9z"
          className="st6"
        />
        <path
          d="m153.4 259.3-.4 5.3c0 .1.1.1.1.1h12.1c.2-.3.3-.6.4-1 0-1.4-7.6-2-8.5-4.5h-3.7zm12.8-5.4-.4 5.3c0 .1.1.2.2.2h12c.2-.3.3-.6.4-1 0-1.4-7.1-2-8-4.5h-4.2z"
          className="st5"
        />
        <path
          d="M147.3 201.5h-.2v-.4h.1c1.7 0 8.8-2.6 11.5-4.1l.2.4c-2.8 1.5-9.8 4.1-11.6 4.1z"
          className="st3"
        />
        <path
          d="M161 196.7s1.3-1.5 1.9-1.5c.3 0 .6.3.9.3.4 0 1-.6 1.2-.6 0 0 .3-.1.3 1.4 0 1-.2 2.4-1.2 2.9-.8.4-1.6.6-2.4.7h-.1l-.6-3.2z"
          className="st6"
        />
        <path
          d="M160.2 196c.6-.6 1.3-.9 2-1.1.6 0 1.2.2 1.5.2h.2c.1 0 .3.1.3.3l-3.1 1.7-.2-1.1-.7.3v-.3z"
          className="st6"
        />
        <path
          d="M117.7 284c-.6-2.6-.9-5.3-.8-8 0-7.4 2-18.9 12.1-29.2.3 2.9.4 5.7.4 8.6 0 8.3-1.2 20.7-7.4 28.2l-4.3.4z"
          className="st11"
        />
        <defs>
          <path
            id="SVGID_3_"
            d="M117.7 284c-.6-2.6-.9-5.3-.8-8 0-7.4 2-18.9 12.1-29.2.3 2.9.4 5.7.4 8.6 0 8.3-1.2 20.7-7.4 28.2l-4.3.4z"
          />
        </defs>
        <clipPath id="SVGID_4_">
          <use xlinkHref="#SVGID_3_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_4_)">
          <path
            d="m124.5 259.9.6-8.8h.2l-.5 8 4.5-7.7.2.1-5 8.4zm-2.2 8.1-1.9-8.9h.2l1.9 8.6 6.6-5.6.1.2c0-.1-6.9 5.7-6.9 5.7z"
            className="st4"
          />
          <path
            d="m120.4 277.5-2.3-11.9h.2l2.3 11.6 7.2-6.5.1.2-7.5 6.6z"
            className="st4"
          />
          <path
            d="M119.8 284v-.2c.2-14.1 9-36.8 9-37l.2.1c-.1.2-8.7 22.6-9 36.7l5-4.8.1.2-5.3 5z"
            className="st4"
          />
          <path d="m116.8 277.8.2-.1 3.1 6.1-.2.1-3.1-6.1z" className="st4" />
        </g>
        <path
          d="M118.2 285c-3.4-6.2-5.2-13.3-5.2-20.5.1-7.4 1.8-14.7 5.2-21.2 0 0 6.3 14.1 6.3 27.8.1 4.1-.6 8.1-2 11.8l-4.3 2.1z"
          className="st13"
        />
        <defs>
          <path
            id="SVGID_5_"
            d="M118.2 285c-3.4-6.2-5.2-13.3-5.2-20.5.1-7.4 1.8-14.7 5.2-21.2 0 0 6.3 14.1 6.3 27.8.1 4.1-.6 8.1-2 11.8l-4.3 2.1z"
          />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlinkHref="#SVGID_5_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_6_)">
          <path
            d="m117.8 258.1-2.1-9.1h.2l1.9 8.2 2.2-9.4.2.1-2.4 10.2zm.2 9-4.7-8.2.2-.1 4.5 8 5-8.1.2.1-5.2 8.3z"
            className="st4"
          />
          <path
            d="m119 277.4-6-11.2.2-.1L119 277l5.3-9.3.2.1-5.5 9.6z"
            className="st4"
          />
          <path
            d="m120.4 284.2-.1-.2c-1.1-3.9-2.6-11.7-2.6-26.4 0-8 .5-14.2.5-14.3h.2c0 .1-.5 6.3-.5 14.3 0 14.6 1.5 22.4 2.6 26.2l3.6-6.6.2.1-3.9 6.9z"
            className="st4"
          />
          <path d="m115.4 279 .1-.2 4.9 5-.1.2-4.9-5z" className="st4" />
        </g>
        <path
          d="m37.3 271.4 4-4c1.2-4.2 1.8-8.5 1.9-12.9 0-2.3-.2-4.6-.6-6.9-3.1.7-6.2 1.8-9.1 3.2l-2.1 5.1-1-3.3c-2.2 1.3-4.2 3.1-5.8 5.2l1.2 6.9-2.9-3.7c-1.2 3.5-1.8 17.5-1.8 25.7v2h10.1c3.4-4.9 6.2-10.2 8.3-15.8l-2.2-1.5z"
          className="st13"
        />
        <path
          d="M34.6 283.1c-3.2 0-4.2 2.2-7.6 2.2h-.4c3.2-4.9 5.9-10.2 8-15.8h.4c2.4 0 4.7-1 6.5-2.8.1-.2.1-.4.2-.6-1.8 1.9-4.1 3-6.6 3h-.3c.6-1.5 1.2-3.1 1.8-4.7 2.3-.3 4.6-1.9 6.2-3.3 0-.2.1-.4.1-.5-1.5 1.3-3.8 3-6.1 3.4 2.9-8 4.6-14.5 4.7-14.6l.6-1.5-.8 1c0 .1-1.5 5.4-3.7 12-.5-1.8-.8-3.6-.7-5.5 0-2.1.1-4.1.4-6.2-.1 0-.2.1-.3.1-.2 2-.3 4-.3 6 0 2.3.2 4.7.9 6-1.6 4.6-3.3 9.1-5.3 13.5-.9-1.5.6-10.8-1.8-14.5-1.1-1.8-1.6-3.9-1.6-6v-.7c-.1.1-.2.2-.3.2v.4c0 2.2.5 4.4 1.6 6.2 2.4 3.7.7 13.4 1.9 14.7-.4.9-.8 1.8-1.3 2.6-1.7-6.1-2.2-8-2.2-9.8 0-1.4.3-3 .3-4.4 0-3.7-2-1.9-2.3-8.1l-.3.3c.4 5.9 2.3 4.2 2.3 7.7 0 1.3-.3 2.9-.3 4.5 0 1-.1 1.9 2.3 10.1-.9 1.7-1.8 3.4-2.8 5-1.2-1.3-1.8-3-1.7-4.8 0-1 .1-1.3.1-2 0-3.1-2.4-6.7-4-8.9v.5c1.6 2.1 3.8 5.6 3.8 8.4 0 .6-.1 1-.1 2 0 3.2 1.5 4.7 1.8 5.1-1.1 1.8-2.3 3.4-3.7 5 .2-.3.4-.5.7-.8.4.1.8.2 1.2.2 3.3 0 3.7-2.6 6.6-2.6.3 0 .5 0 .8.1l.2-.3c-.3-.1-.6-.1-1-.1-2.9 0-3.4 2.6-6.6 2.6-.3 0-.7 0-1-.1.5-.6 1-1.3 1.5-2 .2 0 .5.1.7.1 3.2 0 4.4-2.1 7.4-2.2l.1-.1z"
          className="st15"
        />
        <path
          fill="#f2ebee"
          d="M298.2 292.3v-1.1l-2.6 1.3 2.4-4.1c-.6-5.1-4.3-13.8-4.3-13.8-1.5 1.5-2.9 3.2-4.1 5.1l1.1 3.2-2.2-1.3c-2 3.9-3.1 8.4-3 12.9.1 5.8 1.4 11.4 3.9 16.5l2.4 1.1s5.6-11.5 6.2-15.6c.1-1.4.2-2.8.2-4.2z"
        />
        <path
          d="M297.6 298c.1-.3.1-.5.2-.7-1.1 1.4-4 4.6-5.8 5v-.6c1.7 0 4.4-3.6 6-5.7 0-.2 0-.4.1-.6-1.6 2.4-4.4 6-6 6v-1.1c0-2.9-.3-2.8-.3-5.7v-1.5c1.7-.5 4.4-4.8 5.8-7 0-.1-.1-.3-.1-.4-1.3 2.1-4.1 6.5-5.7 7.1 0-1.2.1-2.4.2-3.7 1.8-1.3 3.6-5.1 4.4-6.9 0-.1-.1-.3-.1-.4-.7 1.6-2.5 5.4-4.2 6.9.6-6.4 1.6-13.4 1.6-13.5v-.3l-.1.2c-.9 5.2-1.6 10.5-2 15.8-2.4-.2-4.1-2.9-4.9-4.6 0 .2-.1.3-.1.5.8 1.6 2.5 4.3 5 4.5-.1 1.1-.1 2.3-.1 3.4v1.7c-2.2-.1-4.6-2.2-5.9-3.5v.4c3.4 3.3 5.5 3.4 6 3.4.2 1.2.2 2.5.2 3.7-.1 3.8-.6 7.6-1.4 11.3l.3.1c.7-3 1.1-6 1.3-9 1.7-.5 4.3-3.3 5.6-4.8z"
          className="st15"
        />
        <path
          d="M110.2 276.4c0 1.3 3.3 2.3 4.9 2.3.7 0 .8-.2.8-.4 0-.9-3.1-2.8-4.7-2.8-.3.1-1 .2-1 .9zm1-3.2c0 1.1 2.9 2 4.2 2 .6 0 .7-.1.7-.3 0-.7-2.6-2.4-4.1-2.4-.6 0-.8.4-.8.7zm9.7-.7c0 1.2-3.1 2.5-4.3 2.5-.4 0-.4-.2-.4-.3 0-.8 2.6-2.9 4-2.9.7 0 .7.7.7.7zm-9.6-2c0 1 2.4 1.6 3.7 1.6.9 0 .9-.3.9-.3 0-.6-2.3-1.9-3.7-1.9-.9-.1-.9.5-.9.6z"
          className="st5"
        />
        <path
          d="M119.2 268.5c0 1.2-2.5 3.4-3.3 3.4 0 0-.2 0-.2-.3 0-.9 1.9-3.7 2.9-3.7.3 0 .6.3.6.6zm-7.8-.2c1.2 0 2.9-.5 2.9-.9 0-.3-1-.6-2.2-.6-1 0-2 .3-2 .9 0 .4.6.6 1.3.6z"
          className="st5"
        />
        <path
          d="M116.5 263.8c0 1.2-1.7 3.5-2.3 3.5 0 0-.2 0-.2-.4 0-1 1.1-3.7 2-3.7.3 0 .5.2.5.6zm-6.7 1.7c1 0 2.5-.6 2.5-.9 0-.2-.5-.4-1.5-.4-.9 0-2 .2-2 .8.1.5.9.5 1 .5z"
          className="st5"
        />
        <path
          d="M113.6 261.5c0 1-1 3-1.4 3-.2 0-.2-.5-.2-.6 0-1 .5-3 1.2-3 .3 0 .4.4.4.6zm-5.9 1.6c.8 0 2.1-.6 2.1-.9 0-.1-.3-.2-.8-.2-.8 0-1.9.3-1.9.8.1.2.2.3.6.3z"
          className="st5"
        />
        <path
          d="M110.3 260.2c0 .8-.3 1.9-.5 1.9s-.4-.9-.4-1.7c0-.7.1-1.3.5-1.3s.4.7.4 1.1zm11.7 17c0 1.1-2.6 1.7-4.4 1.7-1.6 0-1.6-.4-1.6-.5 0-.8 2.9-2.1 4.6-2.1 1 0 1.4.4 1.4.9z"
          className="st5"
        />
        <path
          d="M116.3 275.5c0 1.4-.1 2.7-.5 4l-.4-.1c.3-1.3.5-2.6.4-3.9 0-3.7-1.1-9.4-6.9-13.6l.2-.3c6.1 4.2 7.2 10 7.2 13.9z"
          className="st3"
        />
        <path
          d="m22.2 250.8-3.4 4.1 1.3-8.5c-4.2-8-15.6-19.5-15.6-19.5-1.6 3.6-2.8 7.5-3.6 11.4l3.8 4.4-4.4-.5c-.2 1.8-.3 3.7-.3 5.5C0 273.1 20 290 20 290h4.7s1.1-11.8 1.1-21.1c0-5.6 0-9.6-3.6-18.1z"
          className="st15"
        />
        <path
          d="M25.6 262.3c0-.4-.1-.9-.2-1.3-1 3.1-3.6 10.5-6.5 12.5l-.3-.9c2.9-1.2 5.2-9.2 6.3-13.9-.1-.4-.2-.7-.3-1.1-1.2 5.2-3.6 13.4-6.2 14.4-2.4-7.1-3-5.8-5.6-13.5 2.5-2.1 4.3-11.1 5.2-15.9-.1-.2-.3-.4-.4-.6-.8 4.5-2.7 13.8-5 16-.7-2.1-1.3-4.2-1.9-6.3 2.1-3.5 2.8-11 3-14.6-.2-.2-.3-.4-.5-.6-.2 3.1-.8 10.7-2.7 14.5-3.1-11-5.6-23.2-5.7-23.4l-.3-.6v.5c1.7 9.3 3.9 18.4 6.5 27.5-.7.3-1.5.4-2.2.4-3.4 0-6.7-2.5-8.7-4.3 0 .3 0 .6.1.9 2.4 2.1 5.5 4.1 8.6 4.1.8 0 1.6-.1 2.4-.4.8 2.9 1.9 5.7 3 8.4-1.1.4-2.3.6-3.4.6-2.9-.1-5.8-.8-8.5-1.9.1.3.2.5.2.8 2.7 1 5.4 1.6 8.3 1.7 1.3 0 2.5-.2 3.7-.6.5 1.1 5.3 9.2 7.4 25.5h.6c-.7-5.4-1.9-10.7-3.4-15.9 2.8-2 5.2-8.4 6.5-12z"
          className="st13"
        />
        <path
          d="m286.9 284.1-2.7 3.2 1.1-6.7c-3.3-6.4-12.4-15.5-12.4-15.5-1.3 2.9-2.2 5.9-2.9 9.1l3 3.5-3.5-.4c-.2 1.5-.3 2.9-.3 4.4 0 20.2 15.9 33.7 15.9 33.7h3.8s.9-9.4.9-16.8c0-4.6-.1-7.7-2.9-14.5z"
          className="st15"
        />
        <path
          d="M289.5 293.3c0-.3-.1-.7-.1-1-.8 2.5-2.9 8.4-5.2 10-.1-.2-.2-.5-.2-.7 2.3-.9 4.1-7.3 5-11.1-.1-.3-.2-.6-.2-.9-.9 4.1-2.9 10.7-4.9 11.5-1.9-5.6-2.4-4.6-4.5-10.8 2-1.7 3.5-8.9 4.1-12.7-.1-.2-.2-.3-.3-.5-.6 3.6-2.1 11-4 12.7-.5-1.7-1.1-3.3-1.5-5 1.7-2.8 2.2-8.8 2.4-11.7-.1-.2-.3-.3-.4-.5-.1 2.5-.6 8.5-2.2 11.5-2.4-8.8-4.5-18.5-4.5-18.6l-.2-.3v.4c1.4 7.4 3.1 14.7 5.2 21.9-.6.2-1.2.3-1.8.3-2.7 0-5.4-2-6.9-3.4 0 .2 0 .5.1.7 1.9 1.7 4.4 3.2 6.9 3.2.7 0 1.3-.1 1.9-.3.7 2.3 1.5 4.5 2.4 6.7-.9.3-1.8.5-2.7.5-2.3-.1-4.6-.6-6.8-1.5.1.2.1.4.2.6 2.1.8 4.3 1.3 6.6 1.4 1 0 2-.2 3-.5.4.9 4.2 7.3 5.9 20.3h.4c-.6-4.3-1.5-8.5-2.7-12.6 2.1-1.7 4.1-6.8 5-9.6z"
          className="st13"
        />
        <path
          d="M9.8 288.8c0 1 2.7 1.9 4 1.9.5 0 .7-.1.7-.3 0-.7-2.5-2.3-3.9-2.3-.3 0-.8.1-.8.7zm.8-2.7c0 .9 2.3 1.6 3.4 1.6.5 0 .6-.1.6-.2 0-.6-2.1-2-3.3-2-.5.1-.7.4-.7.6zm7.9-.5c0 .9-2.5 2.1-3.5 2.1-.3 0-.4-.1-.4-.2 0-.6 2.1-2.4 3.2-2.4.7-.1.7.4.7.5zm-7.8-1.7c0 .8 1.9 1.3 3 1.3.7 0 .7-.2.7-.2 0-.5-1.9-1.6-3-1.6-.7 0-.7.5-.7.5z"
          className="st17"
        />
        <path
          d="M17.1 282.3c0 .9-2.1 2.7-2.7 2.7 0 0-.2 0-.2-.2 0-.7 1.5-3 2.4-3 .3 0 .5.2.5.5zm-6.4-.2c1 0 2.4-.4 2.4-.8 0-.3-.8-.5-1.8-.5-.8 0-1.7.2-1.7.7.1.5.6.6 1.1.6z"
          className="st17"
        />
        <path
          d="M14.9 278.5c0 .9-1.4 2.9-1.8 2.9 0 0-.2 0-.2-.3 0-.9.9-3 1.6-3 .2-.1.4.1.4.4 0-.1 0 0 0 0zm-5.4 1.3c.8 0 2.1-.5 2.1-.8 0-.2-.4-.3-1.2-.3-.8 0-1.6.2-1.6.7-.1.5.6.4.7.4z"
          className="st17"
        />
        <path
          d="M12.5 276.6c0 .8-.8 2.4-1.1 2.4-.2 0-.2-.4-.2-.5 0-.8.4-2.4 1-2.4.3 0 .3.3.3.5zm-4.7 1.3c.6 0 1.7-.5 1.7-.8 0-.1-.2-.2-.6-.2-.6 0-1.5.2-1.5.6-.1.3 0 .4.4.4z"
          className="st17"
        />
        <path
          d="M9.9 275.5c0 .7-.2 1.6-.4 1.6s-.3-.7-.3-1.4c0-.5.1-1 .4-1 .3 0 .3.5.3.8zm9.5 13.9c0 .9-2.1 1.4-3.5 1.4-1.3 0-1.3-.3-1.3-.4 0-.6 2.3-1.7 3.8-1.7.7 0 1 .3 1 .7z"
          className="st17"
        />
        <path
          d="M14.8 288c0 1.1-.1 2.2-.4 3.3l-.4-.1c.2-1 .4-2.1.4-3.2 0-4.5-2.1-8.7-5.6-11.1l.2-.3c3.7 2.4 5.8 6.7 5.8 11.4z"
          className="st3"
        />
        <path
          d="M377.8 290.1s-5.8-4.5-5.8-8.8 4.8-5.7 4.8-10.8-2.8-6.6-2.8-11.2 4.8-5.2 4.8-9.7c0-4.5 1.4-10.5 3.8-10.5.8 0 1 .8 1 2 0 .9-.1 2.1-.1 3.3 0 6.2 2.9 5.2 2.9 8.7 0 2.8-2.5 5.8-2.5 9 0 4.3 5.4 4.9 5.4 11.1 0 4.8-2.6 9.1-2.6 13.3 0 1.3.3 2.5.8 3.6h-9.7z"
          className="st13"
        />
        <path
          d="M381.1 290.1c-1.1-1.6-1.7-3.6-1.6-5.5 0-5.1 3.4-10 3.4-13.1 0-3.6-3.5-7.2-3.5-10.8 0-2.7 1.7-2.4 1.7-8.5v-3.4c0-2 .2-4 .6-6h.2c-.4 2-.6 4-.6 6v3.4c0 6-1.7 5.9-1.7 8.5 0 3.6 3.5 7.2 3.5 10.8 0 3.2-3.4 8.1-3.4 13.1 0 2 .6 3.9 1.6 5.4l-.2.1z"
          className="st4"
        />
        <path
          d="M364.2 290.1s-4.4-3.4-4.4-6.7c0-3.3 3.7-4.4 3.7-8.2 0-3.9-2.2-5.1-2.2-8.6s3.7-4 3.7-7.4 1.1-8 2.9-8c.6 0 .7.6.7 1.5 0 .7-.1 1.6-.1 2.5 0 4.8 2.2 4 2.2 6.6 0 2.2-1.9 4.4-1.9 6.8 0 3.3 4.2 3.8 4.2 8.5 0 3.6-2 6.9-2 10.1 0 1 .2 1.9.6 2.8l-7.4.1z"
          className="st13"
        />
        <path
          d="M366.7 290.1c-.8-1.2-1.3-2.7-1.3-4.3 0-3.8 2.6-7.7 2.6-10 0-2.7-2.7-5.5-2.7-8.3 0-2.1 1.3-1.9 1.3-6.5v-2.6c0-1.5.1-3.1.4-4.6h.2c-.3 1.5-.4 3-.4 4.6v2.6c0 4.6-1.3 4.5-1.3 6.5 0 2.7 2.7 5.5 2.7 8.3 0 2.5-2.6 6.2-2.6 10 0 1.5.4 2.9 1.2 4.1l-.1.2z"
          className="st4"
        />
        <path
          d="M396.2 286.4c0 1.5-3.8 2.7-5.6 2.7-.8 0-1-.2-1-.4 0-1 3.5-3.2 5.4-3.2.4 0 1.2.1 1.2.9zm-1.2-3.7c0 1.3-3.3 2.3-4.8 2.3-.6 0-.8-.2-.8-.3 0-.8 3-2.7 4.6-2.7.8-.1 1 .4 1 .7zm-11-.8c0 1.3 3.5 2.9 4.9 2.9.5 0 .5-.2.5-.3 0-.9-3-3.3-4.5-3.3-.9-.1-.9.7-.9.7zm10.9-2.3c0 1.1-2.7 1.8-4.2 1.8-1 0-1-.3-1-.3 0-.7 2.7-2.2 4.2-2.2 1-.1 1 .7 1 .7z"
          className="st11"
        />
        <path
          d="M385.9 277.4c0 1.3 2.9 3.8 3.7 3.8 0 0 .2 0 .2-.3 0-1-2.1-4.2-3.4-4.2-.2 0-.5.3-.5.7 0-.1 0 0 0 0zm8.9-.3c-1.4 0-3.3-.6-3.3-1.1 0-.4 1.2-.7 2.5-.7 1.2 0 2.3.3 2.3 1 0 .6-.7.8-1.5.8z"
          className="st11"
        />
        <path
          d="M389 272c0 1.3 2 4 2.6 4 0 0 .2 0 .2-.5 0-1.2-1.2-4.2-2.2-4.2-.4 0-.6.3-.6.7 0-.1 0-.1 0 0zm7.7 1.9c-1.2 0-2.9-.6-2.9-1.1 0-.2.6-.5 1.7-.5s2.2.3 2.2.9c0 .7-1 .7-1 .7z"
          className="st11"
        />
        <path
          d="M392.3 269.3c0 1.2 1.1 3.4 1.6 3.4.2 0 .2-.6.2-.7 0-1.2-.6-3.4-1.4-3.4-.3 0-.4.5-.4.7zm6.7 1.9c-.9 0-2.4-.7-2.4-1.1 0-.2.3-.3.9-.3.9 0 2.2.3 2.2.9-.1.3-.2.5-.7.5z"
          className="st11"
        />
        <path
          d="M396 267.8c0 1 .3 2.2.6 2.2s.5-1 .5-1.9c0-.8-.2-1.4-.6-1.4-.4-.1-.5.7-.5 1.1zm-13.3 19.5c0 1.3 3 2 5 2 1.8 0 1.8-.5 1.8-.5 0-.9-3.3-2.4-5.3-2.4-1-.2-1.5.3-1.5.9z"
          className="st11"
        />
        <path
          d="M389.8 289.9c-.4-1.5-.5-3.1-.5-4.7 0-1.7 0-9.9 8-15.8l.1.2c-7.9 5.8-7.9 13.9-7.9 15.6 0 1.6.2 3.1.5 4.6l-.2.1z"
          className="st4"
        />
        <path
          d="M389.1 290.1s.5-10.9 4.3-10.9c.9 0 1.7.4 1.7 1.6 0 3-4.8 9.3-4.8 9.3h-1.2z"
          className="st5"
        />
        <path
          d="M390.2 290.1s3.6-6.6 6.6-6.6c.7 0 1.7.5 1.7 1.3 0 2.2-6.7 5.3-6.7 5.3h-1.6zm-2.2 0s-2.4-5.1-2.4-8.4c0-1 .2-2.8 2.1-2.8 1.8 0 2.3 2.6 2.3 5.2 0 2-.2 4-.6 6H388z"
          className="st5"
        />
        <path
          d="M394.4 280.5c0 .2-.1.3-.3.3-.1 0-.3-.1-.3-.3s.1-.3.3-.3c.2 0 .3.1.3.3zm2.6 3.7c0 .2-.1.3-.3.3-.1 0-.3-.1-.3-.3 0-.2.1-.3.3-.3s.3.1.3.3zm.7 0c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2.2.1.2.2zm-.3.8c0 .2-.1.3-.3.3-.1 0-.3-.1-.3-.3 0-.2.1-.3.3-.3.2 0 .3.2.3.3zm-3.7-4.1c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4zm-5.9-.9c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4zm-1.1.7c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2.2.1.2.2z"
          className="st18"
        />
        <circle cx={388.5} cy={281.3} r={0.1} className="st18" />
        <path
          d="M394 281.1c0 .1 0 .1-.1.1s-.1-.1-.1-.1c0-.1 0-.1.1-.1s.1 0 .1.1z"
          className="st18"
        />
        <path
          d="m388.7 290.1-1.3-8.7.4-.1 1.3 8.7-.4.1zm1.4-.1 2.7-8.1.4.1-2.7 8.1-.4-.1zm1-.1 4.2-4.7.3.3-4.2 4.7-.3-.3z"
          className="st3"
        />
        <path
          fill="#c44e4e"
          d="M64.8 290.5s22.6 2.4 51.1 2.4c149 0 216.2-123.4 216.2-123.4h17.3L303.5 44.4l-96.4 124.2h27.7S193.7 283.7 70.1 283.7c-1.9 0-3.7-.1-5.3-.2v7z"
        />
        <path
          fill="#f48282"
          d="M207.1 168.6v-18.8l96.4-105.4-96.4 124.2zm16.2 0s-23.3 54.7-57.2 79-51.1 29.6-51.1 29.6 38.5-6.5 71.4-37.6c32.3-30.6 48.5-71 48.5-71h-11.6z"
        />
        <path
          d="M56 223c-4.9 0-18.7 9.7-18.7 18.6 0 4 3.1 4.9 5.9 4.9 1.4 0 2.7-.2 4-.5l-.5 4.1h16.8c-.5-2.1-.7-4.3-.7-6.5 0-3.8.6-7.9 2.3-9.6 2.9-2.9 18.9-8.8 18.9-8.8v-6.8c0-.1-.1-.2-.2-.2h-.1l-21.9 7.3c.1 0-4.5-2.5-5.8-2.5z"
          className="st5"
        />
        <path
          d="M65.1 220.6c-.1-1.4-.5-2.7-1.2-3.9-.7-1.4-2.2-1.9-3.5-1.1-.8.5-1.3 1.4-1.3 2.4 0 .2 0 .4.1.6l.3 2.8L58 223l3.5 4.4 1.2-4.5s2.4-.6 2.4-2.3z"
          className="st21"
        />
        <path
          d="M63.1 215.7c-.1.1-.3.1-.4.1-.4 0-.6-.4-1-.4-.3 0-.6.2-.6.6 0 .5.3.9.3 1.2 0 .4-.4.5-.4.7v.1c.1.3.2.6.2.8 0 .1-.1.2-.2.2-.2 0-.3-.3-.7-.3-.8 0-.9 1.2-.9 1.9v.6l-.6.7s-3.2-5.6-3.2-7.5c0-.2 0-.3.1-.4.9-1.7 2.4-1 3.4-1.5 1.1-.6 1.7-2.8 3.4-2.8.8 0 1.8.5 1.8 2.7 0 1.3-.2 2.5-.6 3.7l-.6-.4z"
          className="st22"
        />
        <path
          d="M60.2 218.4c-.6 0-1 .5-1 1.1 0 .6.5 1.1 1 1.1.6 0 1-.5 1-1.1 0-.6-.4-1.1-1-1.1z"
          className="st21"
        />
        <path d="m62.7 222.9-1.4.3 1.1.6.3-.9z" className="st5" />
        <path
          d="M55 236.8c.9-.2 1.8-.4 2.7-.5.6 0 2.2.1 2.2.5s-1 4.7-1.6 5c-.5.2-1.9.5-1.9.5l-1.9-.9.5-4.6z"
          className="st21"
        />
        <path
          d="m57.4 223.4 3.8 5.1.3-1.1L58 223c0-.1-.6.4-.6.4z"
          className="st5"
        />
        <path
          d="m48.1 245.9-.1-.4 5.9-1.1.9-8.5L44 237v-.4l11.4-1.2-1 9.3-6.3 1.2z"
          className="st3"
        />
        <path
          d="M78.7 261.7c-.9-1.7-11.8-10.5-15.1-11.6H46.8c-.8 4.5-6.6 15.7-7.8 17.7-1.5 2.4-11.5 10.1-11.5 10.6l.3 7.5c0 .1.1.2.2.2h.1L44.8 276c1.6-1 3-2.3 4.1-3.9l7.1-10.4c.6-.8 1.4-1.2 2.3-1.2 1 0 13.2 5.8 14.2 7.1.6.7 4 13.5 4.7 16.3.1.3.4.5.7.5h8.4c.1 0 .2-.1.2-.2-.1 0-7-20.9-7.8-22.5z"
          className="st23"
        />
        <path d="m57.4 259.6-.3-.2 3.3-6.4.3.2-3.3 6.4z" className="st10" />
        <path d="m80.3 284.5.2 2.2h4.9l-.5-2.2h-4.6z" className="st21" />
        <path
          d="m80.5 286.7-.6 5.5c0 .1.1.2.2.2h12.5c.3-.4.4-.9.5-1.4 0-1.5-6.1-1.4-7.6-4.4l-5 .1z"
          className="st24"
        />
        <path d="M27.4 278.6h-1.6l1 5.3.9-.3-.3-5z" className="st21" />
        <path
          d="m25.8 278.6-4.1-.5h-.1c-.3 0-.6.3-.6.6 0 .8.4 12.4.8 12.4h3.7s1.3-1.3 1.3-1.7-2.2-.5-2.3-1.3v-.3c0-1.8 2.3-3.9 2.3-3.9l-1-5.3z"
          className="st24"
        />
        <path
          d="M84 219.1s2.7-1.7 3.8-1.7c.3 0 .4.1.4.3 0 .5-.7 1.7-.7 2.4 0 .2.1.2.1.4 0 .7-1.5 2.3-1.5 2.3H84v-3.7z"
          className="st21"
        />
        <path
          d="M57.4 219.5s-2.1-.4-3.1-2.1c-1-1.6 1.5-3.1 1.5-3.1s-1.1-1.3-.8-2.3c.5-1 1.5-1.4 1.5-1.4s-.3-1.8 1.5-2.6 2.8.8 2.8.8.5-.4 1 .4c0 0 2-.6 2.2 2.2 0 0 1.2 3.1-.4 4.9v-2s-.4 1-1.4.7c-1-.3-1.2-1-1.2-1s-.4 1.3-1.4 1.2c0 0-.3 1-.7 1.6-.5.6.6 1.5.6 1.5l-.5.4-.6-.7c0 .1-.9.6-1 1.5z"
          className="st22"
        />
        <ellipse cx={200} cy={318.1} className="st0" rx={169.9} ry={6.6} />
        <path
          d="M211.2 203.1c0 1.4-1.1 2.6-2.4 2.6-1.3 0-2.4-1.2-2.4-2.6 0-1.4 1.1-2.6 2.4-2.6 1.3 0 2.4 1.2 2.4 2.6zm9-6c0 1.4-1.1 2.6-2.4 2.6-1.3 0-2.4-1.2-2.4-2.6 0-1.4 1.1-2.6 2.4-2.6 1.4 0 2.4 1.2 2.4 2.6zm-9 9.6 2.8 4.9 7.1-3.9-2.1-6-7.8 5z"
          className="st25"
        />
        <path
          d="m213.9 211.5-.3-.6c1.9-2.2 4.4-3.6 7.1-4.2l.2.6-7 4.2z"
          className="st5"
        />
        <path
          d="M243.7 174.2v-1.1h-10s.1 1.6.1 4.2c0 24.6-4.4 25-17 32.1-6.3 3.4-10.6 10-11.3 17.6l-6 3.2s2.8 16.5 8.2 16.5c5.6 0 6.5-13.8 7.9-13.8 1.3 0 7.5 13.8 7.5 13.8l20.8-9.6s-6.5-7.7-6.5-17c-.2-6.8 6.3-18.1 6.3-45.9z"
          className="st9"
        />
        <path
          d="M243.6 237.3c.1 0 .1-.1 0 0 .1 0 48.2 70.7 48.2 70.8 0 .1-.1.1-.1.1h-14.3c-.8 0-1.6-.4-2.1-1.1-15.2-22.7-35.4-44-35.5-44-.1 0-14.8 12.1-14.8 15.6 0 4.3 17.4 27.7 17.4 27.9 0 .2-.1.3-.3.3h-11.6c-2.4 0-21.1-24.5-21.1-32.8 0-8 13.9-15.6 13.9-25 0-.8-.1-1.5-.3-2.2 0-.1 0-.1.1-.1l20.5-9.5z"
          className="st5"
        />
        <path
          fill="#9b0e15"
          d="m241.4 261.4-.3-.2c2.9-4.9 4.7-10 5.1-14.5h.4c-.4 4.6-2.3 9.8-5.2 14.7z"
        />
        <path
          fill="#2b2024"
          d="M204.5 237c-.1 0-.1-.1-.1-.2 0-.4.6-9.4 1-9.4.1 0 .1.1.2.2-1.1 6.9-.7 9.4-1.1 9.4zm10.4-4.6c-.1 0-.1 0 0 0-3-2.9-4.4-2.9-4.4-3.2 0-.1.1-.2.1-.2.9 0 4.4 2.9 4.4 3.2 0 .1 0 .2-.1.2z"
        />
        <path d="m284.9 308.2 1.6 2.4h6.4l-1.6-2.4h-6.4z" className="st25" />
        <path
          d="m293 310.6 3.4 5.2s.1.1.1.2c0 .2-.1.3-.3.3h-17.1l-.6-1.1c-.2-.3-.1-.8.2-1 .1 0 .2-.1.3-.1 2-.2 6.4-1.1 7.5-3.5h6.5z"
          className="st24"
        />
        <path d="m241.2 306.8 1.5 2.4H237l-1.5-2.4h5.7z" className="st25" />
        <path
          d="m242.6 309.3 3 6.5c.1.2 0 .4-.1.5h-17.8l-.5-1.2c-.2-.4 0-.8.3-1 .1 0 .1-.1.2-.1 2.2-.4 7.8-1.6 9.5-4.8l5.4.1z"
          className="st24"
        />
        <path
          d="M201.9 228.9s-.9-2-1.6-2.4c-.6-.4-2.7-3.2-2.7-4.6 0-1.5.4-3.8 1.1-3.8.7 0 3 5 4.3 5 .8 0 1.4-3.5 2.1-3.5.8 0 .7 2.5.7 2.9 0 1.6-.2 3.3-.2 3.3l-.2 1.3-3.5 1.8zm38.1-55.8s1.5-3.2 1.5-4.3c0-.2-.1-.4-.2-.4-.7.2-1.3.4-2 .4-1.7 0-2.7-1-7.4-1-1.9 0-3 .2-3 .8 0 1.1 6.8 2.7 6.8 4.4v.1h4.3z"
          className="st25"
        />
        <path
          d="M219.3 204.1s-2.1-17.3-5.7-17.3c-2.7 0-3.3 3.8-5.9 3.8-.7 0-2.4-.3-3.4-.3-3.8 0-3.9 4-3.9 4.1 0 3.4 4.5 6.2 6.6 6.9l5.3 6.9 7-4.1z"
          className="st24"
        />
        <path
          d="M306.5 184.5c-.2-.3-.4-.7-.4-1 0-.2.1-.4.2-.5.5-.5 1.2-.2 1.2-1 0-.3-1.7-.3-2.4-.5-2.4-.7-3.5-1.2-5.1-1.2h-.7c-.1.2-.1.4-.1.6 0 1 3.8 0 3.8 3.7 0 .5-.1 1.1-.2 1.6l3.7-1.7z"
          className="st6"
        />
        <path
          d="M320.4 206.1c-5.8-6.2-12.3-16-13.8-21.7l-7.9 3.7s6.9 21.1 19.4 27.9l2.3-9.9z"
          className="st9"
        />
        <path
          d="M327.1 193.9c0 2.1-1.5 3.7-3.4 3.7s-3.4-1.7-3.4-3.7 1.5-3.7 3.4-3.7 3.4 1.7 3.4 3.7z"
          className="st6"
        />
        <path
          d="M321.2 191.4s-3 3.8-3 5.5c0 1.6 2.5 2.9 2.5 2.9v6.3l6.1-4.5-1.4-2.6 1.3-3.4-5.5-4.2z"
          className="st6"
        />
        <path
          fill="#fa84bf"
          d="M327.9 196.9c0 .9-.7 1.7-1.5 1.7s-1.5-.7-1.5-1.7c0-.9.7-1.7 1.5-1.7.8.1 1.5.8 1.5 1.7z"
        />
        <path
          d="M322.5 190.4c.2.2.5.3.7.3.4 0 .7-.3 1-.3.3 0 .8.3.8.8 0 .7-.8 1.3-.8 1.8 0 .4.3.6.3.9 0 .1 0 .1-.1.2-.2.3-.4.7-.5 1.1 0 .2.1.3.3.3.2-.1.4-.1.6-.1 1.1 0 1 1.3 1 1.4 0 .8-.2 1.5-.4 2.2l.6 1.1s6.5-6.6 6.5-8.8v-.2c-.6-2.5-2.8-2.2-3.9-3.2-1.3-1.2-1.4-4.9-4.1-4.9-2.6 0-2.9 5.2-2.9 7.2v.8l.9-.6z"
          className="st24"
        />
        <path
          d="M326.5 196.4c0 .8-.6 1.5-1.4 1.5-.8 0-1.4-.7-1.4-1.5s.6-1.5 1.4-1.5c.7 0 1.4.7 1.4 1.5z"
          className="st6"
        />
        <path d="m320.8 199.8 1.7 1-1.7.4v-1.4z" className="st5" />
        <path
          d="M344.9 188.4c0-10.8 1.4-16.7 1.4-16.7h-8.5s-.1 30.5-9.1 30.5c-.6 0-1.2-.2-1.7-.6-5.3 1.4-9 6.7-8.8 12.7 0 8.8 4.4 18.5 4.4 18.5h19s-4.3-9.4-4.3-17.2c0-9.7 7.8-12.6 7.8-26.1-.2-.2-.2-.4-.2-1.1z"
          className="st9"
        />
        <path
          d="m326.9 201.6-.5-1-5.1 3.6.6 2.8 5-5.4zm-6.1 2.3.5.4-.5 3.1-.8-1.8.8-1.7z"
          className="st23"
        />
        <path d="m327 282.2 1.6 1.7 4.6-.7-2-2.4-4.2 1.4z" className="st6" />
        <path
          d="m333.2 283.3 4.4 6.1c0 .1.1.1.1.2 0 .2-.1.3-.3.3h-17.6c-.3-.4-.5-.8-.5-1.3 0-2.3 5.9-.8 9.3-4.6l4.6-.7zm-11.9-79-.4 2.2.2 1.3-.7 20.8 1.5 2.1 1.4-2.1-1.8-20.9.2-1.2-.4-2.2z"
          className="st24"
        />
        <path
          d="M350.4 259.8c-3.2-6.5-5.9-18.5-8.9-27h-19s-16.4 19.3-16.4 26.6c0 6.7 17 24.6 17.4 24.6h.1l9.9-3.2c.1 0 .1-.1.1-.2s-14.2-15.7-14.2-19.5c0-3.9 10.4-12.2 10.4-12.2 4.3 8.8 9.8 18.8 14.2 22.7 8.3 7.4 27.4 15.1 27.7 15.1.2 0 .3-.1.4-.3l2.8-9.5c0-.1-21.3-10.6-24.5-17.1z"
          className="st23"
        />
        <path
          d="M329.3 248.2c-2.4-5.1-5.5-12-5.5-12.1l.3-.2c0 .1 3.1 7 5.5 12.1l-.3.2z"
          className="st10"
        />
        <path d="m374.7 277.2 1.8.5-1.9 4.1-1.2-.3 1.3-4.3z" className="st6" />
        <path
          d="m376.5 277.7 5.3.7c.2 0 .4.2.3.5v.1l-4.3 11.3-5-.1s-.8-.4-.8-.9c0-.9 3.5-.4 3.5-3.3-.1-1.4-.4-2.8-.9-4.1l1.9-4.2z"
          className="st24"
        />
        <path
          d="m343.3 171.7.2-1c.1-.6.6-1.1 1.1-1.3l1.9-.6c.5-.1.9-.5 1.2-1v-.1h-10.2l.2.5c.2.4.5.8.9 1l.8.4c.3.1.4.4.4.8l-.2 1.3h3.7z"
          className="st6"
        />
        <path
          d="M318.5 215.2h-.4c0-2.8.5-5.6 1.5-8.1l.4.1c-1 2.5-1.5 5.3-1.5 8zm-154.3 19.9c0 4.5 0 9-.1 13.4h-.4c0-4.4.1-8.9.1-13.4 0-10.6-.1-21.2-.2-31.7v-3.9h.4v3.9c.1 10.5.2 21.1.2 31.7zm-.2-39.4-.1-.4c19.1-8.5 37.6-17.7 55.8-26.5l.2.4c-18.4 8.8-36.9 18.1-55.9 26.5zm-77.1 22.2-.2-.3C176.1 149.6 236.2 106 272.9 78l.2.3c-41.2 31.5-82.9 61.2-186.2 139.6zm-13.8 30.5c-4.1 0-7.4-4.6-10-8.3l-.2-.3c-.7-1-2.4-3.7-4-3.7-.4 0-.7.1-1 .4l-.2-.3c.3-.3.8-.4 1.2-.4 1.8 0 3.5 2.8 4.3 3.8l.2.3c2.5 3.5 5.8 8.1 9.7 8.1 10.1 0 12.3-23.2 12.6-25.2l.4.1c-.5 3.8-2.9 25.5-13 25.5zm-25.3 34.7-.2-.3c9.2-6.8 9.3-39 9.3-40.6v-.1h.4v.1c0 6.8-.8 34.5-9.5 40.9z"
          className="st3"
        />
        <path
          d="M212.2 314.1s.4-7.9 3.1-7.9c.6 0 1.2.3 1.2 1.1 0 2.2-3.5 6.7-3.5 6.7h-.8z"
          className="st5"
        />
        <path
          d="M213 314.1s2.6-4.8 4.8-4.8c.5 0 1.2.4 1.2 1 0 1.6-4.8 3.8-4.8 3.8H213zm-1.6 0s-1.7-3.7-1.7-6.1c0-.7.2-2 1.5-2s1.6 1.9 1.6 3.8c0 1.5-.2 2.9-.4 4.3h-1z"
          className="st5"
        />
        <path
          d="M216 307.1c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2c.2 0 .2.1.2.2zm2 2.7c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2.2.1.2.2z"
          className="st18"
        />
        <circle cx={218.3} cy={309.9} r={0.1} className="st18" />
        <path
          d="M218.2 310.4c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2.2.1.2.2zm-2.7-3c0 .2-.1.3-.3.3s-.3-.1-.3-.3.1-.3.3-.3c.2 0 .3.2.3.3zm-4.3-.6c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3.2 0 .3.1.3.3zm-.8.5c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2c.2 0 .2.1.2.2zm1.4.4c0 .1 0 .1 0 0-.1.1-.2 0-.2 0 .1 0 .1-.1.2 0 0-.1 0 0 0 0zm4-.1s-.1.1 0 0c-.1.1-.2 0-.2 0 0-.1.1-.1.2 0-.1-.1-.1-.1 0 0-.1-.1 0-.1 0 0z"
          className="st18"
        />
        <path
          d="m211.9 314.1-1-6.3.4-.1 1 6.3-.4.1zm.9-.1 2-5.9.4.1-2 5.9-.4-.1zm.8-.1 3.1-3.4.3.3-3.1 3.4-.3-.3z"
          className="st3"
        />
        <path
          d="M125.1 295.3s.4-7.9 3.1-7.9c.6 0 1.2.3 1.2 1.1 0 2.2-3.5 6.7-3.5 6.7h-.8z"
          className="st5"
        />
        <path
          d="M125.9 295.3s2.6-4.8 4.8-4.8c.5 0 1.2.4 1.2 1 0 1.6-4.8 3.8-4.8 3.8h-1.2zm-1.6 0s-1.7-3.7-1.7-6.1c0-.7.2-2 1.5-2s1.6 1.9 1.6 3.8c0 1.5-.2 2.9-.4 4.3h-1z"
          className="st5"
        />
        <path
          d="M128.9 288.3c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2c.2 0 .2.1.2.2z"
          className="st18"
        />
        <circle cx={130.7} cy={291} r={0.2} className="st18" />
        <path
          d="M131.3 291.1c0 .1-.1.1-.1.1-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1.1-.1.1 0 .1.1zm-.2.5c0 .1-.1.2-.2.2s-.2-.1-.2-.2.1-.2.2-.2.2.1.2.2zm-2.7-2.9c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3s.3.1.3.3zm-4.3-.7c0 .2-.1.3-.3.3-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3s.3.1.3.3z"
          className="st18"
        />
        <circle cx={123.2} cy={288.5} r={0.2} className="st18" />
        <circle cx={124.6} cy={288.9} r={0.1} className="st18" />
        <path
          d="M128.7 288.8s-.1.1 0 0c-.1.1-.2 0-.2 0 0-.1.1-.1.2 0-.1-.1-.1-.1 0 0-.1-.1 0-.1 0 0z"
          className="st18"
        />
        <path
          d="m124.8 295.3-1-6.3.4-.1 1 6.3-.4.1zm.9-.1 2-5.9.4.1-2 5.9-.4-.1zm.8-.1 3.1-3.4.3.3-3.1 3.4-.3-.3z"
          className="st3"
        />
      </g>
    </svg>
  );
}
