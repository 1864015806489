import { useEffect, useState } from 'react';

import { ErrorEventsEnum, errorLogToRemoteUtil } from '@keymono/utilities';

type TUseLocalStorageReturnType = [string | boolean, Function];

/**
 * -----------------------------------------------------------------------------
 * This hook provides means to add an item to local storage.
 *
 * TODO: Make the type of the initial value dynamic
 *
 * @param string - `key`, / unique id used to save a value in local storage.
 * @param boolean | string - `initialValue` the default value to persist.
 *
 * @returns [string, Function] - the store value & function to update it.
 */
const useLocalStorage = (
  key: string,
  initialValue: boolean | string = false
): TUseLocalStorageReturnType => {
  const [storedValue, setStoredValue] = useState((): string | boolean => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error: any) {
      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN__LOCAL_OR_ASYNC_STORAGE,
        errorTitle: 'Error while reading from local storage',
        message: error.message,
      });

      return initialValue;
    }
  });

  const setValue = (value: string | boolean | Function) => {
    try {
      let valueToStore = false;

      if (value instanceof Function) {
        valueToStore = value(storedValue);
      } else {
        valueToStore = !!value;
      }

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error: any) {
      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN__LOCAL_OR_ASYNC_STORAGE,
        errorTitle: 'Error while saving data to local storage',
        message: error.message,
      });
    }
  };

  return [storedValue, setValue];
};

type TUseDarkModeReturnType = [boolean, Function];

/**
 * -----------------------------------------------------------------------------
 * This hook provides means to add an item to local storage.
 *
 * @returns TUseDarkModeReturnType - a `boolean` to show whether dark mode is
 *  enable or not and a `function` to update it.
 */
const useDarkMode = (): TUseDarkModeReturnType => {
  const [enabled, setEnabled] = useLocalStorage('dark-theme', 'dark');

  useEffect(() => {
    const className = 'dark';
    const bodyClass = window.document.body.classList;

    if (enabled) {
      bodyClass.add(className);
    } else {
      bodyClass.remove(className);
    }
  }, [enabled]);

  return [!!enabled, setEnabled];
};

export default useDarkMode;
