import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * Creates wrapper styles for the containing `div` around the input fields...
 * label, input, error messages and feed info tips.
 */
export const TextInputContainer = styled.div`
  label {
    ${tw`
      block
      text-sm
      text-gray-700
    `};
  }

  .input-wrapper {
    ${tw`
      relative
      mt-1
      rounded-md
      shadow-sm
    `};
  }

  input {
    &.input-has-x-btn {
      ${tw`
        pr-10
        rtl:(pr-2.5 pl-10)
      `};
    }

    &.input-has-leading-icon {
      ${tw`
        pl-10
        rtl:(pl-2.5 pr-10)
      `};
    }

    &.input-has-value-prefix {
      ${tw`
        rounded-md
        ltr:rounded-l-none
        rtl:rounded-r-none
      `};
    }
  }

  .input-icons-start {
    ${tw`
      absolute
      inset-y-0
      left-0
      flex
      items-center
      pl-2
      // border-2

      rtl:(left-auto right-0 pl-0 pr-2)
    `};
  }

  .input-icons-end {
    ${tw`
      absolute
      inset-y-0
      right-0
      flex
      items-center
      pr-2
      // border-2

      rtl:(right-auto left-0 pr-0 pl-2)
    `};

    .icon-clear-input {
      ${tw`
        h-5
        w-5
        text-gray-400
      `};
    }

    .icon-error-indicator {
      ${tw`
        pointer-events-none
        h-5
        w-5
        mx-1
        text-red-500/80
      `};
    }
  }

  .input-error-msg {
    ${tw`
      h-0
      opacity-0
      overflow-hidden
      mt-2
      text-xs
      text-red-600/80
      transition-all duration-300 ease-in-out
      // bg-red-50
    `};
  }

  .input-helper-msg {
    ${tw`
      text-xs
      font-light
      text-teal-500
    `};
  }

  &.input-has-error {
    input {
      ${tw`
        border-red-300
        pr-12
        text-red-900
        placeholder-red-300
        focus:(border-red-500/50 outline-none ring-red-100)

        rtl:(pr-2.5 pl-12)
      `};

      &.input-has-leading-icon {
        ${tw`
          rtl:(pr-10)
        `};
      }
    }

    label {
      ${tw`
        block
        text-red-800/80
      `};
    }

    .input-error-msg {
      ${tw`
        min-h-[1.5rem]
        h-auto
        opacity-100
        transition-all duration-500 ease-in-out
        rtl:text-right
        ltr:text-left
      `};
    }
  }

  &.field-row {
    ${tw`
      flex flex-row
      mt-1
    `};

    label {
      ${tw`
        w-1/4
      `};
    }

    .input-and-feedback {
      ${tw`
        w-3/4
        flex-1
      `};
    }
  }
`;
