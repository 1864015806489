import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * Styles for the loading indicator on the session profile picture.
 * You need to provide it a `className` with `w-` and `h-` classes.
 */
export const CircularLoader = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 2s linear infinite;

  ${tw`
    border-2
    border-gray-50
    border-t-red-500
    rounded-full
  `};
`;
