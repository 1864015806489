export * from './components';
export * from './hooks/useI18n';
export * from './hooks/useUpdateLocaleDir';
export * from './lib';

export { LocaleContext, LocaleProvider } from './LocaleContext';
export { regionsConfig } from './localesConfig';
export { DEFAULT_LOCALE } from './localeConstants';

export type {
  // TCountryCurrency,
  TLocale,
  TRegionInstance,
  TTextLocalized,
} from './types';
