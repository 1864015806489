import { useRouter } from 'next/router';

import { TLocale } from '@keymono/i18n';

import { IBaseInputCheckItem } from '../../types';

import { CheckInput } from './CheckInput';

type TInputCheckItemProps = Omit<IBaseInputCheckItem, 'locale'>;

/**
 * -----------------------------------------------------------------------------
 * This renders input check field to be used across all form fields.
 */
export function InputCheckItem({
  checked,
  disabled,
  label,
  name,
  onChange,
  required,
  title,
}: TInputCheckItemProps) {
  const locale = useRouter().locale as TLocale;

  return (
    <CheckInput
      locale={locale}
      disabled={disabled}
      checked={checked}
      label={label}
      name={name}
      onChange={onChange}
      required={required}
      title={title}
    />
  );
}
