import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

import { PasswordToggleBtnContainer } from './styles';

interface IPasswordToggleBtnProps {
  onTogglePassword: () => void;
  isPasswordVisible: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an icon for when the password is hidden & a slash eye when it's
 * visible.Clicking on this Icon toggles the password to either masked or text.
 */
export function PasswordToggleBtn({
  onTogglePassword,
  isPasswordVisible,
}: IPasswordToggleBtnProps) {
  return (
    <PasswordToggleBtnContainer type="button" onClick={onTogglePassword}>
      {isPasswordVisible ? <EyeSlashIcon /> : <EyeIcon />}
    </PasswordToggleBtnContainer>
  );
}
