import { ISessionsUnderSignOut, TSet } from '@keymono/shared-types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

export function updateSessionsUnderSignOutCallback(set: TSet) {
  return function updateSessionsSignOut(sessions: ISessionsUnderSignOut[]) {
    set(
      ({
        activeSession,
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
      }) => {
        const updatedStoreFields = {
          activeSession,
          activityLog,
          allSessionsById,
          allSessionsIndices,
          allSessionsByIndex,
          sessionsUnderSignOut: sessions,
        };

        persistAuthStore(updatedStoreFields);

        return {
          sessionsUnderSignOut: sessions,
        };
      }
    );
  };
}
