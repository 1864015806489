import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * Simple illustration showing a person chasing down a broken kite connection.
 */
export function IllustrationConnectionError(props: TSvgComponentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 340"
      xmlSpace="preserve"
      width="25em"
      height="21.25rem"
      {...props}
    >
      <style>
        {
          '.st0{display:none}.st2{display:inline}.st4{fill:#d62e0d}.st4,.st5{display:inline}.st5,.st6{fill:none;stroke:#000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st7{fill:#d02323}.st8{fill:none;stroke:#cf0000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
        }
      </style>
      <g id="new">
        <g id="X0D03c.tif_1_">
          <path d="M246.1 267.6c-.1-.1-.2-.2-.3-.2-.2-.1-.3-.2-.5-.3-.1-.1-.2-.1-.3-.2-.4 1-1.5 1.6-3.7 1.8-6.1-1.4-3.6-4.6-.3-7.4-.2-.2-.3-.5-.4-.7-2.9 3-9.5 2.5-8.7 6.6-.9-2.4-2.9-3.3-2.4 0-1.4-2.3-2-3.8-2.3-.3-.9-4.7-8.1-2.9-11.7-4.3-5.1 2 14.8 9.9 18.9 13.5 4.9 2.6 7.5-5.4 11.7-8.5.1.1.1.1 0 0 .1.1.1 0 0 0zM379.1 276.3c-.1.6-.2 1.3-.4 1.9 1.8-.2 3.8.2 6.3.8 5.3 5.2-6.8 7.5-11.6 6.1-.2.6-.4 1.1-.5 1.8 5-2.9 12.9 3.6 15.8 7.7 1.8 2.2 4.7 16.3 8.5 13.9-1-20.5 7.5-32.6-18.1-32.2zM260.3 187.6c17.6 2 21.7 1.7 15.7 20.1-17.9 13.3-46.8 6.6-30.3 13 1.7-.2-.9-1.6-1.1-2.1 11.6-.5 25.6-4.7 34.1-12.2 17.5 0-.9 14.5-10.2 12.7-11.3 1.3-18.7 9.6-22.5 8.7 6.5-5.5-5.2-1.4-1.1-.7-4.3 3-1.4 4.3 1.9 1.7-.1 6 14.7-10.7 22.2-8.4 9.2.4 14.9-5 19.8-11 4.1.6 2.5-4.6 3.7-7.4 2.9 2.6 5 4.8 6.3 6.8.2-.3.5-.5.7-.8-23.9-33-34.4-4.6-42.7-38.6 37.2 6 34.6-8.1 62.6 22.3-.4-1.7 1.5-3.8-1.2-4.7-8.2-3.6-13.4-11.4-20.9-16-16-4.2-10.5-8.1-.3-16.1-5.8-.7-6-7.3-11.8-7.9 3.1-3.1.7-6-3.1-4.8-7.1 2.2-16.7.3-14.3 11.9-5.9 6.2 1.6 9.7 4.4 14.2 1.4 2.7 9.8 1.2 3.3 2.3-14.1-.1-25.4-3.9-36.9-11.8 5 4.3 11 8.6 17 11.3 7.9 13.3-5.2 10.2-12.5 4-10.2-11.2-32.1-16.9-37.6-29-2.7-1.8-.1-3.2-2.2-5.5 2.9-.9 4.5 2 7.2 3 0-2.8-2.3-3.3-3.4-5.3 4.4-2.2 10 9.4 14.1 12.5 5.1 4.8 24.4 12.6 9.6 4.5-9.5-3.6-13.9-12.7-21.6-18.6-1.5-1.4-2.3-1.3-3.3.4-2.1 2.1-7-6.8-10.3-8.5-13.3-8.8-22.9-28.5-40.3-26.5 20.3.9 32.3 25 48.3 36.3-2.9 1.6-1.7 3-.6 5.2.4 3.8 1.9 9.3 6.6 10.3l7.5 4c14.3 10.5 30.6 20.1 43.2 30.7zm20.5 4.9c5.9 3.2 13.2 6.8 9.2 14.6-11.6-3-13.6-1.7-9.2-14.6zm-13.4-36.6c2.3 1.3.5 5 1.9 6.4-2 2.7-3.9-5.2-1.9-6.4zm4-3c2-1.2 5.7-3.4 5.8.6-1.5-.6-3.2-1-4.1.5-.9 6.6-6.1 2.8-1.7-1.1zm4.4 8.7c-1.8 0-3-2.3-2.8-4.1.8-8.8 9.1 2.6 2.8 4.1zm-4-2.2c6.5 8.4 5.6-1.3 8.5-3.5 1.5 6.9 1.5-1.8 4.1-1.4 5.4 2-2.1 5.7-.7 8.3 6.9 6-4.1 11.9-3.6 3.3-6.4 7.2-10.1-2.8-8.3-6.7z" />
          <path
            className="st7"
            d="M2.8 53.5c4.5-1.6 23.5 1.9 19.3-5.4-1.2-1.4-4.4-3.6-1.6-5 24.2 3.3 52.7-14.8 55.1-14.3C64 26.2 47 24.9 39.9 27c15.3-4.5 31.8.2 47.6-.8 15.5 1 21.2 3.8 21.2 3.8S91.2 46 84.5 43c-6.8-3-6.8 7.5-11.7 8.2-5.8 1.2-15.7-.4-18.5 2.9 4.5-5.5 16-2.1 20.5-8.1 1.2-4.3 4.6-5.7 8.5-6.2 8.5.4 14.8-2.4 19.2-10-4.1.9-6.9-1.6-22.8-1.6 3 0-29.4 14.8-38.6 16.2 25.7-3.9 41-9.8 12.5-.2 46.1-6.9-11.6 6.9-30.6.7C33.7 56 7.6 58.2 2.8 53.5z"
          />
          <path className="st6" d="M276.6 206.2c-4.8 8.1-24.9 13.1-35.3 11.4" />
          <path
            className="st7"
            d="M217.9 156.5c.4 1.6 1.6 6.9 1.8 8.5.5 3.2.8 6.5 1.1 9.7.6 6.5.8 13 1.4 19.4.6 6.5 1.4 12.9 2.7 19.2 1.3 6.3 3 12.6 6.2 18.4v.1h-.1c-3.4-5.6-5.4-11.9-7-18.2-1.5-6.4-2.5-12.8-3.3-19.3-.8-6.5-1.4-13-1.8-19.5l-.5-9.7c-.1-4.3-2.3-9.1-2.5-9.9-.1-.3 1.8.5 2 1.3z"
          />
          <path d="M253.2 310.9c12.5.4-.9 5.1-5.8 2.7 4.6-3.8 9.8-6.4 13.3-11.4.7-7.6-20.5 19.7-13.2 2.7-3.7 2.8-4.4 12.2-10.7 8.8 5.4 1.6 13.6-20 11.3-6.2 13-11.4 21.4-9.9 5.1 3.4zM246 221c-1.9 2.1-4.8 1.5-7.4 2.4 2.4 0 4.9-.1 7.3.2-3.1 6.4-12.3 6.4-2.2 1-13.9.6-5.2-3.5 2.3-3.6zM381.8 259.1c-4.9 2.7-13.5-2.3-8.9-7.6-.2 6.8 4.6 6.8 8.9 7.6zM286.2 269.1c3.3.5 5.9-.8 8.8-1.9-.3 3.6-7.9 4.9-8.8 1.9zM43.8 75.7c-3.6-.1-6.3 1.4-8.7 3.6 1-3.3 5.8-6.5 8.7-3.6zM315 162.9c-2.8 2.8-7.7 1.3-7.1-2.5 2.4 1.4 3.7 3.8 7.1 2.5zM275.9 166c-1.6 2.2-2 .3-3-1.1.8-1.7 1.8.2 3 1.1z" />
          <path
            className="st7"
            d="M82 41.1c1.6 2.8 3.5 5.3 5.8 7.3 2.3 2.1 5 3.6 7.8 4.9 2.8 1.2 5.8 2.2 8.8 3.1 3 .9 6.1 1.6 9.2 2.4 3.1.9 6.2 1.7 9.3 2.8 3.1 1.2 6.1 2.5 8.9 4.2 2.8 1.7 5.4 3.8 7.7 6.2 2.2 2.5 4.1 5.2 5.4 8.1v.1h-.1c-1.7-2.7-3.7-5.2-6-7.4s-4.9-4.1-7.7-5.6c-5.6-3.1-11.8-4.9-18-6.5-6.2-1.7-12.5-3.1-18.2-6-2.9-1.4-5.6-3.3-7.8-5.6s-4-5.1-5.3-8c0-.1 0-.1.1-.1 0 0 .1 0 .1.1z"
          />
          <path
            className="st6"
            d="M287.6 208.6c-3.9 11-15.6 10.3-25.1 12.5-5.4-.9-14.7 12.6-14.9 7.2M303.5 216.1c-8-17.5-25.1-27.8-44.6-29.7M255.7 181c-17.8-6.3-29.9-22.5-47.3-29.4-3.1-1.2-5.4-7.2-4.3-9.7-2.2-1.3-2.1-3.6.6-4"
          />
          <path
            className="st7"
            d="M154.8 101c3.8-1.4 9.9.6 14.5 3.2s9.4 7 13.3 10.6c3.9 3.6 11.4 10.3 15 14.2 1.7 1.9 6.5 6 8.2 7.8 1.9 2 .3 1.3-1.2.8l-.1.1c-6.3-3.5-8.9-7.3-12.6-11.1-3.7-3.8-6.8-6.7-10.6-10.4s-7.1-8-11.7-10.7c-4.5-2.7-9.6-4.5-14.9-4.6l.1.1z"
          />
          <path className="st8" d="M82.8 40.8c-.3.1-.6.2-.8.2" />
          <path
            className="st6"
            d="M321 193.3c-4.7-8-16.2-16.1-25.9-22.8-5-3.8-9.9 1.5-15.2.3-7.8 1.2-15.7-2.1-23.4-1.7M206.2 138.2c.9-9.8 20.2 19.9 29.9 19.1"
          />
          <path className="st8" d="M80.7 41.4c-7.7.8-20.5 3.5-28.1 3.1" />
          <path
            className="st6"
            d="M292.5 201.4c-1.8 2-.8 4.7-2.2 6.8M284.9 206.2c-2.6 0-5.3-.9-7.8.1M370.8 285c3.4 1.8 7.5.9 11.2 1.7M238.8 158.7c6.6 5.3 16.2 7.9 19.7 15.4M386.6 278.6c-3.5-.6-7-1.2-10.5-1.7M322.7 194.6c5.1 2.9 6.4 5.3 1 8.8M280.1 192.1c-.5 4.8-2.3 9.4-3.2 14M247.9 308.3c.1-12.1-4.3 8.3-10.7 5.5M246.2 310.8c12.3-12.8 22.8-14.6 5.6 1.5M372.9 252.4c-2 6.4 4.8 7.2 9.2 6.3M278.9 154.6c-.6 19.2-13.9-4.1-1-.2M268.4 155.3c-4.4-.5-2.4 10.5 1.7 7.9M269.4 158.4c-.3-1.6-.8-3.2-.5-4.8M246.9 266c-1.5 1.2-2.9 2.4-4.4 3.6M270.1 163.4c1.8 4.1 4 7.3 8.8 5.3M285.4 164.5l2.7 4.5M295.2 267.6c-3 1.8-5.7 3-9.2 1.7M308.1 160.2c.7 3.6 4.3 4.2 7.1 2.6"
          />
          <path
            d="M42.9 75.3c-3.3-.4-6 1.3-7.8 4"
            style={{
              fill: 'none',
              stroke: '#d02323',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
            }}
          />
          <path
            className="st6"
            d="M245.7 221.2c-2.7.7-5.6.9-8.1 2M242.8 259.2c-2.6 1.6-4.7 3.9-7.1 5.8M237.3 223.2c2.3 1.8 5.9-.1 8.7.5"
          />
          <path className="st8" d="M105.2 30.4c-2.1.2-6.3-.3-8.4.5" />
          <path
            d="M371.7 287.7c.2-1.2.4-2.4 1-3.5 1.1-3.5 2.7-5.2 4.9-5.8.2-.7.3-1.4.4-2.1h-.3c-7.6-7-25.2-.9-29.6-12.7-9.2-18.3-9.8-44-24.6-60.3 0 0-11.2 10.7-26.7 15-15.4 4.3-29.5 14.7-32.9 18.6-6.8 7.8-17.9 13.4-22.4 21.9v.4c0 .2.1.4.1.6 0 .1.1.2.1.3 0 0 0 .1.1.1 0 0 0 .1.1.1.2-.1.3-.3.5-.4 1.1 1.7 2.3 3.6 2.5 5.2.8.5 1.6 1.1 2.4 1.7.7-.3 1.4-.4 2.2-.3 27.7-35.7 61.2-19.1 66.1-23.2.7-.6-4.9-11.6-5.4-13.3 12.5 24.3 27.6 62.3 60.5 58.5.2-.3.5-.6.7-.8.3.1.3.1.3 0z"
            style={{
              fill: '#bd2323',
            }}
          />
          <path
            className="st6"
            d="m244.4 224.2-2.5 2.5M228.1 264.7c.1 1 .2 2.1.3 3.1M243.3 218.4c1.1.6 1.9 1.5 2.5 2.5M248.1 225.3c-1.4.4-2.7.9-4.1 1.3M249.4 226.8c-1.2.7-2.2 2-3.9 1.3M279.8 166.5c-.5 1.3-.8 2.6-.5 4"
          />
        </g>
        <path
          className="st7"
          d="M19.3 66.2c0 3.6-3.3 3.6-3.3 0s3.4-3.6 3.3 0z"
        />
        <path
          d="M80.5 30.7c2.1-1.9 5.1-1.3 7 .7 1.8 1.9 1.8 4 .3 5.4-1.3 1.2-3 .9-3.7.1-.5-.5-.6-1.1-.3-1.5-.6-.1-1.2-.2-1.7-.7-.6-.6-.7-1.5 0-2.1.8-.7 2.4-1 3.5.2.3.3.5.7.4 1.2l.4-.2.3.3-1.8 1c-.9.4-1.1 1-.5 1.6.6.6 1.9.8 3.1-.2 1.4-1.3 1.3-3.1-.3-4.8-1.7-1.8-4.5-2.3-6.4-.6-1.5 1.4-1.4 3.5.2 5.3.6.6 1.3 1 1.9 1.3l.1.3c-.8-.3-1.5-.8-2.1-1.4-1.8-1.9-2.1-4.3-.4-5.9zm3.6 4.4 1.7-1c.1-.5 0-.8-.3-1.1-.8-.9-2.1-.7-2.8-.1-.6.6-.5 1.1-.1 1.5.2.5.7.7 1.5.7z"
          style={{
            fill: 'none',
            stroke: '#d62e0d',
            strokeMiterlimit: 10,
          }}
        />
        <path
          className="st7"
          d="M151.6 78.8c.7.9 1.3 1.7 2.1 2.3.7.6 1.5 1 2.4 1.2.9.2 1.8.3 2.8.3.5 0 1 0 1.5-.1.5 0 1.1-.1 1.7-.1-.4.4-.9.7-1.4 1-.5.2-1 .5-1.6.7-1.1.3-2.4.4-3.6.1-1.2-.3-2.3-1.1-3-2.1-.7-1-1.1-2.2-.9-3.3zM144.8 96.9c.9-.7 1.6-1.4 2.1-2.2.5-.8.9-1.6 1.1-2.5.2-.9.2-1.8.1-2.8-.1-.5-.1-1-.2-1.5s-.2-1.1-.2-1.6c.4.4.7.8 1.1 1.3.3.5.6 1 .8 1.5.4 1.1.6 2.3.3 3.6-.3 1.2-1 2.4-1.9 3.1-.9.8-2 1.2-3.2 1.1zM167 88.8c-.5.6-.8 1.2-1.1 1.7-.2.5-.4 1-.4 1.5-.1.5-.1 1.1 0 1.7 0 .3 0 .6.1 1 0 .3.1.7 0 1.1-.3-.2-.6-.5-.9-.7-.2-.3-.5-.6-.7-.9-.3-.7-.6-1.5-.4-2.4.1-.9.6-1.7 1.2-2.2.6-.7 1.4-1 2.2-.8z"
        />
        <ellipse cx={275.1} cy={157.1} rx={0.4} ry={0.8} />
      </g>
    </svg>
  );
}
