import { TError, IConstraints } from '../types';

import { translations } from './translations';

/**
 * -------------------------------------------------------------------------
 * Validates input based on the constrained format and regular expressions.
 *
 * @param value Value of the input to be validated.
 * @param constraints Options to determine length validation.
 * @returns error Can be as string or undefined.
 */
export const validateInputTypeAndFormat = (
  value: string,
  constraints: IConstraints
): TError => {
  const { type, format, regExp, feedbackLabel, required } = constraints;

  const { length } = value;
  let error: TError;

  if (length <= 0) {
    if (required) {
      error = `${feedbackLabel} is required`;
      return error;
    }
    error = undefined;
    return error;
  }

  // eslint-disable-next-line no-useless-escape
  let tempRegExp = /^[a-zA-Z0-9._@&\-\/'+:]{2,30}$/;
  let errorMsg: TError;

  switch (type) {
    case 'tel': {
      // ^[0-9][0-9 \-]{1,14}[0-9]$
      // ^(?![ -])(?!.*[- ]$)(?!.*[- ]{2})[0-9- ]+$
      // /^(?:0|\(?\+971\)?\s?|014\s?|039\s?)[1-79](?:[.\-\s]?\d\d){4}$/;

      // Disallow spaces and hythens between start and end and repetitions.
      tempRegExp = regExp || /^(?![ -])(?!.*[- ]$)(?!.*[- ]{2})[0-9- ]+$/;
      // errorMsg = 'Please insert a valid contact number';
      errorMsg = translations.insertValidContact;
      break;
    }

    case 'email': {
      tempRegExp =
        regExp ||
        /^[^\s@<>()[\]\\.,;:"]+(\.[^\s@<>()[\]\\.,;:"]+)*@(([a-zA-Z\-0-9]|[^\x20-\x7F])+(\.([a-zA-Z\-0-9]|[^\x20-\x7F]){2,})+)$/;
      errorMsg = translations.insertValidEmail;
      break;
    }

    case 'password': {
      tempRegExp = regExp || /\.*/;
      errorMsg = translations.insertValidPassword;

      if (constraints.compareWith) {
        const { value: comparedValue, message } = constraints.compareWith;
        if (value !== comparedValue) {
          return message;
        }
      }
      break;
    }

    case 'number': {
      if (format === 'identification-no') {
        tempRegExp = regExp || /^[a-zA-Z0-9 ]{6,30}$/;
        errorMsg = `${feedbackLabel || 'This ID'} format is invalid`;
      } else if (format === 'tin-no') {
        tempRegExp = regExp || /^(?![-])(?!.*[-]$)(?!.*[-]{2})[0-9-]+$/;
        errorMsg = `${feedbackLabel || 'This TIN'} format is invalid`;
      } else {
        tempRegExp = regExp || /^[0-9\-.]{3,20}$/;
        errorMsg = translations.insertValidID;
      }
      break;
    }

    default: {
      if (format === 'person-name') {
        tempRegExp = regExp || /^[a-zA-Z ]{2,50}$/;
        errorMsg = translations.invalidNameFormat;
      } else if (format === 'person-other') {
        tempRegExp = regExp || /(^$)|^[a-zA-Z ]{2,50}$/; // Match empty or 2-20 char eg Li, Jr
        errorMsg = translations.invalidNameFormat;
      } else if (format === 'person-fullname') {
        // ToDo: Fix this. Validation fails on space within person-fullname
        tempRegExp =
          regExp || /^[a-zA-Z]]*(([-.' ]|\s)*[a-zA-Z])*[a-zA-Z]{3,60}$/;
        errorMsg = translations.invalidFullNameFormat;
      } else if (format === 'person-username') {
        tempRegExp = regExp || /^[a-zA-Z0-9._-]{2,20}$/;
        errorMsg = translations.invalidUsernameOrIDFormat;
      } else if (format === 'person-id') {
        tempRegExp = regExp || /^[A-Z0-9]{8,20}$/;
        errorMsg = translations.invalidIDFormat;
      } else if (format === 'title') {
        tempRegExp =
          regExp ||
          /^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$/;
        errorMsg = `${feedbackLabel || 'This input'} format is invalid`;
      } else if (format === 'class-name') {
        tempRegExp =
          regExp ||
          /^[a-zA-Z0-9]*(([-_]|\s)*[a-zA-Z0-9-.])*[a-zA-Z0-9]{1,20}$/g;
        errorMsg = `${feedbackLabel || 'This input'} format is invalid`;
      }
    }
  }

  if (!tempRegExp.test(value)) {
    return errorMsg;
  }
  return undefined;
};
