import { useState, PropsWithChildren, ReactNode } from 'react';

interface ITooltipProps {
  children: ReactNode;
  /**
   * For showing tooltip message on `Tooltip`
   */
  message?: string;
  popup?: ReactNode;
}

/**
 * -----------------------------------------------------------------------------
 * This component renders its children with hover tooltip it accepts one message
 * parameter for showing tooltip.
 *
 * @param IPopOverProps -  expect `message`
 *
 * @return JSX.Element
 */
export function Tooltip({
  children,
  message,
  popup,
}: PropsWithChildren<ITooltipProps>) {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowTooltip = () => {
    setShowPopup(() => true);
  };

  const handleHideTooltip = () => {
    setShowPopup(() => false);
  };

  if (popup) {
    return (
      <div onMouseEnter={handleShowTooltip} onMouseLeave={handleHideTooltip}>
        {children}
        {showPopup ? popup : null}
      </div>
    );
  }

  return (
    <div
      className="w-fit"
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
    >
      {children}
      {message && showPopup ? (
        <p
          className="
            m-w-[100px] absolute z-10 mt-2 max-w-xs
            rounded bg-white p-2 text-sm
            font-normal drop-shadow-md
          "
        >
          {message}
        </p>
      ) : null}
    </div>
  );
}
