import { IActivityLog, TSet } from '@keymono/shared-types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This updates the activity log based on adding new or removing sessions.
 */
export function updateActivityLogCallback(set: TSet) {
  return function updateAuthStore(updatedLog: IActivityLog) {
    set(
      ({
        activeSession,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
        sessionsUnderSignOut,
      }) => {
        const persistState = {
          activityLog: updatedLog,
          activeSession,
          allSessionsById,
          allSessionsByIndex,
          allSessionsIndices,
          sessionsUnderSignOut,
        };

        persistAuthStore(persistState);

        return {
          activityLog: updatedLog,
        };
      }
    );
  };
}
