import { TSvgComponentProps } from '../../../types';

import { SVGContainer } from '../styles';

/**
 * -----------------------------------------------------------------------------
 * Shows gradient shapes used as backgrounds.
 */
export function BlobVariant1({ className }: TSvgComponentProps) {
  return (
    <SVGContainer
      // xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 1600 900"
      viewBox="0 0 900 600"
      xmlSpace="preserve"
      // width="100rem"
      // height="56.25rem"
      preserveAspectRatio="none"
      className={className}
    >
      <path fill="#fff" d="M0 0h900v600H0z" />
      <path
        fill="#ffe8e8"
        d="M548.5 600c6.3-22.4 12.5-44.8 21-65.7 8.4-21 19-40.4 32.1-57.9 13.1-17.5 28.7-33.1 45.6-45.3 17-12.2 35.2-20.9 46.3-37.6 11.2-16.7 15.3-41.3 25.4-64.6 10.1-23.2 26.1-44.9 46.6-53.7 20.5-8.7 45.4-4.4 68.6-6.7 23.1-2.3 44.5-11.2 65.9-20V600Z"
      />
      <path
        fill="#fdd1d1"
        d="M724.2 600c3.2-11.2 6.3-22.4 10.5-32.9 4.3-10.4 9.5-20.1 16.1-28.9 6.5-8.8 14.4-16.6 22.8-22.6 8.5-6.1 17.6-10.5 23.2-18.8 5.5-8.4 7.6-20.7 12.6-32.3 5.1-11.6 13.1-22.5 23.3-26.9 10.3-4.4 22.7-2.2 34.3-3.4 11.6-1.1 22.3-5.5 33-10V600Z"
      />
      <path
        fill="#ffe8e8"
        d="M351.5 0c-29.5 18.1-59 36.2-66.1 56.8-7.1 20.5 8.3 43.5 12.1 66.4 3.8 22.9-4 45.7-14.8 65.7-10.8 19.9-24.6 37-38.7 55.1-14.2 18-28.6 36.9-48.7 48.3-20.1 11.4-45.9 15.3-70.2 9.8-24.3-5.5-47.1-20.4-67.7-13.7-20.7 6.6-39 34.9-57.4 63.1V0Z"
      />
      <path
        fill="#fdd1d1"
        d="M175.8 0c-14.8 9.1-29.6 18.1-33.1 28.4-3.5 10.3 4.1 21.8 6 33.2 1.9 11.5-1.9 22.9-7.4 32.8-5.4 10-12.3 18.6-19.3 27.6-7.1 9-14.3 18.4-24.3 24.1-10.1 5.8-23 7.7-35.1 5-12.2-2.8-23.6-10.3-33.9-6.9-10.3 3.3-19.5 17.4-28.7 31.6V0Z"
      />
    </SVGContainer>
  );
}
