// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';

import { IUserTokenInfo } from '@keymono/shared-types';

import { useAuthStore } from '../useAuthStore';

/**
 * This is used to update the store token information outside of the react
 * components.
 *
 * Using `unstable_batchedUpdates` to prevent inconsistent state updates.
 * [Zombie child, yet again](https://github.com/pmndrs/zustand/issues/302).

 * @param userTokenInfo IUserTokenInfo - The `token`, `refreshToken` and `userId`.
 */
export const updateAuthStoreTokenInfo = (userTokenInfo: IUserTokenInfo) => {
  unstable_batchedUpdates(() => {
    useAuthStore.getState().updateTokenInfo(userTokenInfo);
  });
};
