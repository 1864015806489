// import { TTextLocalized } from '../types';

/**
 * This manages reusable translations common to `validation` utility functions.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations = {
  this: {
    en: 'This',
    cn: '这',
    ar: 'هذه',
  },
  isRequired: {
    en: 'is required',
    cn: '是必须的',
    ar: 'مطلوب',
  },
  insertValidContact: {
    en: 'Please insert a valid contact number',
    cn: 'Please insert a valid contact number',
    ar: 'الرجاء إدخال رقم اتصال صالح',
  },
  insertValidEmail: {
    en: 'Please insert a valid email address',
    cn: 'Please insert a valid email address',
    ar: 'الرجاء إدخال عنوان بريد إلكتروني صالح',
  },
  insertValidPassword: {
    en: 'Please enter a valid password',
    cn: 'Please enter a valid password',
    ar: 'الرجاء إدخال كلمة السر الصحيحة',
  },
  insertValidID: {
    en: 'Please insert a valid ID',
    cn: 'Please insert a valid ID',
    ar: 'الرجاء إدخال هوية صالحة',
  },
  invalidNameFormat: {
    en: 'Invalid name format',
    cn: 'Invalid name format',
    ar: 'تنسيق اسم غير صالح',
  },
  invalidFullNameFormat: {
    en: 'Invalid fullname format',
    cn: 'Invalid fullname format',
    ar: 'تنسيق الاسم الكامل غير صالح',
  },
  invalidUsernameOrIDFormat: {
    en: 'Invalid User Name/ID format',
    cn: 'Invalid User Name/ID format',
    ar: 'اسم المستخدم / تنسيق معرف غير صالح',
  },
  invalidIDFormat: {
    en: 'Invalid ID format',
    cn: 'Invalid ID format',
    ar: 'تنسيق الهوية غير صالح',
  },
  insertValidDate: {
    en: 'Please enter a valid date',
    cn: 'Please enter a valid date',
    ar: 'ارجوك ادخل تاريخ صحيح',
  },
  max: {
    en: 'max:',
    cn: 'max:',
    ar: 'طويل جدا',
  },
  min: {
    en: 'min:',
    cn: 'min:',
    ar: 'الأعلى',
  },
  charCount: (min: number | string, max: number | string) => ({
    en: `must be between ${min} - ${max} characters`,
    cn: `must be between ${min} - ${max} characters`,
    ar: `أحرف ${max} - ${min} يجب أن يكون بين `,
  }),
  tooShort: (min: number | string) => ({
    en: `is too short (Min: ${min})`,
    cn: `is too short (Min: ${min})`,
    ar: `قصير جدًا (الحد الأدنى: ${min})`,
  }),
  tooLong: (max: number | string) => ({
    en: `is too long (Max: ${max})`,
    cn: `is too long (Max: ${max})`,
    ar: `طويل جدًا (الحد الأقصى: ${max})`,
  }),
};
