import { SVGProps } from 'react';

import { StyledSVGLogomark } from './styles';

/**
 * -----------------------------------------------------------------------------
 * The keymono logomark as a customizable SVG Element to be used across the app.
 * To be used in small spaces where the whole logo does not fit.
 */
export function LogoKeymonoLogomark({ className }: SVGProps<SVGSVGElement>) {
  return (
    <StyledSVGLogomark
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 512 512"
      height={512}
      width={512}
      className={`cursor-pointer ${className}`}
    >
      <path
        fill="#b7202a"
        d="M292.8 294.8c45.4-40.8 95.7-104.2 130.6-170.5l-7-10-115.7 10c-31.4 62.7-80.6 108.3-114.5 128.9V36.6l-7-10-99.6 10-7 421.1 7 10h106.6v-103c6.1-2.3 13.4-5.8 22-10.5L309 468.6h138.3l-5.2-10-149.3-163.8z"
      />
      <path
        fill="#eb2027"
        d="M285.8 284.8c45.4-40.8 95.7-104.2 130.6-170.5H293.7c-31.4 62.7-80.6 108.3-114.5 128.9V26.6H72.7v431.1h106.6v-103c6.1-2.3 13.4-5.8 22-10.5L302 458.6h138.3L285.8 284.8z"
      />
    </StyledSVGLogomark>
  );
}
