import Lottie from 'lottie-react';

import tickAnimationFile from './raw-json/tick-checkmark-light.json';

interface ITickAnimationProps {
  className?: string;
}

/**
 * Shows an animated checkmark micro animation to be used when an action has
 * been completed successfully.
 */
export function TickAnimation({ className = '' }: ITickAnimationProps) {
  return (
    <div className={className}>
      <Lottie animationData={tickAnimationFile} loop className="flex" />
    </div>
  );
}
