import { ReactNode, Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { IChoice } from '../types';

interface IRadioDropdownProps {
  icon?: ReactNode;
  label: string;
  containerClassName?: string;
  buttonClassName?: string;
  options: IChoice[];
  onChange?: (option: IChoice) => void;
  selectedOption?: IChoice | null;
}

/**
 * -----------------------------------------------------------------------------
 * A Button with a dropdown menu that contains Radio options.
 * The first option is selected by default.
 */
export function RadioDropdown({
  icon: Icon,
  label,
  containerClassName = '',
  buttonClassName = '',
  options,
  onChange,
  selectedOption,
}: IRadioDropdownProps) {
  const PLACEHOLDER_OPTION: IChoice = {
    key: '',
    label: label ?? '... Select',
  };

  const [selectedItem, setSelectedItem] = useState<IChoice>(
    selectedOption ?? PLACEHOLDER_OPTION
  );

  const handleOnSelect = (option: IChoice) => {
    setSelectedItem(option);
    onChange?.(option);
  };

  const placeholderClassName = !selectedItem.key ? 'text-gray-400' : '';
  const componentId = label.replace(/\s/g, '-').toLowerCase();

  return (
    <Popover
      as="div"
      key={label}
      id={`desktop-menu-${componentId}`}
      className={`form-btn relative inline-block rounded-lg  border border-gray-100 bg-white text-left shadow-sm hover:bg-white ${containerClassName}`}
    >
      <Popover.Button
        className={`group flex w-full items-center justify-between gap-x-3 whitespace-nowrap text-sm font-medium text-gray-500 hover:text-gray-900 focus-visible:outline-0 ${buttonClassName}`}
      >
        <span className={`focus-visible:outline-0 ${placeholderClassName}`}>
          {selectedItem.label}
        </span>
        {Icon ?? (
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-4 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <form className="space-y-4">
            {options.map((option, optionIdx) => {
              const inputId = `filter-${componentId}-${optionIdx}`;

              return (
                <div key={option.key} className="flex items-center">
                  <input
                    id={inputId}
                    name={`${componentId}[]`}
                    defaultChecked={selectedItem === option}
                    type="radio"
                    onChange={() => handleOnSelect(option)}
                    className="text-colorPri-600 focus:ring-colorPri-600 h-4 w-4 border-gray-300"
                  />
                  <label
                    htmlFor={inputId}
                    className="ms-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-600"
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
