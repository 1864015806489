import { IInputElement } from '../../types';

import { InputTextElement } from '../../form-inputs';

type TEmailAddressInputProps = Pick<
  IInputElement,
  | 'className'
  | 'classNameIconEnd'
  | 'constraints'
  | 'editable'
  | 'error'
  | 'label'
  | 'isLabelEnabled'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'value'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture the email address of a person or organization,
 * and tries to add constraints that are used to validate the expected format of
 * a valid email.
 */
export function EmailAddressInputField({
  className,
  classNameIconEnd,
  constraints = { maxLength: 50, format: 'email' },
  editable,
  error,
  label,
  isLabelEnabled,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  value,
}: TEmailAddressInputProps) {
  return (
    <InputTextElement
      className={className}
      classNameIconEnd={classNameIconEnd}
      constraints={{
        feedbackLabel: 'The email address',
        maxLength: 80,
        minLength: 7, // a@bo.ae
        required: true,
        type: 'email',
        ...constraints,
      }}
      editable={editable}
      error={error}
      hasClearButton
      isLabelEnabled={isLabelEnabled}
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder || 'Email Address'}
      showIsRequired={false}
      type="email"
      value={value}
    />
  );
}
