/**
 * This manages reusable translations common to `packages/i18n` components.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations = {
  region: {
    en: 'Region',
    cn: 'Region',
    ar: 'منطقة',
  },
  selectARegion: {
    en: 'Please select a region',
    cn: 'Please select a region',
    ar: 'الرجاء تحديد منطقة',
  },
};
