import { create as createStore } from 'zustand';
import { IAuthState } from '@keymono/shared-types';

import {
  addNewSessionCallback,
  loadPersistedAuthStore,
  removeAllSessionsCallback,
  removeSessionCallback,
  updateActiveOrganization,
  updateActiveSessionCallback,
  updateActivityLogCallback,
  updateTokenInfoCallback,
  updateProfileDetailsCallback,
  updateSessionsUnderSignOutCallback,
} from './callbacks';

/**
 * This creates a local session state tracker and update callbacks to track all
 * the concurrent active sessions, the activity logs & device information.
 *
 * TODO: For not trackable sessions, remove them on app mount when not set to
 * remember me. Also check if their session expired and then flush them out of
 * the session.
 */
export const useAuthStore = createStore<IAuthState>((set) => {
  const localAuthData = loadPersistedAuthStore();

  return {
    activeSession: localAuthData?.activeSession || null,
    activityLog: localAuthData?.activityLog || null,
    allSessionsById: localAuthData?.allSessionsById || null,
    allSessionsByIndex: localAuthData?.allSessionsByIndex || null,
    allSessionsIndices: localAuthData?.allSessionsIndices || [],
    sessionsUnderSignOut: localAuthData?.sessionsUnderSignOut || null,
    addNewSession: addNewSessionCallback(set),
    removeSession: removeSessionCallback(set),
    updateActiveOrganization: updateActiveOrganization(set),
    removeAllSessions: removeAllSessionsCallback(set),
    updateActiveSession: updateActiveSessionCallback(set),
    updateActivityLog: updateActivityLogCallback(set),
    updateTokenInfo: updateTokenInfoCallback(set),
    updateProfileDetails: updateProfileDetailsCallback(set),
    updateSessionsUnderSignOut: updateSessionsUnderSignOutCallback(set),
  };
});
