import { SimpleButtonWrapper } from './button-styles';

interface ISimpleButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;

  /**
   * Custom color to further format the button.
   */
  className?: string;

  /**
   * What background color to use
   */
  backgroundColor?: string;

  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * Button contents
   */
  label: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Primary UI component for user interaction
 */
export function SimpleButton({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  ...props
}: ISimpleButtonProps) {
  const mode = primary
    ? 'text-white bg-red-600'
    : 'storybook-button--secondary';

  return (
    <SimpleButtonWrapper
      type="button"
      className={[
        `storybook-button--${size}`,
        'font-primary-en rtl:font-primary-ar inline-block cursor-pointer rounded-3xl border-0 font-normal',
        'bg-transparent text-gray-800 shadow-sm',
        mode,
      ].join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {label}
    </SimpleButtonWrapper>
  );
}
