import { useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import {
  DateType,
  DateValueType,
} from 'react-tailwindcss-datepicker/dist/types';

import { useI18n } from '@keymono/i18n';

interface ICalendarDropdownProps {
  label: string;
  singleMonth?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  inputWidth?: string;
  separator?: string;
  onChange?: (dateRange: DateValueType) => void;
  startDate: DateType;
  endDate: DateType;
  minDate?: Date | null;
  maxDate?: Date | null;
}

/**
 * -----------------------------------------------------------------------------
 * A Button with a dropdown menu that contains a Date Range picker calendar.
 */
export function CalendarDropdown({
  label,
  singleMonth = false,
  containerClassName = '',
  inputClassName = '',
  inputWidth = 'w-max',
  separator = 'to',
  onChange = () => {},
  startDate,
  endDate,
  minDate = null,
  maxDate = null,
}: ICalendarDropdownProps) {
  const { locale } = useI18n().language;

  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate,
    endDate,
  });

  const handleValueChange = (newValue: DateValueType) => {
    if (
      minDate &&
      newValue?.startDate &&
      new Date(newValue.startDate).getTime() < minDate.getTime()
    ) {
      // eslint-disable-next-line no-param-reassign
      newValue.startDate = startDate;
    }
    if (
      maxDate &&
      newValue?.endDate &&
      new Date(newValue.endDate).getTime() > maxDate.getTime()
    ) {
      // eslint-disable-next-line no-param-reassign
      newValue.endDate = maxDate;
    }
    setDateRange(newValue);
    onChange(newValue);
  };

  return (
    <Datepicker
      i18n={locale}
      useRange={!singleMonth}
      showShortcuts
      containerClassName={`relative text-gray-600
        [&>.transition-all>.absolute]:ms-[1.25rem]
        [&>.transition-all>.absolute]:[margin-left:unset]
        ${containerClassName}`}
      inputClassName={`form-btn font-medium text-gray-600 text-start shadow-sm
      placeholder:font-medium hover:bg-white focus-visible:border-gray-100
        rounded-lg border border-gray-100 bg-white placeholder:text-gray-400
        ${inputWidth} ${inputClassName}`}
      toggleClassName="absolute end-5 h-full text-gray-400
        focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
      showFooter={false}
      placeholder={label}
      primaryColor="red"
      separator={separator} // default is "~"
      displayFormat="MM/DD/YYYY"
      value={dateRange}
      readOnly
      onChange={handleValueChange}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
