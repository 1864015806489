import {
  loadLocalData,
  saveLocalData,
  STORAGE_KEY_AUTH_STATE,
} from '@keymono/utilities';

import { IAuthState } from '@keymono/shared-types';

export type IPersistAuthStoreTargetState = Pick<
  IAuthState,
  | 'activeSession'
  | 'activityLog'
  | 'allSessionsById'
  | 'allSessionsByIndex'
  | 'allSessionsIndices'
  | 'sessionsUnderSignOut'
>;

/**
 * This persists the session information to secure local storage.
 */
export const persistAuthStore = (targetState: IPersistAuthStoreTargetState) => {
  saveLocalData(STORAGE_KEY_AUTH_STATE, targetState);
};

/**
 * This retrieves and decrypts the session information from secure local storage.
 */
export const loadPersistedAuthStore = () =>
  loadLocalData<IPersistAuthStoreTargetState>(STORAGE_KEY_AUTH_STATE);
