import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

interface IUseDebouncedInputOptions<T> {
  /**
   * The initial Value
   */
  initialValue: T;
  /**
   * Duration in `ms` to wait for the debounce. Default is `500 ms`
   */
  delay?: number;
  /**
   * Callback to execute after the delay with updated value.
   */
  onChangeCb: (value: T) => void;
}

/**
 * -----------------------------------------------------------------------------
 * Adds a delay to the callback function. It can be used on search API call and
 * for other delayed output.
 *
 * @param TUseDebouncedInputOptions
 *
 * @returns {{debouncedValue, setDebouncedValue, handleChangeDebounced}}
 * - debounced value of the element
 * - set hook to set the debounced value.
 * - callback function to be called after the delay.
 */
export const useDebounce = <T>({
  initialValue,
  delay = 500,
  onChangeCb,
}: IUseDebouncedInputOptions<T>) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const handleChangeDebounced = useRef(debounce(onChangeCb, delay)).current;

  useEffect(() => {
    handleChangeDebounced.cancel();
  }, [handleChangeDebounced]);

  useEffect(() => {
    setDebouncedValue(() => initialValue);
  }, [initialValue]);

  return { debouncedValue, setDebouncedValue, handleChangeDebounced };
};
