import { ReactElement, useState } from 'react';
import { Switch } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface IToggleButtonProps {
  containerClassName?: string;
  buttonClassName?: string;
  falseIcon?: ReactElement;
  trueIcon?: ReactElement;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

export function ToggleButton({
  containerClassName = '',
  buttonClassName = '',
  falseIcon: FalseIcon,
  trueIcon: TrueIcon,
  value = false,
  onChange,
}: IToggleButtonProps) {
  const [enabled, setEnabled] = useState<boolean>(value);

  const handleOnChange = (newValue: boolean) => {
    setEnabled(newValue);
    onChange?.(newValue);
  };

  return (
    <Switch
      checked={enabled}
      onChange={handleOnChange}
      className={`focus:ring-colorPri-600 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        enabled ? 'bg-colorPri-600' : 'bg-gray-200'
      } ${containerClassName}`}
    >
      <span
        className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
          enabled ? 'translate-x-5' : 'translate-x-0'
        } ${buttonClassName}`}
      >
        <span
          className={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${
            enabled
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in'
          }`}
          aria-hidden="true"
        >
          {FalseIcon ?? (
            <XMarkIcon className="h-3 w-3 stroke-gray-500 text-gray-400" />
          )}
        </span>
        <span
          className={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${
            enabled
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out'
          }`}
          aria-hidden="true"
        >
          {TrueIcon ?? (
            <CheckIcon className="text-colorPri-600 h-3 w-3 stroke-2" />
          )}
        </span>
      </span>
    </Switch>
  );
}
