import { IActiveSession, TSet } from '@keymono/shared-types';

import {
  IPersistAuthStoreTargetState,
  persistAuthStore,
} from './authStoreLocalStorageUtils';

/**
 * This updates the active session in both local & app memory.
 */
export function updateActiveSessionCallback(set: TSet) {
  return function updateAuthStore(session: IActiveSession) {
    set(
      ({
        activeSession,
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
        sessionsUnderSignOut,
      }) => {
        if (!activeSession === null) return { activeSession };
        const { userId, index } = session;

        /**
         * Update the normalized session info to update this to.
         */
        const updatedAllSessionsById = {
          ...allSessionsById,
          [userId]: session,
        };
        const updatedAllSessionsByIndex = {
          ...allSessionsByIndex,
          [index]: session,
        };

        const updatedStoreFields: IPersistAuthStoreTargetState = {
          activityLog,
          allSessionsIndices,
          activeSession: session,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          sessionsUnderSignOut,
        };

        persistAuthStore(updatedStoreFields);

        return updatedStoreFields;
      }
    );
  };
}
