import { IMembership } from '@keymono/shared-types';
import { useAuthStore } from '../../auth-store';

/**
 * This AuthStore sucks so much. This hook doesn't work because someone doesn't
 * set activeOrgId all the time. It is declared as optional in IActiveSession
 *
 * Useless!
 *
 * Have moved this to AccountMenu where we look at
 * activeOrgId or fallback to the orgId set in router
 */
export function useActiveOrgMembership(): IMembership | null {
  const { activeSession } = useAuthStore();
  if (!activeSession) return null;

  const {
    activeOrgId,
    user: { membership },
  } = activeSession;
  if (!activeOrgId) return null;

  function findActiveMembership(_membership: IMembership): boolean {
    return _membership.org === activeOrgId;
  }

  const found = membership.find(findActiveMembership);
  if (!found) return null;

  return found;
}
