import { Text, TTextLocalized } from '@keymono/i18n';

import { XCircleIcon } from '@heroicons/react/24/outline';

import { CardSecButton } from '../../../buttons';

import { IBaseUIFallbackCommonOptions } from '../../types';

interface IGenericFallbackErrorProps extends IBaseUIFallbackCommonOptions {
  title?: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a simple error page shown when the parent component has not
 * provided a fallback error pge.
 */
export function GenericFallbackError({
  error,
  onRetryAction,
  title,
}: IGenericFallbackErrorProps) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title ? (
            <h3 className="text-sm font-medium text-red-800">
              <Text text={title} />
            </h3>
          ) : null}
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              <li>{error?.message}</li>
              <li>{error?.description}</li>
            </ul>
          </div>
          <CardSecButton onClick={onRetryAction.onClick}>
            <Text text={onRetryAction.label || 'Retry'} />
          </CardSecButton>
        </div>
      </div>
    </div>
  );
}
