import { ChangeEvent, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { IChoice } from '../../types';

interface IChoicesSelectDropdownWithSearchProps {
  choices: IChoice[];
  classNameButton?: string;
  classNameContainer?: string;
  classNameInput?: string;
  classNameInputContainer?: string;
  classNameLabel?: string;
  icon?: JSX.Element;
  label?: string;
  onChange: (choice: IChoice) => void;
  onUpdateSearchKey?: (searchKey: string) => void;
  pattern?: string | null;
  placeholder?: string;
  selectedChoice: IChoice | null;
}

/**
 * -----------------------------------------------------------------------------
 * This provides options for selecting a choice from a list.
 * It also provides a search input.
 * It replaces the native unstyled dropdown component.
 */
export function ChoicesSelectDropdownWithSearch({
  choices,
  classNameButton = '',
  classNameContainer = '',
  classNameInput = '',
  classNameInputContainer = '',
  classNameLabel = '',
  label,
  icon: DropdownIcon = <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />,
  onChange,
  onUpdateSearchKey,
  pattern,
  placeholder,
  selectedChoice,
}: IChoicesSelectDropdownWithSearchProps) {
  const PLACEHOLDER_CHOICE: IChoice = {
    key: '',
    label: placeholder ?? 'Search...',
  };

  const [selectedItem, setSelectedItem] = useState(
    selectedChoice ?? PLACEHOLDER_CHOICE
  );

  const handleOnSelect = (item: IChoice) => {
    setSelectedItem(item);
    onChange(item);
  };

  const [query, setQuery] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // set query value only if it matches the pattern
    if (pattern) {
      const regex = new RegExp(pattern);
      if (regex.test(event.target.value)) {
        setQuery(event.target.value);
      }
    } else {
      setQuery(event.target.value);
    }
    onUpdateSearchKey?.(event.target.value);
  };

  /* eslint-disable indent */
  /* eslint-disable function-paren-newline */
  const filteredChoices =
    query === ''
      ? choices
      : choices.filter((choice) =>
          choice.label.toLowerCase().includes(query.toLowerCase())
        );
  /* eslint-enable indent */
  /* eslint-enable function-paren-newline */

  return (
    <Combobox
      className={`flex items-center gap-x-4 ${classNameContainer}`}
      as="div"
      value={selectedChoice}
      onChange={handleOnSelect}
    >
      {label ? (
        <Combobox.Label
          className={`block text-sm font-medium leading-6 text-gray-900 ${classNameLabel}`}
        >
          {label}
        </Combobox.Label>
      ) : null}
      <div className={`relative ${classNameInputContainer}`}>
        <Combobox.Input
          className={`focus:ring-colorPri-300 truncate rounded-lg border-0 bg-white py-2.5 pe-12 ps-4 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:font-medium placeholder:text-gray-400 hover:bg-white focus:ring-1 focus:ring-inset ${classNameInput}`}
          onChange={handleInputChange}
          placeholder={`${selectedItem?.label}`}
          value={query}
          pattern={pattern ?? undefined}
        />
        <Combobox.Button
          className={`absolute inset-y-0 end-5 flex items-center rounded-r-md focus:outline-none ${classNameButton}`}
        >
          {DropdownIcon}
        </Combobox.Button>

        {filteredChoices.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredChoices.map((choice) => {
              const isSelected = choice.key === selectedChoice?.key;
              return (
                <Combobox.Option
                  key={choice.key}
                  value={choice}
                  className={`relative cursor-pointer select-none py-2 pe-8 ps-3 text-gray-900 ${
                    isSelected ? 'bg-red-600 text-white' : ''
                  }`}
                >
                  <>
                    <span
                      className={`block truncate ${
                        isSelected && 'font-semibold'
                      } text-gray-600`}
                    >
                      {choice.label}
                    </span>

                    {isSelected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pe-2 ${
                          isSelected ? 'text-white' : 'text-red-600'
                        }
                          `}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
