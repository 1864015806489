import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This styles the main div that surrounds the whole form.
 */
export const LocaleContainer = styled.div`
  ${tw`
    relative
  `};

  &.is-inline {
    ${tw`
    `};
  }

  &.is-floating {
    ${tw`
      absolute
      top-8
      z-50
      transition-all
      duration-500
      ease-in

      ltr:right-6
      rtl:left-6

      sm:top-10
      sm:ltr:right-20
      sm:rtl:left-20

      md:ltr:right-12
      md:rtl:left-12

      lg:ltr:right-24
      lg:rtl:left-24
   `};
  }
`;
