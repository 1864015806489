import { Button } from '../Button';

interface ICardButtonProps {
  className?: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  label: string;
  loadingLabel?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a primary button component used to trigger form submissions on a
 * card.
 */
export function CardSubmitButton({
  className = '',
  isLoading = false,
  isEnabled = true,
  label,
  loadingLabel = '',
}: ICardButtonProps) {
  return (
    <Button
      type="submit"
      className={`form-btn w-auto px-8 py-1 text-xs ${className}`}
      isEnabled={isEnabled}
      isLoading={isLoading}
      label={label}
      loadingLabel={loadingLabel}
    />
  );
}
