import { ReactNode } from 'react';

interface IHamburgerMenuItemProps {
  children: ReactNode;
  className?: string;
}

export function HamburgerMenuItem({
  children,
  className = '',
}: IHamburgerMenuItemProps) {
  return (
    <div className={`border-t border-gray-200 p-4 ${className}`}>
      {children}
    </div>
  );
}
