import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * A rectangular Icon/Flag that shows the Arabic language symbol in focus.
 * Indicate a trigger to switch the locale to the Arabic language.
 */
export function FlagArabic({
  size = 32,
  className,
  ...rest
}: TSvgComponentProps) {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlSpace="preserve"
      id="flag-arabic"
      height={size}
      width={size * 1.7658}
      className={`rounded-lg bg-red-700 fill-white p-1 ${className}`}
      viewBox="0 0 442 250"
      {...rest}
    >
      <path d="M55.21 45.64h19.43l54.37 126.21h-25.67l-11.77-28.88H36.85l-11.41 28.88H.31l54.9-126.21zm28.53 78.08L64.3 72.38l-19.78 51.34h39.22zM132.09 45.64h43.85c6.06 0 11.91.57 17.56 1.69 5.64 1.13 10.67 3.06 15.06 5.79 4.4 2.74 7.9 6.42 10.52 11.05 2.61 4.64 3.92 10.52 3.92 17.65 0 9.15-2.53 16.82-7.58 23s-12.27 9.92-21.66 11.23l33.51 55.8h-27.1l-29.24-53.48h-16.4v53.48h-22.46V45.64h.02zm39.93 53.48c3.21 0 6.42-.15 9.63-.45 3.21-.3 6.15-1.01 8.82-2.14 2.67-1.13 4.84-2.85 6.51-5.17 1.66-2.32 2.5-5.55 2.5-9.72 0-3.68-.77-6.65-2.32-8.91a14.456 14.456 0 0 0-6.06-5.08c-2.5-1.13-5.26-1.87-8.29-2.23s-5.97-.53-8.82-.53h-19.43v34.23h17.46zM231.53 248.94h-22.88L287.16 1.06h21.71l-77.34 247.88zM438.63 70.94c2.47 15.19 5.36 28.49.15 42.21-6.52 17.18-22.65 27.43-40.13 8.1-6.47 9.17-15.38 13.64-26.74 12.62-.85 4.87-1.25 9.31-2.42 13.54-5.64 20.39-20.67 30.12-40.16 34.02-15.1 3.02-29.02.18-39.98-11.47-6.35-6.75-8.89-15.3-9.69-24.39-1.4-15.89 1.98-30.66 9.77-44.42 1.18.15 1.79.1 2.28.32 1.05.45 2.04 1.05 2.91 1.51-1.8 6.49-4.09 12.66-5.17 19.04-4.73 28.17 16.17 46.88 43.7 39.33 2.79-.76 5.58-1.75 8.13-3.1 24.99-13.27 26.58-23.1 16.54-44.99-1.21-2.64-2.34-5.42-4.09-7.67-4.75-6.1-3.61-11.6.91-17.12 1.46-1.78 2.63-3.79 4.65-6.76 1.96 4.26 3.66 7.62 5.07 11.09 1.68 4.12 3.12 8.33 4.64 12.51 1.82 5.01 3.62 10.45 10.03 10.59 6.56.15 12.25-2.71 15.47-8.6 2-3.67 3.48-7.89 4.01-12.02.82-6.37 4.68-9.17 9.64-10.97 0 5.41-.23 10.75.05 16.06.39 7.41 5.67 12.79 12.8 13.56 6.49.7 10.05-2.78 9.07-9.28-.75-4.97-1.58-9.98-3.09-14.75-1.23-3.85-.71-6.52 2.29-9.2 3.01-2.67 5.6-5.78 9.36-9.76z" />
      <path d="M413.4 67.05c-3.22 3.97-6.01 7.41-9.13 11.26-4.88-4.33-9.41-8.34-14.42-12.78 3.14-3.78 6.16-7.41 9.57-11.52 4.69 4.36 9.01 8.4 13.98 13.04zm-40.21-25.43c3.33-3.96 6.27-7.47 9.65-11.49 4.76 4.47 9.17 8.6 13.92 13.06-3.14 3.87-5.99 7.4-9.09 11.23-5-4.42-9.54-8.43-14.48-12.8zm3.2 13.86c4.78 4.52 9.08 8.59 13.87 13.12-3.05 3.83-5.89 7.4-9.01 11.31-4.84-4.29-9.39-8.31-14.41-12.76 2.95-3.61 5.92-7.24 9.55-11.67z" />
    </svg>
  );
}
