import { ForwardedRef, ReactNode, forwardRef } from 'react';

import { CircularLoader } from '../../loaders';
import { CardSecButtonWrapper } from '../button-styles';

interface ICardButtonProps {
  children: ReactNode;
  className?: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a simple button component (secondary) used as an alternative
 * action on a card. Such as cancelling a popped-up card.
 * - Inner component for `CardSecButton` to be fed with a ref.
 */
function CardSecButtonInner(
  {
    children,
    className = '',
    isEnabled = true,
    isLoading = false,
    onClick,
  }: ICardButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <CardSecButtonWrapper
      ref={ref}
      className={`form-btn-secondary ${className}`}
      disabled={!isEnabled}
      onClick={onClick}
      type="button"
    >
      {isLoading ? (
        <CircularLoader className="h-4 w-4 ltr:mr-4 rtl:ml-4" />
      ) : null}
      {children}
    </CardSecButtonWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders a simple button component (secondary) used as an alternative
 * action on a card. Such as cancelling a popped-up card.
 */
export const CardSecButton = forwardRef(CardSecButtonInner);
