import { RefObject, useRef } from 'react';
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { Text, TLocale } from '@keymono/i18n';
import { getTextFromI18n } from '@keymono/utilities';

import { IBaseInputTextItem } from '../../types';

import { TextInputContainer } from './styles';

interface ITextInputProps extends IBaseInputTextItem {
  /**
   * This provides options for translating the text input fields that cannot be
   * rendered by the Translation ready`i18n/Text`, such as the element title
   * attributes which only expect string.
   */
  locale: TLocale;
}

/**
 * -----------------------------------------------------------------------------
 * Creates a theme-able TextInput component with support for error self container
 * error feedback,loading and animated labels.
 *
 * TODO: Add loading and animatable labels.
 */
export function TextInput({
  // autoFocus = false, TODO: Implement Auto Focus
  className = '',
  classNameIconEnd = '',
  classNameInput = '',
  classNameInputContainer = '',
  classNameLabel = '',
  editable = true,
  error,
  handleClearInput,
  hasClearButton = false,
  helpText,
  iconStart,
  iconEnd,
  isLabelEnabled = true,
  label: fieldLabel,
  labelPostFix = '',
  locale,
  maxLength,
  minLength,
  name,
  onChange,
  onBlur,
  onFocus,
  placeholder = '',
  required,
  showIsRequired = true,
  type,
  value = '',
  valuePrefix = '',
}: ITextInputProps) {
  const inputRef: RefObject<HTMLInputElement> = useRef(null);

  const handleClear = () => {
    if (inputRef && handleClearInput) {
      inputRef.current?.focus();
      handleClearInput();
    }
  };

  const showRequiredStar = showIsRequired && required ? ' *' : '';
  const label = `${fieldLabel}${labelPostFix}${showRequiredStar}`;
  const ariaDescription = error ? `${label}-error` : label;

  const inputClasses = [];

  if (hasClearButton) inputClasses.push('input-has-x-btn');
  if (iconStart) inputClasses.push('input-has-leading-icon');
  if (valuePrefix) inputClasses.push('input-has-value-prefix');

  const errorClass = error ? 'input-has-error' : '';
  const translatedPlaceholder = getTextFromI18n({
    text: placeholder,
    locale,
  });

  return (
    <TextInputContainer className={`${className} ${errorClass}`}>
      {isLabelEnabled ? (
        <label className={classNameLabel} htmlFor={name}>
          <Text text={fieldLabel} />
          <Text text={labelPostFix} />
          <Text text={showRequiredStar} />
        </label>
      ) : null}

      <div className={`input-and-feedback ${classNameInputContainer}`}>
        <div className={`input-wrapper ${classNameInput}`}>
          <div className="flex">
            {valuePrefix ? (
              <span
                className="
                  inline-flex items-center border border-gray-200 bg-gray-50
                  px-3 text-gray-500 ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md
                  rtl:border-l-0 sm:text-sm
                "
              >
                {valuePrefix}
              </span>
            ) : null}
            <input
              aria-describedby={ariaDescription}
              aria-invalid={!!error}
              id={name}
              maxLength={maxLength}
              minLength={minLength}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              placeholder={translatedPlaceholder}
              ref={inputRef}
              required={required}
              type={type}
              value={value}
              disabled={!editable}
              className={inputClasses.join(' ')}
            />
          </div>

          <div className="input-icons-start">{iconStart || null}</div>
          <div className={`input-icons-end ${classNameIconEnd}`}>
            {iconEnd || null}
            {hasClearButton && value && editable ? (
              <XMarkIcon
                aria-hidden="true"
                className="icon-clear-input"
                onClick={handleClear}
              />
            ) : null}

            {error ? (
              <ExclamationCircleIcon
                className="icon-error-indicator"
                aria-hidden="true"
              />
            ) : null}
          </div>
        </div>

        <div>
          <p className="input-error-msg" id={`${name}-error`}>
            <Text text={error || ''} />
          </p>

          {helpText ? (
            <p className="input-helper-msg">
              <Text text={helpText} />
            </p>
          ) : null}
        </div>
      </div>
    </TextInputContainer>
  );
}
