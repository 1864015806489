import { SVGProps } from 'react';

import { StyledSVGLogo } from './styles';

/**
 * -----------------------------------------------------------------------------
 * This is the short form of the Keymono Web app, made uniquely from the
 * keymono logo elements, to be displayed in very tiny spaces on smaller screens.
 */
export function LogoKeymonoShort({ className }: SVGProps<SVGSVGElement>) {
  return (
    <StyledSVGLogo
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 460 100"
      height={32}
      width={148}
      className={className}
    >
      <path d="M320.24 66.11c-5.74 5.48-13.09 8.21-22.04 8.21s-16.24-2.68-21.87-8.04c-5.63-5.36-8.44-12.53-8.44-21.49 0-8.97 2.87-16.2 8.61-21.69 5.74-5.49 13.1-8.24 22.07-8.24s16.26 2.72 21.87 8.16c5.61 5.44 8.41 12.61 8.41 21.52s-2.87 16.1-8.61 21.57m-31.4-10.44c2.29 2.75 5.47 4.12 9.53 4.12s7.23-1.37 9.5-4.12c2.27-2.75 3.41-6.42 3.41-11.02s-1.14-8.27-3.41-11.02c-2.27-2.75-5.44-4.12-9.5-4.12s-7.24 1.37-9.53 4.12c-2.29 2.75-3.43 6.42-3.43 11.02s1.14 8.27 3.43 11.02m155.79 10.44c-5.74 5.48-13.09 8.21-22.04 8.21s-16.24-2.68-21.87-8.04c-5.63-5.36-8.44-12.53-8.44-21.49 0-8.97 2.87-16.2 8.61-21.69 5.74-5.49 13.1-8.24 22.07-8.24s16.26 2.72 21.87 8.16c5.61 5.44 8.41 12.61 8.41 21.52s-2.87 16.1-8.61 21.57m-31.4-10.44c2.29 2.75 5.47 4.12 9.53 4.12s7.23-1.37 9.5-4.12c2.27-2.75 3.41-6.42 3.41-11.02s-1.14-8.27-3.41-11.02c-2.27-2.75-5.44-4.12-9.5-4.12s-7.24 1.37-9.53 4.12c-2.29 2.75-3.43 6.42-3.43 11.02s1.14 8.27 3.43 11.02M154.52 16.86c-1.91 15.65-4.94 28.28-9.1 37.89-.42.84-.74 1.89-.97 3.15-.3-.99-.69-2.04-1.14-3.15-4.96-11.45-8.57-24.08-10.82-37.89h-19.51c2.73 12.04 5.47 21.59 8.35 29.13 3.09 8.09 7.33 16.31 12.71 24.67-2.67 3.68-6.78 7.82-12.29 12.39l7.48 14.45c12.44-8.81 22.2-19.79 29.28-32.91 6.71-12.43 11.76-27.87 14.25-47.74h-18.24zm82.27-1.95c-6.39 0-13.03 2.05-18.09 6.34-5.06-4.29-11.7-6.34-18.09-6.34-12.88 0-26.74 8.3-26.74 26.52v31.84h17.3V38.73c0-8.02 5.91-9.21 9.44-9.21 3.53 0 9.44 1.2 9.44 9.21v34.54h17.3V38.73c0-8.02 5.91-9.21 9.44-9.21 3.52 0 9.44 1.2 9.44 9.21v34.54h17.3V41.43c.01-18.22-13.85-26.52-26.74-26.52m151.7 58.36h-17.3V38.73c0-8.32-7.39-9.21-10.56-9.21-3.18 0-10.56.9-10.56 9.21v34.54h-17.3V41.43c0-18.22 14.45-26.52 27.87-26.52 13.42 0 27.87 8.3 27.87 26.52v31.84zM41.67 44.79c7.44-6.68 15.68-17.07 21.4-27.93h-20.1C37.82 27.13 29.76 34.6 24.21 37.98V2.49H6.76v70.64h17.46V56.24c.99-.38 2.19-.95 3.61-1.72l16.51 18.75H67L41.67 44.79z" />
      <path d="M106.52 54.75c-5.8 3.74-11.28 5.61-16.43 5.61-8.09 0-12.65-3.8-13.68-11.39h37.03c.38-2.33.57-5.06.57-8.19 0-7.75-2.42-13.94-7.27-18.57-4.85-4.64-11.33-6.95-19.46-6.95s-14.95 2.79-20.46 8.39c-5.51 5.59-8.27 12.85-8.27 21.78 0 8.93 2.75 16 8.24 21.21 5.5 5.21 12.83 7.81 22.01 7.81 9.11 0 17.07-2.58 23.91-7.73l-6.19-11.97zM79.9 31.34c1.95-1.91 4.58-2.82 7.9-2.75 3.32.08 5.76 1.11 7.33 3.09 1.56 1.98 2.35 4.46 2.35 7.44H76.41c.38-3.28 1.55-5.87 3.49-7.78" />
    </StyledSVGLogo>
  );
}
