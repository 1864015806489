import { useEffect } from 'react';

import { TLocale } from '@keymono/shared-types';

import { useAuthStore } from '../../auth-store';

interface IUseAuthenticationOptions {
  accountId: number;
  locale: TLocale;
}

/**
 * -----------------------------------------------------------------------------
 * This provides logic to retrieve auth info from the persisted storage and
 * configures it at app startup.
 *
 * - NOTE: Manages the local state with `authStore` -> `zustand` store.
 */
export const useAuthentication = ({
  accountId,
  locale,
}: IUseAuthenticationOptions) => {
  const { activeSession, activityLog, updateActivityLog } = useAuthStore();

  useEffect(() => {
    if (accountId !== activeSession?.index) {
      updateActivityLog({
        ...(activityLog || {}),
        locale,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
