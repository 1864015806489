import React from 'react';
import { cva, cx } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

const titleVariants = cva('text-black font-medium', {
  variants: {
    tag: {
      h1: 'text-4xl',
      h2: 'text-3xl',
      h3: 'text-2xl',
      h4: 'text-xl',
      h5: 'text-lg',
      h6: 'text-md',
    },
  },
});

type TitleVariants = VariantProps<typeof titleVariants>;

type TitleProps = TitleVariants &
  React.DetailsHTMLAttributes<HTMLHeadingElement>;

export function Title(props: TitleProps) {
  const { className, tag, ...rest } = props;
  const element = tag ?? 'h1';
  const classes = cx(titleVariants({ tag: element }), className);
  return React.createElement(element, {
    ...rest,
    className: classes,
  });
}
