import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * A rectangular Icon/Flag that shows the Chinese language symbol in focus.
 * Indicate a trigger to switch the locale to the Chinese language.
 */
export function FlagChinese({
  size = 32,
  className,
  ...rest
}: TSvgComponentProps) {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlSpace="preserve"
      id="flag-chinese"
      height={size}
      width={size * 1.7658}
      className={`rounded-lg bg-red-700 fill-white p-1 ${className}`}
      viewBox="0 0 442 250"
      {...rest}
    >
      <path d="m.5 151.2 75.7-85H1.7V46.1h106v20.2l-76.3 85h77.5v20.2H.5v-20.3zM113.1 46.1h25.2V96h66.3V46.1h25.2v125.3h-25.2v-55.2h-66.3v55.2h-25.2V46.1zM232 248.9h-22.9L287.6 1.1h21.7L232 248.9z" />
      <path d="M425.5 152.1c-3-1.2-5.9-2.3-7.5-3.3l-.2-.1c-7-3.9-43.6-24.8-54.8-32.4-2.6-2.2-5.7-3.2-8.6-4.2-.5-.2-1-.3-1.5-.5 0-1.6.4-3.2.7-4.9.1-.4.2-.8.2-1.3 19-4.5 36-7.1 52-8 3.5-.3 7.3-.4 10.6 1l.3.1h.4c2.5.3 4.7-.9 6-3.3.8-1.6.9-3.3.3-5-1.1-3.2-4.8-6.3-10.8-9.1l-.4-.2h-.5c-9.3-.6-18.6.3-27.7 1.3l-1.4.1c.1-.9.4-2.2.5-3 .2-1 .4-1.9.5-2.6 2.5-13.9 3.7-27.5-3.4-32.5-4.9-3.5-12.6-2.3-24 3.8-.1-.7-.1-1.4-.2-2.2-.1-1.7-.2-3.5-.8-5.2v-.1c-1.5-4.2-4.4-7.4-8.7-9.5l-.1-.1c-2-.9-4.7-1.6-6.4-1-1.7.6-2.8 1.5-3.3 2.9-.8 2.3.5 4.4 1.6 6.3.5.8 1.3 2.1 1.3 2.6v.6c.2 2.4.3 4.9.3 7.3 0 1.3.1 2.7.1 4.1.1 1.7-.2 3-.8 3.2-3.3 1.2-6.3 2.8-9.2 4.4-2.3 1.2-4.4 2.4-6.7 3.4l-.2.1c-1.4.8-2.8 1.7-4.1 2.6-3.4 2.3-6.4 4.3-9.9 3.4h-.1c-3.4-.7-7.1-1.1-10.6.3l-.3.1c-3.1 1.7-3 4-2.9 4.9.5 3.1 4.3 5.2 6.9 5.7.5.2 1.1.4 1.6.5 1.2.4 2 .6 2.3 1.1l2.1 3.7c2.4 4.3 4.9 8.7 7.5 13.1-.5.2-1.3.5-1.7.6-.3.1-.7.2-1 .3-14.5 5.1-26.2 11-35.8 18.1l-1 .8v4.1l.4.6c1.9 2.8 5 6.7 9 7.4 1.9.4 3.9-.1 5.6-1.3 2.9-1.9 6-3.6 9-5.3 1.3-.7 2.6-1.4 3.8-2.1 9.3-4.4 18.7-7.9 29.6-11.9-7.7 28.7-28.9 51.1-49.5 72.8l-1.1 1.2-.3.3c-.2.2-1 .9-1.5 1.4-2.7 2.5-4.5 4.3-4.5 6.4 0 1 .5 1.8 1.2 2.4l.7.5h3.5l.6-.4c13-9 24.8-19.9 36.2-30.3 4.5-4.1 9.2-8.4 13.9-12.5 7.2-6.5 11.1-15.6 14.6-23.8.9-1.5 1.4-3.4 1.9-5.3.2-.7.4-1.7.7-2.6 10.7 10.2 23.6 17.9 36 25.2 8.6 5.1 17.4 10.3 25.4 16.3l.3.2c.9.5 2.2 1.4 3.7 2.4 6.6 4.4 15.8 10.5 21.9 8.1 1.6-.6 3.8-2.1 4.8-5.8v-.2c1.9-8.6-8.3-12.5-16.5-15.7zm-68.8-89.9c4-2.6 8.6-4.3 12.9-6.4 2.4-.5 1.4 2.8 1.5 4.2-.3 3.3-.6 6.6-1 9.8-.8 5.7-3.4 12.4 2.1 16.5-3.4 1.6-7.2 1.8-10.8 2.4-1.5-.1-6.3 2.1-6.4-.3-.1-4.5-.2-8.9-.2-13.4.4-3.5-1.2-10.8 1.9-12.8zm-14.8-27.7h.1c-.1-.1-.1 0-.1 0-.1 0-.1 0 0 0zm-.8 54.4c.1 2.8-.5 5-3.4 6-1.1.3-10.4 3.3-10.3 2.1l-6-11.4c-1.8-2.1-.1-2.9 1.5-4.2 6.4-4 12.5-9.4 20-11.2.6 6.3-.6 12.6-1.8 18.7z" />
    </svg>
  );
}
