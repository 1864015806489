import tw, { styled } from 'twin.macro';

export const SimpleButtonWrapper = styled.button`
  &.storybook-button {
    /* font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    /* font-weight: 700; */
    /* border: 0;
    border-radius: 3em;
    cursor: pointer;
    display: inline-block;
    line-height: 1; */
  }
  &.storybook-button--primary {
    /* color: white;
    background-color: #1ea7fd; */
  }
  &.storybook-button--secondary {
    color: #333;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  }
  &.storybook-button--small {
    font-size: 12px;
    padding: 10px 16px;
  }
  &.storybook-button--medium {
    font-size: 14px;
    padding: 11px 20px;
  }
  &.storybook-button--large {
    font-size: 16px;
    padding: 12px 24px;
  }
`;

export const CardSecButtonWrapper = styled.button`
  ${tw`
    flex
    w-auto
    rounded-md
    px-4
    py-1.5
    text-xs
  `};

  &.primary {
    ${tw`
      border-teal-700
      bg-teal-50
      text-teal-900
      hover:(bg-teal-100 text-teal-800)
    `};
  }

  &.secondary {
    ${tw`
      border-gray-700
      bg-white
      text-gray-900
      hover:(bg-red-50 text-red-800)
    `};
  }

  ${tw`
    // Shifted the disabled styles to the end
    // to override the primary and secondary styles if required.
    disabled:(bg-red-700/10 text-red-900/40)
    disabled:hover:text-red-900/40
  `};
`;
