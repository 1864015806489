import { ReactNode } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { reactQueryClient } from '../reactQueryClient';
import { ReactQueryDevtools } from './ReactQueryDevTools';

interface IReactQueryProviderProps {
  /**
   * The children elements to consume the provider content.
   */
  children: ReactNode;
}

/**
 * -----------------------------------------------------------------------------
 * This wraps around the parts of the application that should consume get access
 * to the query client and cache.
 *
 * @param IReactQueryProviderProps
 * @returns
 */
export function ReactQueryProvider({ children }: IReactQueryProviderProps) {
  return (
    <QueryClientProvider client={reactQueryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
