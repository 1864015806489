import { IInputElement } from '../../types';

import { InputTextElement } from '../../form-inputs';

type TTextInputFieldProps = Pick<
  IInputElement,
  | 'className'
  | 'classNameInput'
  | 'classNameInputContainer'
  | 'classNameLabel'
  | 'constraints'
  | 'defaultValue'
  | 'error'
  | 'isLabelEnabled'
  | 'label'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'type'
  | 'value'
  | 'valuePrefix'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture generic input field without a custom dedicated
 * form-field.
 */
export function TextInputField({
  className,
  classNameInput,
  classNameInputContainer,
  classNameLabel,
  defaultValue,
  constraints,
  error,
  label,
  isLabelEnabled,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  type = 'text',
  value,
  valuePrefix,
}: TTextInputFieldProps) {
  return (
    <InputTextElement
      className={className}
      classNameInput={classNameInput}
      classNameInputContainer={classNameInputContainer}
      classNameLabel={classNameLabel}
      constraints={
        constraints || {
          feedbackLabel: label || 'The input',
          maxLength: 80,
          minLength: 2,
          required: true,
          format: 'title',
        }
      }
      defaultValue={defaultValue}
      error={error}
      hasClearButton
      isLabelEnabled={isLabelEnabled}
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder}
      showIsRequired={false}
      type={type}
      value={value}
      valuePrefix={valuePrefix}
    />
  );
}
