import { cx } from 'class-variance-authority';
import React from 'react';

type CrumbProps = {
  href: string;
  children: React.ReactNode;
  element?: React.JSXElementConstructor<any> | 'a';
};
function Crumb({ href, children, element = 'a' }: CrumbProps) {
  return React.createElement(
    element,
    {
      href,
      className: 'text-blue-600 underline',
    },
    children
  );
}

function DummyCrumb({ children }: Omit<CrumbProps, 'href'>) {
  return <span className="text-gray-600">{children}</span>;
}

function Divider() {
  return (
    <svg
      viewBox="0 0 6 20"
      aria-hidden="true"
      className="h-5 w-auto text-gray-300"
    >
      <path
        d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z"
        fill="currentColor"
      />
    </svg>
  );
}

type BreadcrumbsProps = {
  className?: string;
  children: React.ReactElement<CrumbProps>[];
  currentPath: string;
};

/**
 * -----------------------------------------------------------------------------
 * This renders a minimal breadcrumb navigation for the integrations view,
 * allowing the user to either go back to the details page, or main org overview.
 */
export function Breadcrumbs({
  children,
  currentPath,
  className,
}: BreadcrumbsProps) {
  return (
    <ol className={cx('flex flex-row gap-2 text-sm', className)}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;
        return (
          <>
            {currentPath === child.props.href && (
              <DummyCrumb {...child.props} />
            )}
            {currentPath !== child.props.href && <Crumb {...child.props} />}
            {index !== children.length - 1 && <Divider />}
          </>
        );
      })}
    </ol>
  );
}

Breadcrumbs.Crumb = Crumb;
