import { CSSProperties } from 'react';
import ReactContentLoader from 'react-content-loader';

interface IContentLoaderProps {
  /**
   * The background shade of the gradient animation.
   */
  backgroundColor?: string;
  /**
   * Classes passed to the underlying loader component.
   */
  className?: string;
  /**
   * The foreground shade of the gradient animation.
   */
  foregroundColor?: string;
  /**
   * The Id of the component to assign in server side rendering optimization.
   */
  id?: string;
  /**
   * The `rx` attribute defines the `x-axis` radius of the ellipse used to round
   * the corners of the rectangle.
   *
   * **NOTE**: This is also passed to the `ry` attribute if not provided.
   *
   * @default `20`
   */
  rx?: string;
  /**
   * For the `y-axis` radius of the ellipse used to round the corners.
   *
   * @default `rx=20`
   */
  ry?: string;
  /**
   * The speed of the loading gradient animation in seconds.
   *
   * @default `2`
   */
  speed?: number;
  /**
   * Custom styles passed to the underlying loader component.
   */
  style?: CSSProperties;
  /**
   * The `title` attribute defines the title of the content loader.
   * This is used for accessibility purposes when the loader hovered.
   */
  title?: string;
  /**
   * The `viewBoxWidth` attribute defines the coordinate system for the content
   * width of the viewport.
   */
  vw?: string;
  /**
   * The `viewBoxHeight` attribute defines the coordinate system for the content
   * height of the viewport.
   */
  vh?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a fully customizable rectangular content loader component.
 */
export function RectContentLoader({
  backgroundColor,
  className = '',
  foregroundColor,
  id,
  rx = '20',
  ry,
  speed = 2,
  style,
  title,
  vw = '100',
  vh = '100',
}: IContentLoaderProps) {
  return (
    <ReactContentLoader
      backgroundColor={backgroundColor}
      className={className}
      foregroundColor={foregroundColor}
      uniqueKey={id || 'rect-content-loader-id'}
      style={style}
      speed={speed}
      title={title}
      viewBox={`0 0 ${vw} ${vh}`}
    >
      <rect x="0" y="0" rx={rx} ry={ry || rx} width="100%" height="100%" />
    </ReactContentLoader>
  );
}
