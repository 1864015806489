import React from 'react';
import { motion } from 'framer-motion';
import { cx } from 'class-variance-authority';

type DrawerProps = {
  open: boolean;
  className?: string;
  children: React.ReactNode;
};
export function Drawer({ open, className, children }: DrawerProps) {
  return (
    <motion.aside
      initial={false}
      animate={open ? 'open' : 'closed'}
      className={cx('fixed inset-0 top-14', 'bg-white', 'z-50', className)}
      variants={{
        open: { x: 0 },
        closed: { x: '110%' },
      }}
      transition={{ type: 'tween' }}
    >
      {children}
    </motion.aside>
  );
}
