import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export type DropdownOption<TValue> = {
  label: string;
  value: TValue;
};

type ControlledDropdownMenuProps<TValue> = {
  label: string | React.ReactNode;
  value: DropdownOption<TValue>;
  onChange: (option: DropdownOption<TValue>) => void;
  options: DropdownOption<TValue>[];
};

export function ControlledDropdownMenu<TValue>({
  options,
  value,
  onChange,
  label,
}: ControlledDropdownMenuProps<TValue>) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <span>{label}</span>
          <Listbox.Button className="min-w-20 flex items-center justify-between gap-4 rounded border bg-white px-4 py-2 text-start shadow">
            {value.label}
            <ChevronDownIcon width={24} />
          </Listbox.Button>
          <Transition
            show={open}
            as={React.Fragment}
            leave="transition ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 flex h-48 flex-col overflow-y-scroll rounded bg-white py-2 shadow">
              {options.map((option) => (
                <Listbox.Option
                  className="cursor-pointer px-4 py-2 text-sm hover:bg-slate-100"
                  key={option.label}
                  value={option}
                >
                  {option.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
