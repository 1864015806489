import Link from 'next/link';

import { INavLinkItemProps } from '../types';

/**
 * -----------------------------------------------------------------------------
 * Shows a clickable `call-to-action` navigation link within the navigation bar.
 * To be use on public facing pages.
 *
 * @param INavLinkItemProps
 * @returns JSX.Element
 */
export function NavLinkCTAItem({
  altTitle,
  className,
  isEnabled = true,
  label,
  labelExtra,
  to,
}: INavLinkItemProps) {
  return isEnabled ? (
    <Link
      aria-label={altTitle}
      href={to}
      title={altTitle}
      className={`
        duration-800 group inline-flex items-center justify-center rounded-full
        bg-red-600 px-4 py-2 text-sm font-semibold text-white
        transition-all hover:bg-red-500 hover:text-red-100
        focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-1
        focus-visible:outline-red-300 active:bg-red-800
        active:text-red-100 ${className}
      `}
    >
      {label}
      {labelExtra && (
        <span className="hidden px-1 lg:inline">{labelExtra}</span>
      )}
    </Link>
  ) : null;
}
