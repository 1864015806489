/**
 * This provides a local translation layer for all items int he design-system.
 *
 * - NOTE: These are the base and are exposed for overwriting within the calling
 * parent components.
 * - NOTE 2: The translations are kept here to provide for `storybook` internationalization.
 */
export const translations = {
  formFields: {
    rememberMe: {
      en: 'Remember Me',
      cn: 'Remember Me',
      ar: 'تذكر حسابي',
    },
    rememberMeTitle: {
      en: "If selected, you won't have to login in again next time even when you close your browser.",
      cn: "If selected, you won't have to login in again next time even when you close your browser.",
      ar: 'إذا تم تحديده ، فلن تضطر إلى تسجيل الدخول مرة أخرى في المرة القادمة حتى عندما تغلق المتصفح.',
    },
  },
  formSubmission: {
    pleaseWait: {
      en: 'Please Wait',
      cn: 'Please Wait',
      ar: 'ارجوك انتظر',
    },
    verifyingDetails: {
      en: 'Verifying your details. ',
      cn: 'Verifying your details. ',
      ar: ' التحقق من التفاصيل الخاصة بك.',
    },
  },
  documentUpload: {
    uploadCTA: {
      en: 'Click to Upload',
      cn: 'Click to Upload',
      ar: 'انقر للتحميل',
    },
    addAnother: {
      en: 'Add another',
      cn: 'Add another',
      ar: 'أضف آخر',
    },
  },
};
