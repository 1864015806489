import { TTextLocalized, TLocale } from '@keymono/i18n';
import { getTextFromI18n } from '@keymono/utilities';

interface SiteMetaDataProps {
  appName: TTextLocalized;
  description: TTextLocalized;
  locale: TLocale;
  title: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This renders `SEO` friendly metadata and tags for `PWA` enhancements within
 * the head of the document.
 *
 * TODO:
 * - Expose props that will allow for other pages/components to customize this content.
 * - Update the Meta description and images with better marketable content.
 *
 * Reference Reads::
 * - [The Open Graph protocol](https://ogp.me/)
 * - [Open Graph Meta Tags: Everything You Need to Know](https://ahrefs.com/blog/open-graph-meta-tags/)
 * - [12 Essential Open Graph Meta Tags for Facebook and Twitter](https://neilpatel.com/blog/open-graph-meta-tags/)
 */
export function SiteMetaData({
  appName,
  description,
  locale,
  title,
}: SiteMetaDataProps) {
  const tTitle = getTextFromI18n({
    locale,
    text: title,
  });
  const tDescription = getTextFromI18n({
    locale,
    text: description,
  });
  const tAppName = getTextFromI18n({
    locale,
    text: appName,
  });

  const ogImageURL =
    'https://keymono.herokuapp.com/images/keymono-og-image-1200x630.png';

  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>{tTitle}</title>
      <meta name="author" content={tAppName} />
      <meta name="description" content={tDescription} />
      <link
        rel="icon"
        href="/images/favicon.ico"
        sizes="16x16"
        type="image/ico"
      />
      <link
        rel="shortcut icon"
        href="/images/favicon-32x32.png"
        sizes="32x32"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/images/apple-touch-icon.png"
        sizes="180x180"
        type="image/png"
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <meta name="application-name" content={tAppName} />

      {/*
      // Also:: "og:locale"
      // <html lang="ar" itemscope itemtype="https://schema.org/FAQPage">
       */}
      <meta name="language" content="en-AE" />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />

      <meta name="format-detection" content="telephone=yes" />
      <meta name="apple-mobile-web-app-title" content={tAppName} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="background-color" content="#fdfdfd" />

      {/* Pinned Sites   */}
      <meta name="application-name" content={tAppName} />
      <meta name="msapplication-tooltip" content={tDescription} />
      <meta name="msapplication-starturl" content="/" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#fdfdfd" />
      <meta name="msapplication-tap-highlight" content="no" />

      {/* Tap highlighting   */}
      <meta name="msapplication-tap-highlight" content="no" />

      {/* UC Mobile Browser   */}
      <meta name="full-screen" content="yes" />
      <meta name="browsermode" content="application" />

      {/* Disable night mode for this page   */}
      <meta name="nightmode" content="enable/disable" />

      {/* Layout mode  */}
      <meta name="layoutmode" content="fitscreen/standard" />

      {/* imagemode - show image even in text only mode   */}
      <meta name="imagemode" content="force" />

      {/* Orientation   */}
      <meta name="screen-orientation" content="portrait" />

      {/* ADD MORE SEO META TAGS.   */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://twitter.com/keymono" />
      <meta name="twitter:title" content={tAppName} />
      <meta name="twitter:description" content={tDescription} />
      <meta name="twitter:image" content={ogImageURL} />
      <meta name="twitter:creator" content="@keymono" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={tAppName} />
      <meta property="og:description" content={tDescription} />
      <meta property="og:site_name" content={tAppName} />
      <meta property="og:url" content="https://keymono.com" />
      <meta
        property="og:image"
        // Update this to use a proper URL for a valid og:image
        content={ogImageURL}
      />
    </>
  );
}
