import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * A rectangular Icon/Flag that shows the English language symbol in focus.
 * Indicate a trigger to switch the locale to the English language.
 */
export function FlagEnglish({
  size = 32,
  className,
  ...rest
}: TSvgComponentProps) {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // xmlSpace="preserve"
      id="flag-english"
      height={size}
      width={size * 1.7658}
      className={`rounded-lg bg-red-700 fill-white p-1 ${className}`}
      viewBox="0 0 442 250"
      {...rest}
    >
      <path d="M.22 45.64h83.61v20.32H22.68v31.02h57.94v20.32H22.68v34.23h64.35v20.32H.22V45.64zM115.14 45.64h29.77l61.14 93.77h.36V45.64h22.46v126.21h-28.52l-62.39-96.8h-.36v96.8h-22.46V45.64zM231.73 248.94h-22.88L287.36 1.06h21.71l-77.34 247.88zM436.17 180.24c-.16 3.03-.96 5.5-2.39 7.41-1.43 1.91-3.19 3.07-5.26 3.47s-4.15.04-6.21-1.07c-2.07-1.12-3.82-3.03-5.26-5.74-.96-7.49-2.31-14.86-4.06-22.11-1.76-7.25-3.82-14.46-6.22-21.63l-63.35.48c-.32 0-.68-.04-1.08-.12s-.76-.12-1.07-.12c-3.99 13.55-7.57 27.57-10.76 42.07-1.28 3.35-3.11 5.58-5.5 6.69s-4.66 1.35-6.81.72c-2.15-.64-3.91-2.07-5.26-4.3-1.36-2.23-1.71-5.1-1.08-8.61 5.26-23.11 11.59-45.7 19-67.77s16.69-43.63 27.85-64.66c.96-3.03 3.19-4.94 6.69-5.74 1.75-.63 3.43-.75 5.02-.36 1.59.4 2.95 1.16 4.06 2.27.16 0 .24.04.24.12 0 .08.08.12.24.12 8.28 7.97 15.22 16.97 20.8 27.01s10.67 20.24 15.3 30.6c1.75 3.67 3.38 7.33 4.9 11 1.51 3.67 2.99 7.33 4.42 11l12.67-.24c3.03.96 5.26 2.39 6.69 4.3s2.11 3.87 2.03 5.86c-.08 1.99-.92 3.91-2.51 5.74s-3.91 3.07-6.93 3.7h-5.02c2.07 6.54 3.86 13.11 5.38 19.72 1.53 6.6 2.68 13.34 3.48 20.19zM370.19 65.25a389.57 389.57 0 0 0-12.31 27.73c-3.75 9.41-7.21 18.88-10.4 28.45l52.11-.48c-2.07-4.78-4.18-9.48-6.33-14.1-2.15-4.62-4.34-9.24-6.57-13.87-2.55-4.62-5.1-9.32-7.65-14.1-2.55-4.78-5.5-9.32-8.85-13.63z" />
    </svg>
  );
}
