import { IUser, TSet } from '@keymono/shared-types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This updates the profile information on the active session.
 */
export function updateProfileDetailsCallback(set: TSet) {
  return function updateProfileDetails(updatedProfileDetails: IUser) {
    set(
      ({
        activityLog,
        activeSession,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
        sessionsUnderSignOut,
      }) => {
        if (activeSession) {
          const updateSession = {
            ...activeSession,
            user: updatedProfileDetails,
          };

          const persistState = {
            activityLog,
            activeSession: updateSession,
            allSessionsById,
            allSessionsByIndex,
            allSessionsIndices,
            sessionsUnderSignOut,
          };

          persistAuthStore(persistState);
        }

        return {
          activeSession,
        };
      }
    );
  };
}
