import { Fragment, useRef, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function FloatingUI() {
  const menuTitle = 'Some ITEM';

  const linksArray = [
    // [[title: string, href: string], ...]
    ['Home', '/'],
    ['About', '/about'],
    ['Blog', '/blog'],
  ];

  let timeout: ReturnType<typeof setTimeout>;

  /**
   * The amount of time in milliseconds the browser should wait on hover before
   * firing a click even on underlying button.
   */
  const HOVER_WAIT_TIMEOUT_DURATION = 400;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [openState, setOpenState] = useState(false);

  /**
   * This programmatically triggers a click on a button ref when called.
   */
  const toggleMenu = () => {
    setOpenState((prevOpenState) => !prevOpenState);
    buttonRef?.current?.click();
  };

  /**
   * On hovering over the button/CTA, wait for the a period of
   * `HOVER_WAIT_TIMEOUT_DURATION` and then trigger a click event on the target.
   * - Open the modal if closed or close it if open on hover.
   *
   * @param open
   * @param action
   */
  const onHover = (open: boolean, action: 'onMouseEnter' | 'onMouseLeave') => {
    if (
      (!open && !openState && action === 'onMouseEnter') ||
      (open && openState && action === 'onMouseLeave')
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout);
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(), HOVER_WAIT_TIMEOUT_DURATION);
    }
  };

  const handleClick = (open: boolean) => {
    setOpenState(!open); // toggle open state in React state
    clearTimeout(timeout); // stop the hover timer if it's running
  };

  const LINK_STYLES = classNames(
    'py-5 px-1 w-48',
    'text-base text-gray-900 uppercase font-bold',
    'transition duration-500 ease-in-out',
    'bg-gray-100 hover:text-blue-700 hover:bg-blue-100'
  );

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore
    if (buttonRef.current && !buttonRef.current.contains(event.currentTarget)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // document.removeEventListener('touchstart', handleClickOutside);
    };
  });

  return (
    <div
      className={classNames(
        'absolute inset-0 h-full w-full pt-8',
        'bg-gradient-to-r md:bg-gradient-to-l',
        'from-yellow-400 via-red-500 to-pink-500'
      )}
    >
      <Popover className="relative mx-auto w-48">
        {({ open }) => (
          <div
            className="flex flex-col"
            onMouseEnter={() => onHover(open, 'onMouseEnter')}
            onMouseLeave={() => onHover(open, 'onMouseLeave')}
            // onTouchStart={() => onHover(open, 'onMouseEnter')}
            // onTouchEnd={() => onHover(open, 'onMouseLeave')}
          >
            <Popover.Button ref={buttonRef}>
              <button
                type="button"
                className={classNames(
                  open ? 'text-blue-800' : 'text-gray-800',
                  'rounded-md bg-white',
                  'border-2 border-solid border-black',
                  'flex justify-center',
                  LINK_STYLES
                )}
                onClick={() => handleClick(open)}
              >
                <span className="uppercase">
                  {menuTitle}
                  {openState ? 'open' : 'closed'}
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'translate-y-6 text-gray-600' : 'text-gray-400',
                      'inline-block h-9 w-9',
                      'transform transition-all duration-500'
                    )}
                    aria-hidden="true"
                  />
                </span>
              </button>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel static className="z-10 mx-auto w-48">
                <div
                  className={classNames(
                    'relative grid space-y-[2px]',
                    'border-2 border-solid border-gray-300 bg-white',
                    'divide-y-2 rounded-md text-center'
                  )}
                >
                  {linksArray.map(([title, href]) => (
                    <Fragment key={`PopoverPanel<>${title}${href}`}>
                      <a href={href} className={LINK_STYLES}>
                        {title}
                      </a>
                    </Fragment>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  );
}
