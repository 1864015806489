import { styled } from 'twin.macro';

export const SVGContainer = styled.svg`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: rotate(1.25);
      opacity: 1;
    }
    100% {
      transform: rotate(1);
      opacity: 0.25;
    }
  }

  @keyframes shimmer {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .spin-path {
    animation: spin 20s ease-in-out infinite;
  }

  .pulsate-path {
    animation: pulse 2s linear infinite;
  }

  .shimmer-path {
    animation: shimmer 5s ease-in-out infinite;
  }
`;
