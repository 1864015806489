import { SVGProps } from 'react';

export function SVGGradientBackground(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlSpace="preserve"
      viewBox="0 0 1920 1920"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0h1920v1080H0z" />
      </defs>
      <clipPath id="b">
        <use
          xlinkHref="#a"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <g
        style={{
          opacity: 0.2,
          clipPath: 'url(#b)',
        }}
      >
        <radialGradient
          id="c"
          cx={397.483}
          cy={1758.769}
          r={1}
          gradientTransform="matrix(0 1401.6 1720.8 0 -3025024 -556718.563)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#ffffff',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#c4c4c4',
              stopOpacity: 0,
            }}
          />
        </radialGradient>
        <path
          d="M1466.4 1795.2c950.4 0 1720.8-627.5 1720.8-1401.6S2416.8-1008 1466.4-1008-254.4-380.5-254.4 393.6 516 1795.2 1466.4 1795.2z"
          style={{
            fill: 'url(#c)',
          }}
        />
        <radialGradient
          id="d"
          cx={397.615}
          cy={1758.706}
          r={1}
          gradientTransform="matrix(0 1101.6 1351.8 0 -2377025 -437298.188)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#c996ff',
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ffffff',
              stopOpacity: 0,
            }}
          />
        </radialGradient>
        <path
          d="M394.2 1815.6c746.6 0 1351.8-493.2 1351.8-1101.6S1140.8-387.6 394.2-387.6-957.6 105.6-957.6 714s605.2 1101.6 1351.8 1101.6z"
          style={{
            fill: 'url(#d)',
          }}
        />
        <radialGradient
          id="e"
          cx={397.726}
          cy={1758.653}
          r={1}
          gradientTransform="matrix(0 932.4 1144.2 0 -2010702.125 -369887)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#b883ba',
              stopOpacity: 0.75,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#ffffff',
              stopOpacity: 0,
            }}
          />
        </radialGradient>
        <path
          d="M1548.6 1885.2c631.9 0 1144.2-417.4 1144.2-932.4S2180.5 20.4 1548.6 20.4 404.4 437.9 404.4 952.8s512.3 932.4 1144.2 932.4z"
          style={{
            fill: 'url(#e)',
          }}
        />
        <radialGradient
          id="f"
          cx={397.665}
          cy={1758.682}
          r={1}
          gradientTransform="scale(1249.8 1017.6) rotate(90 -680.5 -1077.97)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0}
            style={{
              stopColor: '#9300e9',
              stopOpacity: 0.75,
            }}
          />
          <stop
            offset={1}
            style={{
              stopColor: '#c4c4c4',
              stopOpacity: 0,
            }}
          />
        </radialGradient>
        <path
          d="M265.8 1215.6C956 1215.6 1515.6 760 1515.6 198S956-819.6 265.8-819.6-984-364-984 198s559.6 1017.6 1249.8 1017.6z"
          style={{
            fill: 'url(#f)',
          }}
        />
      </g>
    </svg>
  );
}
