import { TLocale, TTextLocalized } from '../../types';
import { useI18n } from '../../hooks/useI18n';

type IGetTextFromI18nOptions = {
  /**
   * A simple string or object with locale domains to be rendered.
   */
  text: TTextLocalized;
  /**
   * The locale of the active language in the UI (or Path param)
   */
  locale: TLocale;
};

/**
 * Gets the translated language from an internationalized text into a readable string.
 * Useful for rendering in places where `Text` cannot be used.
 *
 * @param IGetTextFromI18nOptions
 */
export function getTextFromI18n({ locale, text }: IGetTextFromI18nOptions) {
  let localizedText = '';

  if (typeof text === 'string') {
    localizedText = text;
  } else {
    /**
     * This extracts the key of the text from the oject based on the currently
     * supported active locale's language type..
     */
    localizedText = text[locale];
  }

  return localizedText;
}

type ITextLocalizedProps = {
  /**
   * A simple string or object with locale domains to be rendered.
   */
  text: TTextLocalized;
  /**
   * The style to be passed to the wrapper span.
   */
  className?: string;
};

/**
 * -----------------------------------------------------------------------------
 * This exposes a language aware text to render based on whether the input prop
 * is a simple string, or an object containing the language keys with the
 * respective text.
 *
 * @param ITextLocalizedProps
 * @returns JSX.Element
 */
export function Text({ text, className = '' }: ITextLocalizedProps) {
  const { locale } = useI18n();

  let localizedText = '';

  if (typeof text === 'string') {
    localizedText = text;
  } else {
    /**
     * This extracts the key of the text from the oject based on the currently
     * supported active locale's language type..
     */
    localizedText = text[locale];
  }

  return <span className={className}>{localizedText}</span>;
}
