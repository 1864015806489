import SecureLS from 'secure-ls';

import { ErrorEventsEnum, errorLogToRemoteUtil } from '../error-logger';

const encryptionSecret =
  process.env.APP_ENCRYPTION_SECRET || 'APP_ENCRYPTION_ERROR_LOGGER';

let securedLocalStorage: SecureLS | undefined;

const initializeLocalStorage = () => {
  /**
   * Utility to provide encryption to items saved to local storage.
   */
  securedLocalStorage = new SecureLS({
    encryptionSecret,
    encodingType: 'aes',
  });
};

/**
 * This is a temporary work around the failing build for SecureLS when
 * localStorage is indexed on the node instead of the browser.
 */
if (global?.localStorage) initializeLocalStorage();

/**
 * Adds AES Encryption to a value before saving it to local browser storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 * @returns boolean Indicate whether success of failure on saving record.
 */
export function saveLocalData(key: string, value: any): boolean {
  try {
    securedLocalStorage?.set(key, value);
    return true;
  } catch (error) {
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN__LOCAL_OR_ASYNC_STORAGE,
      errorTitle: 'Error in Saving to Secure Local Storage ',
      message: 'saveLocalData:failed',
    });

    return false;
  }
}

/**
 * This loads the AES Encrypted value from the browser local storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 * @returns T The data saved.
 */
export function loadLocalData<T>(key: string): T | null {
  try {
    return securedLocalStorage?.get(key) || null;
  } catch (error) {
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN__LOCAL_OR_ASYNC_STORAGE,
      errorTitle: 'Error in reading from Secure Local Storage ',
      message: 'loadLocalData:failed',
    });

    return null;
  }
}
