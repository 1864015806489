import { UrlObject } from 'url';
import { Fragment, ForwardedRef, forwardRef } from 'react';
import Link from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { TLocale, TLanguagesConfigItem, TIconId } from '../../../types';

import { FlagIcon } from '../../Flags/flagIcons';

interface IMenuItemLinkProps {
  /**
   * The path as shown in the browser including the search params and respecting
   * the `trailingSlash` configuration. `basePath` and `locale` are not included.
   */
  asPath: string;
  /**
   * The final path to be passed to the underlying `<a>` link tag.
   */
  href: UrlObject | string;
  /**
   * Used to determine the styling of this based on additional parent logic.
   */
  isCurrent: boolean;
  /**
   * The title/name of the item to be displayed on the UI.
   */
  label: string;
  /**
   * The locale to be passed to the Link
   */
  locale: TLocale;
  /**
   * The id of the icon/flag to render besides the label
   */
  icon: TIconId;
}

type TMenuItemLinkRef = ForwardedRef<HTMLAnchorElement>;

/**
 * -----------------------------------------------------------------------------
 * The individual menu item displaying an icon/flag alongside the label.
 */
const MenuItemLink = forwardRef(
  (
    {
      href,
      label,
      icon,
      locale,
      isCurrent,
      asPath,
      ...rest
    }: IMenuItemLinkProps,
    ref: TMenuItemLinkRef
  ) => {
    const arabicTextStyle = locale === 'ar' ? 'font-primary-ar' : '';

    return (
      <Link
        as={asPath}
        href={href}
        locale={locale}
        ref={ref}
        className={`drop-down-item ui-active:last-of-type:rounded-b-2xl ${arabicTextStyle}`}
        {...rest}
      >
        <FlagIcon id={icon} className="rounded-lg" size={22} />
        {label}
      </Link>
    );
  }
);

MenuItemLink.displayName = 'MenuItemLink';

interface ILocaleMenuProps {
  /**
   * The path as shown in the browser including the search params and respecting
   * the `trailingSlash` configuration. `basePath` and `locale` are not included.
   */
  asPath: string;
  /**
   * The final path to be passed to the underlying `<a>` link tag
   */
  href: UrlObject | string;
  /**
   * A list of items for the dropdown.
   */
  items: TLanguagesConfigItem[];
  /**
   * The current selected value of the selected list.
   */
  value: TLanguagesConfigItem;
}

/**
 * -----------------------------------------------------------------------------
 * This provides a menu dropdown that helps in changing the active locale for
 * the app.
 * - It displays the country flag alongside the locale name.
 *
 * @param ILocaleMenuProps
 * @returns JSX.Element
 */
export function LocaleMenu({ asPath, href, items, value }: ILocaleMenuProps) {
  const activeLanguageLocale = value.locale;

  return (
    <Menu as="div">
      <div>
        <Menu.Button
          className={`
            ui-open:rounded-b-none ui-open:rounded-t-2xl rtl:text-md font-primary-ar
            ltr:font-primary-en focus:hover:bg-red shadow-xs inline-flex w-full
            min-w-[110px] items-center justify-between rounded-3xl border
            border-gray-200 bg-white p-2 text-xs font-normal text-gray-700
            transition-all hover:bg-red-50 hover:text-red-800 focus:outline-none
            focus:ring-2 focus:ring-red-100 focus:ring-offset-2
            focus:ring-offset-gray-100
          `}
        >
          <FlagIcon
            className="shrink-0 rounded-lg ltr:mr-2 rtl:ml-2"
            id={value.icon}
            size={22}
          />
          <span className="!rtl:font-light text-center rtl:-mt-2 rtl:text-base">
            {value.label}
          </span>
          <ChevronDownIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute right-0 z-10 mt-0 w-full min-w-[110px] origin-top-right divide-y
            divide-gray-100 rounded-b-2xl bg-white shadow-md shadow-gray-100
            ring-1 ring-black ring-opacity-5 focus:outline-none
          "
        >
          <div className="py-1">
            {items.map((item) => {
              const isCurrentLocale = item.locale === activeLanguageLocale;

              return (
                <Menu.Item key={item.id}>
                  <MenuItemLink
                    isCurrent={isCurrentLocale}
                    key={item.id}
                    href={href}
                    asPath={asPath}
                    label={item.label}
                    locale={item.id as TLocale}
                    icon={item.icon}
                  />
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
