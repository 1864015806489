import {
  TLanguagesConfigItem,
  TLanguagesConfigByLocale,
  TRegionInstanceById,
  TRegionInstance,
} from './types';
import {
  LANGUAGE_ARABIC,
  LANGUAGE_ENGLISH,
  LANGUAGE_CHINESE,
} from './localeConstants';

/**
 * A list of locales, grouped by english and arabic versions to be displayed in
 * the language selector menu.
 *
 * TODO: 1. Get these from the API
 * TODO: 2. Add additional flags specific to the country.
 * TODO: 3. Make the text language aware (Arabic ETC/great if done at config level).
 * */
export const languagesConfig: TLanguagesConfigItem[] = [
  {
    id: 'en',
    locale: 'en',
    icon: 'english',
    label: LANGUAGE_ENGLISH,
    isRTL: false,
  },
  {
    id: 'ar',
    locale: 'ar',
    icon: 'arabic',
    label: LANGUAGE_ARABIC,
    isRTL: true,
  },
  {
    id: 'cn',
    locale: 'cn',
    icon: 'chinese',
    label: LANGUAGE_CHINESE,
    isRTL: false,
  },
];

/**
 * This creates a normalized list of the languages that can easily be indexed by
 * `locale` without having to refilter the array.
 */
export const languagesConfigByLocale = languagesConfig.reduce(
  (previousItem, currentItem) => {
    const langByIds = { ...previousItem, [currentItem.locale]: currentItem };

    return langByIds;
  },
  {} as TLanguagesConfigByLocale
);

/**
 * A list of locales, grouped by english and arabic versions to be displayed in
 * the language selector menu.
 *
 * TODO: 1. Get these from the API
 * TODO: 2. Add additional flags specific to the country.
 * TODO: 3. Make the text language aware (Arabic ETC/great if done at config level).
 * */
export const regionsConfig: TRegionInstance[] = [
  {
    id: 'uae',
    icon: 'uae',
    label: {
      ar: 'الإمارات العربية المتحدة',
      cn: 'United Arab Emirates',
      en: 'United Arab Emirates',
    },
  },
  {
    id: 'ksa',
    icon: 'ksa',
    label: {
      ar: 'المملكة العربية السعودية',
      cn: 'Kingdom of Saudi Arabia',
      en: 'Kingdom of Saudi Arabia',
    },
  },
  {
    id: 'cn',
    icon: 'chinese',
    label: {
      ar: 'عالمي',
      cn: 'Global',
      en: 'Global',
    },
  },
];

/**
 * This creates a normalized list of the regions that can easily be indexed by
 * `id` without having to refilter the array.
 */
export const regionsConfigById = regionsConfig.reduce(
  (previousItem, currentItem) => {
    const regionByIds = { ...previousItem, [currentItem.id]: currentItem };

    return regionByIds;
  },
  {} as TRegionInstanceById
);
