import { FlagArabic } from './FlagArabic';
import { FlagChinese } from './FlagChinese';
import { FlagEnglish } from './FlagEnglish';
// import { FlagGlobal } from './FlagGlobal';
import { FlagIraq } from './FlagIraq';
import { FlagSaudi } from './FlagSaudi';
import { FlagUAE } from './FlagUAE';

import { TSvgComponentProps } from './types';

/**
 * This consists of all the supported country flag SVG icons to be used in the
 * app.
 */
export const flagIcons = {
  arabic: FlagArabic,
  english: FlagEnglish,
  chinese: FlagChinese,
  iraq: FlagIraq,
  ksa: FlagSaudi,
  uae: FlagUAE,
};

export type TFlagIconsIds = keyof typeof flagIcons;

interface IFlagIconProps extends TSvgComponentProps {
  /**
   * The `id` of the flag to retrieve from the flags id-component map.
   * - NOTE: keep it in sync with `services/TRegionId`.
   */
  id: TFlagIconsIds;
}

/**
 * -----------------------------------------------------------------------------
 * This component expects a the flag `id` and returns the appropriate flag
 * component. It exposes the rest of the flag props for overriding.
 *
 * @param IFlagIconProps
 *
 * @returns JSX.Element
 */
export function FlagIcon({ id, ...rest }: IFlagIconProps) {
  const Flag = flagIcons[id];

  return <Flag {...rest} />;
}
