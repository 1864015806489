import {
  NextIntlProvider as BaseNextIntlProvider,
  AbstractIntlMessages,
} from 'next-intl';
import { ReactNode } from 'react';

interface INextIntlProviderProps {
  /**
   * Child components fed with the translation locales.
   */
  children: ReactNode;
  /**
   * The translated messages loaded from json.
   */
  messages: AbstractIntlMessages;
}

/**
 * -----------------------------------------------------------------------------
 * Wrapper around the Next internalization lib to provide for better localization.
 */
export function NextIntlProvider({
  children,
  messages,
}: INextIntlProviderProps) {
  return (
    <BaseNextIntlProvider messages={messages}>{children}</BaseNextIntlProvider>
  );
}
