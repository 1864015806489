import Lottie from 'lottie-react';

import animationFile from './raw-json/man-checking-stats.json';

export function ManCheckingStatsAnimation() {
  return (
    <div className="w-full">
      <Lottie animationData={animationFile} loop className="flex h-72 w-full" />
    </div>
  );
}
