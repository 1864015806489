'use client';

import React from 'react';

export function useToggle(initial: boolean) {
  const [value, setValue] = React.useState(initial);

  function toggle(override?: boolean) {
    if (override !== undefined) setValue(override);
    else setValue(!value);
  }

  return [value, toggle] as const;
}
