import Lottie from 'lottie-react';

import messageArrivedAnimationFile from './raw-json/message-arrived.json';

export function MessageArrived() {
  return (
    <div className="w-full">
      <Lottie
        animationData={messageArrivedAnimationFile}
        loop
        className="flex h-40 w-full"
      />
    </div>
  );
}
