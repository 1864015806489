import { CSSProperties } from 'react';
import ReactContentLoader from 'react-content-loader';

interface IContentLoaderProps {
  className?: string;
  style?: CSSProperties;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a simple avatar content loader component.
 */
export function SimpleAvatarHeaderLoader({
  className = '',
  style,
}: IContentLoaderProps) {
  return (
    <ReactContentLoader
      className={className}
      viewBox="0 0 380 70"
      style={style}
    >
      <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ReactContentLoader>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders a content loader for to be used as a fallback when a component
 * is fetching async data.
 */
export function ContentLoader({ className = '', style }: IContentLoaderProps) {
  return (
    <ReactContentLoader
      className={className}
      viewBox="0 0 380 70"
      style={style}
    >
      <rect x="0" y="0" rx="80" ry="5" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ReactContentLoader>
  );
}
