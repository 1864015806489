import Lottie from 'lottie-react';

import emailSentAnimationFile from './raw-json/email-sent.json';

export function EmailSentAnimation() {
  return (
    <div className="w-full border-l-2 border-red-200">
      <Lottie
        animationData={emailSentAnimationFile}
        loop
        className="flex h-40 w-full"
      />
    </div>
  );
}
