import { translations } from '../../../../translations';

import { IBaseInputCheckItem } from '../../types';
import { InputCheckItem } from '../../form-inputs';

type TRememberMeCheckFieldProps = Pick<
  IBaseInputCheckItem,
  'checked' | 'disabled' | 'onChange' | 'title'
>;

/**
 * -----------------------------------------------------------------------------
 * This toggles the remember me field for the user. If set, tha app will try to
 * remember the current session.
 */
export function RememberMeCheckField({
  checked,
  disabled,
  onChange,
  title,
}: TRememberMeCheckFieldProps) {
  return (
    <InputCheckItem
      checked={checked}
      disabled={disabled}
      label={translations.formFields.rememberMe}
      name="rememberMe"
      onChange={onChange}
      required={false}
      title={title || translations.formFields.rememberMeTitle}
    />
  );
}
