import { IInputElement } from '../../types';

import { InputTextElement } from '../../form-inputs';

type TAddressInfoInputFieldProps = Pick<
  IInputElement,
  | 'constraints'
  | 'error'
  | 'isLabelEnabled'
  | 'label'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'value'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture the address summary of a person or organization,
 * and tries to add constraints that are used to validate the expected format of
 * a valid address.
 */
export function AddressInfoInputField({
  constraints,
  error,
  label,
  isLabelEnabled,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  value,
}: TAddressInfoInputFieldProps) {
  return (
    <InputTextElement
      constraints={{
        feedbackLabel: 'The address information',
        maxLength: 80,
        minLength: 2,
        required: true,
        format: 'title',
        regExp:
          /^(?![ -.&,_'":?!/])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!/]{2})[a-zA-Z0-9- .#@&,_'":.?!/]+$/,
        ...constraints,
      }}
      error={error}
      hasClearButton
      isLabelEnabled={isLabelEnabled}
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder || 'Address Information'}
      showIsRequired={false}
      type="text"
      value={value}
    />
  );
}
