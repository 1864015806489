import { LocaleSwitcher, useTranslations } from '@keymono/i18n';

import { LogoKeymono } from '../../brand';
import { NavLinkCTAItem, NavLinkItem } from '../../links';

export function PublicHeader() {
  const t = useTranslations('AppHeaders');
  const tCommon = useTranslations('Common');

  return (
    <header className="py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <a
              aria-label={tCommon('logoAriaLabel')}
              title={tCommon('logoAriaLabel')}
              href="/"
            >
              <LogoKeymono />
            </a>
            <div className="hidden md:flex md:gap-x-6">
              <NavLinkItem
                altTitle="Get to know more about the features"
                label="Features"
                to="/app-features"
                isEnabled={false}
              />
              <NavLinkItem
                altTitle="Frequently Asked Questions"
                label="FAQs"
                to="/faqs"
                isEnabled={false}
              />
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden items-center gap-4 md:flex md:flex-row">
              <NavLinkItem
                altTitle={t('login.title')}
                label={t('login.label')}
                to="/auth/login"
                isEnabled
              />
              <NavLinkCTAItem
                altTitle="Register today to become a keymono"
                className="hidden sm:block"
                isEnabled
                label="Sign up"
                labelExtra="now"
                to="/auth/sign-up"
              />
              <LocaleSwitcher />
            </div>
            <div className="-mr-1 flex flex-row gap-4 md:hidden">
              <NavLinkItem
                altTitle={t('login.title')}
                label={t('login.label')}
                to="/auth/login"
                isEnabled
              />

              <NavLinkCTAItem
                altTitle="Register today to become a keymono"
                // className="hidden sm:block"
                isEnabled
                label="Sign up"
                labelExtra="now"
                to="/auth/sign-up"
              />

              <div>
                <button
                  className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
                  aria-label="Toggle Navigation"
                  type="button"
                  aria-expanded="false"
                >
                  <svg
                    aria-hidden="true"
                    className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                  >
                    <path
                      d="M0 1H14M0 7H14M0 13H14"
                      className="origin-center transition"
                    />
                    <path
                      d="M2 2L12 12M12 2L2 12"
                      className="origin-center scale-90 opacity-0 transition"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
