import { IActiveSession, IActiveOrgInfo, TSet } from '@keymono/shared-types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This updates the active organization ID on the active session and matching
 * userIds.
 * This is to br called on switching the org or creating a new one.
 */
export function updateActiveOrganization(set: TSet) {
  return function updateActiveOrgId(orgInfo: IActiveOrgInfo) {
    set(
      ({
        activeSession,
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
        sessionsUnderSignOut,
      }) => {
        if (!activeSession || !allSessionsById) return { activeSession };

        const { orgId, userId } = orgInfo;

        const targetSession = allSessionsById[userId];

        /**
         * Get the session with matching `userId`. Note that we cannot rely on
         * the `activeSession`since it might be changed from a different browser
         * tab/window.
         */
        const updatedSession: IActiveSession = {
          ...targetSession,
          activeOrgId: orgId,
        };

        /**
         * Update the normalized session info to update this to.
         */
        const updatedAllSessionsById = {
          ...allSessionsById,
          [userId]: updatedSession,
        };
        const updatedAllSessionsByIndex = {
          ...allSessionsByIndex,
          [updatedSession.index]: updatedSession,
        };

        /**
         * Log the last date this org was switched. Useful in the future to
         * determine caching strategies.
         */
        const updatedActivityLog = {
          ...activityLog,
          lastOrgSwitchDate: new Date().getTime(),
        };

        /**
         * Also update the active session if the userIds match.
         * Meaning, it's not been changed from another tab/window.
         */
        let updatedActiveSession = activeSession;

        if (updatedSession.userId === activeSession?.userId) {
          updatedActiveSession = {
            ...activeSession,
            ...updatedSession,
          };
        }

        const persistState = {
          activityLog: updatedActivityLog,
          activeSession: updatedActiveSession,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          allSessionsIndices,
          sessionsUnderSignOut,
        };

        persistAuthStore(persistState);

        return {
          activityLog: updatedActivityLog,
          activeSession: updatedActiveSession,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
        };
      }
    );
  };
}
