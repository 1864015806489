import { Fragment, ReactNode, useRef, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';

interface IPopoverWithHoverProps {
  popoverContainerClass?: string;
  popoverContentWrapperClass?: string;
  popoverContent: ReactNode;
  popoverTrigger: ReactNode;
}

export function PopoverWithHover({
  popoverContainerClass,
  popoverContent,
  popoverContentWrapperClass,
  popoverTrigger,
}: IPopoverWithHoverProps) {
  let timeout: ReturnType<typeof setTimeout>;

  /**
   * The amount of time in milliseconds the browser should wait on hover before
   * firing a click even on underlying button.
   */
  const HOVER_WAIT_TIMEOUT_DURATION = 300;

  const [openState, setOpenState] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  /**
   * This programmatically triggers a click on a button ref when called.
   */
  const toggleMenu = () => {
    setOpenState((prevOpenState) => !prevOpenState);
    buttonRef?.current?.click();
  };

  /**
   * On hovering over the button/CTA, wait for the a period of
   * `HOVER_WAIT_TIMEOUT_DURATION` and then trigger a click event on the target.
   * - Open the modal if closed or close it if open on hover.
   */
  const handleOnHover = (
    open: boolean,
    action: 'onMouseEnter' | 'onMouseLeave'
  ) => {
    if (
      (!open && !openState && action === 'onMouseEnter') ||
      (open && openState && action === 'onMouseLeave')
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout);
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(), HOVER_WAIT_TIMEOUT_DURATION);
    }
  };

  const handleOnClick = (open: boolean) => () => {
    setOpenState(!open);

    // stop the hover timer if it's running
    clearTimeout(timeout);
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore
    if (buttonRef.current && !buttonRef.current.contains(event.currentTarget)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Popover className={popoverContainerClass}>
      {({ open }) => (
        <div
          onMouseEnter={() => handleOnHover(open, 'onMouseEnter')}
          onMouseLeave={() => handleOnHover(open, 'onMouseLeave')}
        >
          <Popover.Button
            ref={buttonRef}
            className="
              ui-open:bg-red-50 cursor-pointer rounded-md p-0
              hover:border-red-100 hover:bg-red-50
              focus:outline-none focus:ring-1 focus:ring-red-200
            "
            onClick={handleOnClick(open)}
          >
            {popoverTrigger}
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel static className={popoverContentWrapperClass}>
              {popoverContent}
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}
