import { Text } from '@keymono/i18n';

import { cx } from 'class-variance-authority';
import React from 'react';
import { ButtonLoadingIndicator } from '../../loaders';

function IconButtonLabel({ children }: { children: string }) {
  return <Text text={children} />;
}

function IconButtonIcon({ children }: { children: React.ReactNode }) {
  return children;
}

/**
 * The interface for the form button element.
 * - When it's `type='submit'`, the `onClick` prop should not be provided unlike
 * the case for `type='button'`
 */
type ButtonType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>['type'];

type IIconButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  type?: ButtonType;
  className?: string;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

/**
 * -----------------------------------------------------------------------------
 * Button component with support for passing an optional icon
 * or render icon only without text.
 * The main action to trigger form submission it the button is `type='submit'`
 * or fire some other action if the `type='button'`.
 * TODO: Combine with Button component if feasible.
 */
export function IconButton({
  loading,
  leftIcon,
  rightIcon,
  className,
  children,
  type = 'button',
  ...rest
}: IIconButtonProps) {
  let LeftIcon: React.ReactNode = null;
  let RightIcon: React.ReactNode = null;
  if (loading) {
    LeftIcon = <ButtonLoadingIndicator isEnabled />;
    RightIcon = <ButtonLoadingIndicator isEnabled />;
  } else {
    LeftIcon = leftIcon;
    RightIcon = rightIcon;
  }

  return (
    <button
      {...rest}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className={cx(
        'flex items-center justify-center gap-3',
        'whitespace-nowrap',
        'focus:ring-0',
        'disabled:bg-gray-200/50 disabled:active:bg-gray-200/50',
        className
      )}
    >
      {LeftIcon}
      {children}
      {RightIcon}
    </button>
  );
}

IconButton.Label = IconButtonLabel;
IconButton.Icon = IconButtonIcon;
