import { ReactNode } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface IHamburgerMenuDropdownItemProps {
  children: ReactNode;
  label: string;
}

export function HamburgerMenuDropdownItem({
  children,
  label,
}: IHamburgerMenuDropdownItemProps) {
  return (
    <Disclosure as="div" className="border-t border-gray-200 pb-4 pt-4">
      {({ open }) => (
        <fieldset>
          <legend className="w-full px-2">
            <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
              <span className="text-sm font-medium text-gray-900">{label}</span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={`h-5 w-5 transform ${
                    open ? '-rotate-180' : 'rotate-0'
                  }`}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </legend>
          <Disclosure.Panel className="px-4 pb-2 pt-4">
            <div className="space-y-6">{children}</div>
          </Disclosure.Panel>
        </fieldset>
      )}
    </Disclosure>
  );
}
