import { Text } from '@keymono/i18n';

import { ButtonLoadingIndicator } from '../../loaders';

import { IButtonTypeButton, IButtonTypeSubmit } from '../types';

/**
 * The interface for the form button element.
 * - When it's `type='submit'`, the `onClick` prop should not be provided unlike
 * the case for `type='button'`
 */
type IButtonProps = IButtonTypeSubmit | IButtonTypeButton;

/**
 * -----------------------------------------------------------------------------
 * The main action to trigger form submission it the button is `type='submit'`
 * or fire some other action if the `type='button'`.
 *
 * TODO: Add a loading indicator for loading states.
 */
export function Button({
  className = '',
  isEnabled,
  isLoading,
  onClick,
  type,
  label,
  loadingLabel,
}: IButtonProps) {
  const btnLabel = isLoading ? loadingLabel : label;

  if (type === 'submit') {
    return (
      <button
        type="submit"
        className={`form-btn ${className}`}
        disabled={!isEnabled}
        onClick={onClick}
      >
        {isLoading ? (
          <ButtonLoadingIndicator className="mr-3" isEnabled={isEnabled} />
        ) : null}
        <Text text={btnLabel} />
      </button>
    );
  }

  return (
    <button
      type="button"
      className={`form-btn ${className}`}
      disabled={!isEnabled}
      onClick={onClick}
    >
      {isLoading ? (
        <ButtonLoadingIndicator className="mr-3" isEnabled={isEnabled} />
      ) : null}
      <Text text={btnLabel} />
    </button>
  );
}
