/* eslint-disable function-paren-newline */
/* eslint-disable indent */
import {
  ChangeEvent,
  FocusEvent,
  Fragment,
  RefObject,
  UIEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Combobox, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';

import { useTranslations } from '@keymono/i18n';
import { useDebounce } from '@keymono/utilities';

import { ButtonLoadingIndicator } from '../../../loaders';

import { IChoice } from '../../types';

interface ISelectChoice {
  key: string;
  label: string;
}

interface IChoicesCountriesSelectDropdownProps {
  activeSearchKey: string;
  countries: IChoice[];
  className?: string;
  isLoading: boolean;
  label?: string;
  onChange: (choice: IChoice) => void;
  onScrollToBottom?: () => void;
  onUpdateSearchKey: (newSearchKey: string) => void;
  selectedChoice: IChoice | null;
}

/**
 * -----------------------------------------------------------------------------
 * This provides options for selecting a a country from a list of choices.
 * It replaces the native unstyled dropdown component.
 * It also supports searching for a country from a list of country choices
 * NOTE: By default it is styled to appear inline.
 *
 * TODO: Merge this with the `ChoicesSelectDropdown` component
 * TODO: Add infinite scroll to the list of countries
 */
export function ChoicesCountriesSelectDropdown({
  activeSearchKey: initSearchKey = '',
  countries,
  className = '',
  label,
  isLoading,
  onChange,
  onScrollToBottom,
  onUpdateSearchKey,
  selectedChoice,
}: IChoicesCountriesSelectDropdownProps) {
  const t = useTranslations('Country');

  const comboInputRef: RefObject<HTMLInputElement> = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState(selectedChoice);
  const [filteredCountriesList, setFilteredCountriesList] = useState(countries);
  const [searchKey, setSearchKey] = useState(initSearchKey);

  /**
   * This will be passed down to the underlying debounce hook to silently call
   * the search api every after a delay (1second) between user typing.
   */
  const handleSubmitSearch = (newSearchValue: string) => {
    setSearchKey(newSearchValue);
    onUpdateSearchKey(newSearchValue);
  };

  const {
    debouncedValue: searchKeyDebounced,
    setDebouncedValue: setInputValue,
    handleChangeDebounced: debouncedHandleChange,
  } = useDebounce({
    initialValue: searchKey,
    delay: 1000,
    onChangeCb: handleSubmitSearch,
  });

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    /**
     * Get a nicely formatted input text removing any special characters or emojis.
     *
     * - `[^0-9a-zA-Z\s\u0600-\u06FF]` is a character class that matches any
     * character that is not a digit (0-9), lowercase letter (a-z), uppercase
     * letter (A-Z), whitespace character (space, tab, newline),
     * or an Arabic character (Unicode range `\u0600` to `\u06FF`).
     *
     * - The `/g` flag at the end of the regular expression is a global flag
     * that ensures all matching characters are replaced, not just the first
     * occurrence.
     *
     * - TODO: @Sowed Move this to validation utility function
     */
    const inputText = event.target.value.replace(
      /[^0-9a-zA-Z\s\u0600-\u06FF]/g,
      ''
    );
    setInputValue(inputText);
    debouncedHandleChange(inputText);
  };

  /**
   * Triggered when the app focusses on the input field, forcing the cursor to
   * move to the end of the text input value if present
   */
  const handleOnFocusInput = (event: FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target;

    const characterCount = value.length;

    if (comboInputRef.current) {
      // * Force the cursor to the end of the input field
      comboInputRef.current.setSelectionRange(characterCount, characterCount);
      comboInputRef.current.focus();
    }
  };

  /**
   * Triggered when the user selects a country from the dropdown list.
   */
  const handleOnSelect = (item: ISelectChoice) => {
    if (comboInputRef.current) {
      setSelectedCountry(item);
      onChange(item);
    }
  };

  /**
   * When the user deselects the drop down reset the search key from this component
   * and if no country is selected, reset the search key from the parent component too.
   */
  const handleOnBlurInput = () => {
    setSearchKey('');

    if (!selectedCountry) {
      onUpdateSearchKey('');
    }
  };

  /**
   * On pressing the `X` icon on the right of the input field, clear the text
   * input within the search input, and reset the selected country from this
   * component and the parent component too.
   * - Will force the app to refetch the list of countries from the api.
   */
  const handleOnClear = () => {
    if (comboInputRef.current) {
      setSelectedCountry(null);
      setSearchKey('');
      setInputValue('');
      onUpdateSearchKey('');
      comboInputRef.current.focus();
    }
  };

  /**
   * Maintain a state to determine whether the user has scrolled to the bottom
   * This is useful for calling the onScrollToBottom event, so that event is
   * not called repeatedly.
   */
  const [hasReachedBottom, setHasReachedBottom] = useState(false);

  /**
   * The `onScroll` event is triggered when the user scrolls the dropdown list.
   * This is used to determine whether the user has scrolled to the bottom of the list
   * and call the `onScrollToBottom` event.
   */
  const handleOnScroll = (event: UIEvent) => {
    const target = event.target as HTMLElement;
    const bottom =
      target.scrollHeight - target.clientHeight <= target.scrollTop + 50;

    if (bottom && !hasReachedBottom) {
      setHasReachedBottom(true);
      onScrollToBottom?.();
    } else if (!bottom) {
      setHasReachedBottom(false);
    }
  };

  useEffect(() => {
    const updatedCountriesList =
      searchKeyDebounced === ''
        ? countries
        : countries.filter((choice) =>
            choice.label
              .toLowerCase()
              .includes(searchKeyDebounced.toLowerCase())
          );

    setFilteredCountriesList(() => updatedCountriesList);
  }, [countries, searchKeyDebounced]);

  /**
   * Used to determine whether the `No results found` message should be shown
   * below the user input. Hidden when the user is still typing or when the api
   * is fetching content.
   */
  const shouldShowNotResults =
    !isLoading &&
    filteredCountriesList.length === 0 &&
    searchKeyDebounced !== '';

  return (
    <Combobox value={selectedCountry} onChange={handleOnSelect} nullable>
      {({ open }) => (
        <>
          {label ? (
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-600">
              {label}
            </Combobox.Label>
          ) : null}
          <div className="relative mt-1 w-full">
            <Combobox.Input
              ref={comboInputRef}
              onChange={handleSearchInputChange}
              onFocus={handleOnFocusInput}
              onBlur={handleOnBlurInput}
              displayValue={(choice: IChoice) => choice?.label}
              // TODO: Move this to the parent component
              placeholder={t('countryPlaceholder.placeholder') || '...select'}
              className={`
                form-input-field relative  block h-10 truncate  font-light
                text-gray-700 rtl:pr-2 sm:h-auto sm:text-sm sm:leading-5
                ${className}
              `}
            />

            <Combobox.Button
              className="
                absolute inset-y-0 flex w-full items-center justify-end px-2.5
                ltr:right-0 rtl:left-0
              "
            >
              {selectedCountry || searchKeyDebounced ? (
                <XMarkIcon
                  className="
                    border-2X absolute inset-y-0 flex
                    h-10 w-10 items-center justify-end p-2.5 text-gray-400
                    ltr:right-0 rtl:left-0
                  "
                  aria-hidden="true"
                  onClick={handleOnClear}
                />
              ) : (
                <span>
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              )}
            </Combobox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options
                onScroll={handleOnScroll}
                className="
                  border-2X absolute z-10 mt-1 max-h-60 w-full
                  overflow-auto rounded-b-lg border-red-500 bg-white py-2
                  text-xs shadow-md shadow-gray-200 ring-1 ring-gray-700
                  ring-opacity-5 focus:outline-none
                "
              >
                {isLoading ? (
                  <div
                    className="
                      mt-1 flex h-full w-full items-center justify-start gap-2
                      rounded-md text-sm text-red-800 ltr:pl-2 rtl:pr-2
                    "
                  >
                    <ButtonLoadingIndicator isEnabled className="h-5 w-5" />
                    <p>{t('loadingMessage')}</p>
                  </div>
                ) : null}

                {shouldShowNotResults ? (
                  <div
                    className="
                      ui-active:bg-red-100 ui-active:text-red-900 relative
                      cursor-not-allowed select-none px-3 py-2 text-red-800
                      transition-all duration-300 ease-linear
                    "
                  >
                    {t('notFound')}
                  </div>
                ) : (
                  filteredCountriesList.map((choice) => (
                    <Combobox.Option
                      key={choice.key}
                      className="
                        ui-active:bg-red-100 ui-selected:text-red-800
                        ui-active:text-red-900 relative cursor-pointer select-none
                        border-b border-gray-100 px-3 py-2 text-gray-900
                        transition-all duration-300 ease-linear
                      "
                      value={choice}
                    >
                      <>
                        <span className="ui-selected:font-medium block truncate font-normal">
                          {choice.label}
                        </span>

                        <span
                          className="
                            ui-active:text-red-700 ui-selected:flex absolute inset-y-0
                            hidden items-center pl-1.5 ltr:right-4 rtl:left-4
                          "
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      </>
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  );
}
