import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';

export const CardWithActionsWrapper = styled.div`
  ${tw`
    relative
    !rounded-lg
    rounded-tl-lg
    rounded-tr-lg
    bg-white
    p-6
    sm:rounded-tr-none
  `};
`;

export const CardActionWrapper = styled(motion.button)`
  ${tw`
    absolute
    flex
    cursor-pointer
    rounded-lg
    ltr:right-6
    rtl:left-6
    top-6
    p-2
    text-gray-300
    opacity-80

    hover:(bg-red-100 text-red-700)
  `};
`;

export const SuccessMessageWrapper = styled(motion.div)`
  ${tw`
    absolute
    right-20
    top-8
    flex
    cursor-pointer
    rounded-xl
    // bg-teal-600
    bg-green-100
    px-4
    py-1
    text-xs
    text-white
    text-teal-900
  `};
`;

export const TickWrapper = styled(motion.div)`
  ${tw`
    absolute
    right-1.5
    top-1.5
  `};
`;
