import { IInputElement } from '../../types';

import { InputTextElement } from '../../form-inputs';

type TFirstNameInputFieldProps = Pick<
  IInputElement,
  | 'className'
  | 'classNameInput'
  | 'classNameLabel'
  | 'error'
  | 'label'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'value'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture the first name of a person or organization,
 * and tries to add constraints that are used to validate the expected format of
 * a valid person's first name.
 */
export function FirstNameInputField({
  className,
  classNameInput,
  classNameLabel,
  error,
  label,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  value,
}: TFirstNameInputFieldProps) {
  return (
    <InputTextElement
      constraints={{
        feedbackLabel: 'First Name',
        maxLength: 50,
        minLength: 2,
        required: true,
        format: 'person-name',
      }}
      className={className}
      classNameInput={classNameInput}
      classNameLabel={classNameLabel}
      error={error}
      hasClearButton
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder || 'Please enter a first name'}
      showIsRequired={false}
      type="text"
      value={value}
    />
  );
}
