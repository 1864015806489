import { useRef } from 'react';

interface IDeviceInfo {
  appVersion: '0.0.0';
  locale: 'en' | 'ar' | 'cn';
  vendor: string;
  model: string;
  platform: string;
}

interface IUseDeviceInfoOptions {
  locale: 'en' | 'ar';
}

/**
 * -----------------------------------------------------------------------------
 * Retrieves the device info that is later posted alongside the auth requests.
 */
export const useDeviceInfo = ({ locale }: IUseDeviceInfoOptions) => {
  const deviceInfoRef = useRef<IDeviceInfo>();

  if (!deviceInfoRef.current?.vendor) {
    if (global?.navigator) {
      deviceInfoRef.current = {
        appVersion: '0.0.0',
        locale,
        vendor: navigator.vendor,
        model: navigator.userAgent,
        platform: navigator.platform,
      };
    }
  }

  return deviceInfoRef.current;
};
