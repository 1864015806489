import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This contains the styles for the form feedback section that either styles as
 * an info tip or an error block.
 */
export const FormFeedbackSectionContainer = styled.p`
  ${tw`
    font-normal
    text-red-500
    py-3
    transition-all
    duration-300
    ease-in-out
    dark:text-teal-700
  `};

  .message {
    ${tw`block text-sm text-teal-800`}
  }

  .initial-description {
    ${tw`
      text-base
      font-normal
      text-black
    `}
  }

  .description {
    ${tw`text-sm font-light`}
  }

  &.feedback-error {
    .message {
      ${tw`text-red-500`}
    }

    .description {
      ${tw`text-red-700`}
    }
  }
`;
