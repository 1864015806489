import {
  isToday,
  isYesterday,
  differenceInSeconds,
  differenceInMinutes,
  isSameYear,
  format,
  subDays,
} from 'date-fns';

export type TimeRangeType = 'TODAY' | 'YESTERDAY' | 'EARLIER';

export const getDateLabel = (date: number | Date): TimeRangeType => {
  if (isToday(date)) {
    return 'TODAY';
  }
  if (isYesterday(date)) {
    return 'TODAY';
  }
  return 'EARLIER';
};

interface IGetHumanReadableTimeStampProps {
  timeRange: TimeRangeType;
  timestamp: number | Date;
}

export const getHumanReadableTimeStamp = ({
  timeRange,
  timestamp,
}: IGetHumanReadableTimeStampProps): string => {
  const now = Date.now();
  // ToDo:: Regenerate relativity if none is provided
  switch (timeRange) {
    case 'TODAY': {
      const diff = differenceInMinutes(now, timestamp);
      if (diff < 1) {
        const diffInSec = differenceInSeconds(now, timestamp);
        return `${diffInSec} second${diffInSec !== 1 ? 's' : ''} ago`;
      }
      if (diff < 60) {
        return `${diff} minute${diff !== 1 ? 's' : ''} ago`;
      }
      return format(timestamp, 'p');
    }
    case 'YESTERDAY': {
      return `Yesterday, ${format(timestamp, 'p')}`;
    }
    default: {
      // Case EARLIER
      if (!isSameYear(now, timestamp)) {
        return format(timestamp, 'PP');
      }
      return format(timestamp, 'LLL do, p');
    }
  }
};

/**
 * This function converts Date to formatted string eg: "2022/12/13"
 *
 * @param date
 * @returns string
 */
export const getFormattedDate = (date: Date, formatString?: string): string =>
  format(date, formatString || 'yyyy-MM-dd');
/**
 * This function subtracts current date with given days count
 *
 * @param days
 * @returns string
 */
export const getSubDate = (days: number): string => {
  if (days === 0) return '';

  const subDate = subDays(new Date(), days);

  return getFormattedDate(subDate);
};

/**
 * Gets a human readable relative date from the timestamp.
 *
 * @param date number - The date in milliseconds
 * @returns string - The human relative date.
 */
export const convertToHumanReadableTimestamp = (date?: number | Date) => {
  let timestamp = '';

  if (date) {
    timestamp = getHumanReadableTimeStamp({
      timeRange: getDateLabel(date),
      timestamp: date,
    });
  }

  return timestamp;
};

export { isToday, isYesterday, format };
