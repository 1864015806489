import Link from 'next/link';

import { useTranslations } from '@keymono/i18n';

import { LogoKeymono } from '../../brand';

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
    // { name: 'Analytics', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Guides', href: '#' },
    // { name: 'Documentation', href: '#' },
    // { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Partners', href: '#' },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
    // { name: 'Claim', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: () => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="h-6 w-6 hover:fill-blue-600"
        >
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: () => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="h-6 w-6 hover:fill-blue-400"
        >
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

/**
 * -----------------------------------------------------------------------------
 * The main footer of the public facing applications.
 */
export function PublicFooter() {
  const t = useTranslations('Common');

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div>
            <Link aria-label={t('logoAriaLabel')} href="/">
              <LogoKeymono />
            </Link>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Solutions
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-red-700"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Support
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-red-700"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Company
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-red-700"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-red-700"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
          </div>
          <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              className="
                w-full min-w-0 appearance-none rounded-md border-gray-300
                bg-white px-[calc(theme(spacing.3)-1px)]
                py-[calc(theme(spacing[1.5])-1px)] text-base leading-7
                text-gray-900 placeholder-gray-400 shadow-sm focus:border-red-200
                focus:ring-red-300 sm:w-56 sm:text-sm sm:leading-6
              "
              placeholder="Enter your email"
            />
            <div
              className="
                mt-4 rounded-md rtl:ml-auto rtl:mr-4  sm:ml-4 sm:mt-0
                sm:flex-shrink-0
              "
            >
              <button
                type="submit"
                className="
                  flex w-full items-center justify-center rounded-md bg-red-600
                  px-3 py-1.5 text-base font-semibold leading-7 text-white
                  hover:bg-red-500 focus-visible:outline focus-visible:outline-2
                  focus-visible:outline-offset-2 focus-visible:outline-red-600
                  sm:text-sm sm:leading-6
                "
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div
          className="
            mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center
            md:justify-between
          "
        >
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-red-700"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
            {t('footer.copyright')}

            <p>{t('footer.version', { version: '1.0.0' })}</p>
          </p>
        </div>
      </div>
    </footer>
  );
}
