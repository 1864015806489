import { useRouter } from 'next/router';

import { TLocale } from '../../types';
import { languagesConfig, languagesConfigByLocale } from '../../localesConfig';

import { LocaleContainer } from './styles';
import { LocaleMenu } from './LocaleMenu';
import { SetLocaleCTA } from './SetLocaleCTA';

interface ILocaleSwitcherProps {
  /**
   * The class to be passed down the the container parent element.
   */
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a floating menu dropdown (incase multi-languages are supported),
 * or nav link (for just duo language support) that's used in selecting the
 * preferred language by the user.
 *
 * @returns JSX.Element
 */
export function LocaleSwitcher({ className = '' }: ILocaleSwitcherProps) {
  const { locale, pathname, query, asPath } = useRouter();

  const activeLanguage = languagesConfigByLocale?.[locale as TLocale];

  /**
   * To remove the already selected country and display the rest.
   */
  const selectableLanguages = languagesConfig.filter(
    (language) => language.locale !== locale
  );

  /**
   * For only 2 locales, will just toggle between, otherwise show a dropdown.
   */
  const isDuoLocale = selectableLanguages.length === 1;

  return (
    <LocaleContainer
      className={` font-primary-en ltr:font-primary-ar ${className}
      `}
    >
      {
        /**
         * Render a single nav link button to change the locale if only one extra
         * locale is present. Otherwise render a dropdown menu to choose from.
         */
        isDuoLocale ? (
          <SetLocaleCTA
            language={selectableLanguages[0]}
            href={{ pathname, query }}
            asPath={asPath}
          />
        ) : (
          <LocaleMenu
            items={selectableLanguages}
            value={activeLanguage}
            href={{ pathname, query }}
            asPath={asPath}
          />
        )
      }
    </LocaleContainer>
  );
}
