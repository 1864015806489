import { ErrorInfo } from 'react';

// eslint-disable-next-line no-shadow
export enum ErrorEventsEnum {
  ERROR_IN_APP = '🅰️ - Application Wide Error',
  ERROR_IN_APP_INITIALIZATION = '⭕️ - Error while initializing the application',
  ERROR_IN_SCREEN_OR_PAGE = '🚩 - Error Caught by the Screen or Page',
  ERROR_IN_API_CALL = '🚨 - Error in API call',
  ERROR_IN_ATTACHMENT = '📎 - Error Image/File Attachment',
  ERROR_IN_PAYMENT = '🏦 - Error in Payment Processing',
  ERROR_IN__LOCAL_OR_ASYNC_STORAGE = '💾 - Error in Persisted Storage',
  ERROR_IN_PERMISSION = '🔐 - Error in Accessing Permissions',
  ERROR_IN_PDF = '📜 - Error in PDF',
}

export type TErrorEventsKey = keyof typeof ErrorEventsEnum;

export interface IErrorLogToRemoteUtilOptions {
  error: Error | unknown;
  errorCode: ErrorEventsEnum;
  errorTitle?: string;
  message?: string;
  params?: Record<string, string | number>;
}

export interface ILogDevicePermissionErrorArgs {
  message?: string;
  shouldOpenSettings?: string;
}

export interface IErrorLoggerOptions {
  error: Error | unknown;
  errorCode: ErrorEventsEnum;
  errorInfo?: Record<string, unknown> | ErrorInfo;
  errorTitle?: string;
  message?: string;
  handleAsCrash?: boolean;
}
