import { ReactNode, useState, useRef } from 'react';
import {
  Placement,
  arrow,
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';

import { useI18n } from '@keymono/i18n';

export function TooltipUIOld() {
  const { x, y, strategy, refs } = useFloating();

  return (
    <>
      <button type="button" ref={refs.setReference}>
        BUTTON
      </button>
      <div
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          width: 'max-content',
        }}
      >
        Tooltip
      </div>
    </>
  );
}

interface ITooltipUIProps {
  children: ReactNode;
  content: ReactNode;
  placement?: Placement;
}

// const arrowElement = window ? document.querySelector('#arrow') : null;

export function TooltipUI({ children, content, placement }: ITooltipUIProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useI18n();

  const arrowElement = useRef<Element>(document.querySelector('#arrow'));

  const defaultPlacement = language.isRTL ? 'left' : 'right';

  const { x, y, strategy, refs, context } = useFloating({
    placement: placement || defaultPlacement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), arrow({ element: arrowElement })],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div {...getReferenceProps()} ref={refs.setReference}>
        {children}
      </div>

      {isOpen && (
        <div
          {...getFloatingProps()}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
          }}
        >
          <div id="arrow" className="absolute" />
          {content}
        </div>
      )}
    </>
  );
}
