import { TSvgComponentProps } from '../../types';

import { SVGContainer } from './styles';

/**
 * -----------------------------------------------------------------------------
 * Shows gradient shapes used as backgrounds.
 */
export function BackgroundForceField({ className }: TSvgComponentProps) {
  return (
    <SVGContainer
      // xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 900"
      xmlSpace="preserve"
      width="100rem"
      height="56.25rem"
      className={className}
    >
      <linearGradient
        id="a"
        x1={498.91}
        x2={498.91}
        y1={837.875}
        y2={838.875}
        gradientTransform="matrix(-50.214 45.3007 46.4156 40.8642 -12740.404 -56388.066)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff8383" />
        <stop offset={1} stopColor="red" />
      </linearGradient>
      <path
        className="shimmer-path"
        id="triangle"
        fill="none"
        stroke="url(#a)"
        strokeMiterlimit={8.56}
        strokeWidth={2.825}
        d="m1141.2 473.3 13.7-68.2 51.8 45.4-65.5 22.8z"
      />
      <linearGradient
        id="b"
        x1={498.83}
        x2={498.83}
        y1={839.848}
        y2={840.848}
        gradientTransform="scale(-78.08 78.08) rotate(35.6 1553.672 -361.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#8b2a2a" />
        <stop offset={0.5} stopColor="#2a8b71" />
        <stop offset={1} stopColor="red" />
      </linearGradient>
      <circle
        className="spin-path"
        cx={298.5}
        cy={126.3}
        id="cirlce"
        r={42}
        fill="none"
        stroke="url(#b)"
        strokeMiterlimit={9.76}
        strokeWidth={1.074}
      />
      <linearGradient
        id="c"
        x1={486.83}
        x2={486.83}
        y1={838.706}
        y2={839.706}
        gradientTransform="matrix(52.239 54.6524 13.8966 -49.3891 -36728.441 15657.969)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff8383" />
        <stop offset={1} stopColor="red" />
      </linearGradient>
      <path
        className="shimmer-path delay-5"
        fill="none"
        stroke="url(#c)"
        strokeMiterlimit={9.76}
        strokeWidth={3.221}
        d="m341.5 749.3 40.9 70.8c-19.5 11.3-44.5 4.6-55.8-15s-4.7-44.5 14.9-55.8z"
      />
      <linearGradient
        id="d"
        x1={494.948}
        x2={494.948}
        y1={838.566}
        y2={837.566}
        gradientTransform="matrix(-3.2722 92.223 66.2575 12.8256 -53455.723 -55958.41)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="red" />
        <stop offset={1} stopColor="#03ff96" />
      </linearGradient>
      <path
        className="pulsate-path"
        fill="none"
        stroke="url(#d)"
        strokeMiterlimit={10.48}
        strokeWidth={1.048}
        d="m447 389.6-26.8 50.5 38.5 42.2 26.8-50.5-38.5-42.2z"
      />
      <linearGradient
        id="e"
        x1={493.301}
        x2={493.301}
        y1={850.823}
        y2={849.823}
        gradientTransform="scale(100 -100) rotate(69.8 866.318 76.842)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="red" />
        <stop offset={1} stopColor="#03ff96" />
      </linearGradient>
      <path
        className="shimmer-path delay-3"
        fill="none"
        stroke="url(#e)"
        strokeMiterlimit={10}
        strokeWidth={2.2}
        d="M1111 565h100v100h-100z"
        transform="rotate(-82.802 1160.974 615.025)"
      />
      <linearGradient
        id="f"
        x1={490.908}
        x2={490.908}
        y1={838.637}
        y2={837.637}
        gradientTransform="matrix(13.3718 54.1683 60.5705 -19.096 -56269.617 -10333.783)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="red" />
        <stop offset={1} stopColor="#03ff96" />
      </linearGradient>
      <path
        className="shimmer-path"
        fill="none"
        stroke="url(#f)"
        strokeMiterlimit={7.6}
        strokeWidth={0.76}
        d="m1089.1 237.4-.7 32-28 15.4-27.3-16.6.7-31.9 28-15.4 27.3 16.5z"
      />
    </SVGContainer>
  );
}
