import { IBaseUIFallbackOptions } from '../../types';

import { GenericFallbackError } from './GenericFallbackError';

interface IAsyncComponentFallbackTemplateProps extends IBaseUIFallbackOptions {}

/**
 * -----------------------------------------------------------------------------
 * This provides reusable UI logic for rendering fallback screen when an
 * application errors or it's loading async data.
 */
export function AsyncComponentFallbackTemplate({
  isLoading,
  error,
  onRetryAction,
  title,
  ErrorComponent,
  LoadingComponent,
}: IAsyncComponentFallbackTemplateProps) {
  /**
   * First show the error if it's available, errors treated as first class citizens.
   */
  if (error) {
    if (ErrorComponent) {
      return <ErrorComponent error={error} onRetryAction={onRetryAction} />;
    }

    return (
      <GenericFallbackError
        error={error}
        isLoading={isLoading}
        onRetryAction={onRetryAction}
        title={title}
      />
    );
  }

  /**
   * If there is not error and the app is loading, show the loading state
   * otherwise do not show anything for the data below to show through.
   */
  return isLoading ? <LoadingComponent /> : null;
}
