import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * This renders a rectangular UAE National Flag SVG component so it can easily be
 * customized at runtime.
 *
 * @param {{size: number}}
 */
export function FlagUAE({ size = 32, ...rest }: TSvgComponentProps) {
  return (
    <svg
      id="FlagUAE"
      height={size}
      width={size * 1.5}
      viewBox="0 0 512 340"
      {...rest}
    >
      <g id="uae">
        <path id="top-green" d="M0 0h512v340H0z" className="fill-green-700" />
        <path
          id="white-middle"
          d="M0 113.3h512V340H0z"
          className="fill-white"
        />
        <path
          id="bottom-black"
          d="M0 226.7h512V340H0z"
          className="fill-black"
        />
        <path id="left-red" d="M0 0h128v340H0z" className="fill-red-600" />
      </g>
    </svg>
  );
}
