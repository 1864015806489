import { IAddNewSessionOptions, TSet } from '@keymono/shared-types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This adds a new session instance to track across the app.
 */
export function addNewSessionCallback(set: TSet) {
  let sessionIndex = 0;

  return function updateAuthStore({
    user,
    locale,
    token,
    expiry,
    refreshToken,
    rememberMe,
  }: IAddNewSessionOptions) {
    set(
      ({
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
        sessionsUnderSignOut,
      }) => {
        const isSessionExisting = allSessionsById
          ? allSessionsById[user.id]
          : null;

        const sessionsCount: number = allSessionsIndices.length || 0;
        sessionIndex = sessionsCount === 0 ? 0 : sessionsCount + 1;

        if (isSessionExisting) {
          sessionIndex = isSessionExisting.index;
        }

        const updatedActiveSession = {
          index: sessionIndex,
          locale,
          // Don't save the fresh token if the user does not want to remember session.
          refreshToken: rememberMe ? refreshToken : '',
          rememberMe,
          token,
          expiry,
          user,
          userId: user.id,
          isExpired: false,
        };

        const updatedAllSessionsIndices = isSessionExisting
          ? [...allSessionsIndices]
          : [...allSessionsIndices, sessionIndex];
        const updatedActivityLog = {
          ...(activityLog || {}),
          lastLoginDate: new Date().getTime(),
        };

        const updatedAllSessionsByIndex = {
          ...(allSessionsByIndex || {}),
          [sessionIndex]: updatedActiveSession,
        };
        const updatedAllSessionsById = {
          ...(allSessionsById || {}),
          [user.id]: updatedActiveSession,
        };

        const updatedStoreFields = {
          activeSession: updatedActiveSession,
          activityLog: updatedActivityLog,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          allSessionsIndices: updatedAllSessionsIndices,
          sessionsUnderSignOut,
        };

        persistAuthStore(updatedStoreFields);

        return updatedStoreFields;
      }
    );

    return sessionIndex;
  };
}
