import { useState } from 'react';
// import { LockOpenIcon } from '@heroicons/react/20/solid';

import { IInputElement } from '../../types';
import { InputTextElement } from '../../form-inputs';

import { PasswordToggleBtn } from './PasswordToggleBtn';

type TPasswordInputFieldProps = Pick<
  IInputElement,
  | 'editable'
  | 'error'
  | 'label'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'value'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture the password of a person or organization,
 * and tries to add constraints that are used to validate the expected format of
 * a valid password (with max & min length).
 */
export function PasswordInputField({
  editable,
  error,
  label,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  value,
}: TPasswordInputFieldProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePassword = () => {
    setIsPasswordVisible((isVisible) => !isVisible);
  };

  return (
    <InputTextElement
      constraints={{
        feedbackLabel: 'The password',
        maxLength: 40,
        minLength: 8,
        required: true,
        type: 'password',
      }}
      editable={editable}
      error={error}
      // Testing for the leading icon input format
      // iconStart={<LockOpenIcon className="h-5 w-5" />}
      iconEnd={
        <PasswordToggleBtn
          isPasswordVisible={isPasswordVisible}
          onTogglePassword={handleTogglePassword}
        />
      }
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder || '... your account password here'}
      showIsRequired={false}
      type={isPasswordVisible ? 'text' : 'password'}
      value={value}
    />
  );
}
