import { ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';

import { THeroIcon } from '../../../types';

import { TickAnimation } from '../../animations';

import {
  CardActionWrapper,
  CardWithActionsWrapper,
  SuccessMessageWrapper,
  TickWrapper,
} from '../styles';

interface ICardAction {
  className?: string;
  Icon: THeroIcon;
  title: string;
  onClick: () => void;
}

interface ICardIcon {
  Icon: THeroIcon;
  className: string;
}

interface ICardIconProps {
  options: ICardIcon;
}

/**
 * -----------------------------------------------------------------------------
 * Renders the primary card icon that uniquely identifies the card.
 */
function CardIcon({ options }: ICardIconProps) {
  const { Icon, className } = options;

  return (
    <div className="mb-6">
      <span
        className={`inline-flex rounded-lg p-3 ring-4 ring-white ${className}`}
      >
        <Icon className="h-6 w-6" aria-hidden="true" />
      </span>
    </div>
  );
}

interface ICardActionsProps {
  actions: ICardAction[];
  isVisible: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * Shows the actions to be rendered on the card item to either route or update
 * the content in the card.
 */
function CardActions({ actions, isVisible }: ICardActionsProps) {
  return (
    <AnimatePresence>
      {isVisible &&
        actions.map(({ className = '', Icon, onClick, title }) => (
          <CardActionWrapper
            key={title}
            aria-hidden="true"
            className={`
              form-link-action
              group-hover:text-red-700
              group-hover:opacity-100
              ${className}
            `}
            onClick={onClick}
            title={title}
            type="button"
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <Icon className="h-6 w-6" />
          </CardActionWrapper>
        ))}
    </AnimatePresence>
  );
}

interface ICardSuccessPopupProps {
  isVisible: boolean;
  onHide?: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Shows the actions pop that is triggered when the actions on the card have
 * completed an async event successfully.
 */
function CardSuccessPopup({ isVisible, onHide }: ICardSuccessPopupProps) {
  return (
    <AnimatePresence>
      {isVisible ? (
        <>
          <SuccessMessageWrapper
            key="success-inline-text"
            initial={{ opacity: 0, x: 60 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 40 }}
            transition={{ duration: 0.5 }}
          >
            <span className="">Updated Successfully</span>
          </SuccessMessageWrapper>
          <TickWrapper
            className="absolute right-1.5 top-1.5"
            key="check-mark"
            initial={{ opacity: 0.8 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            onAnimationComplete={onHide}
          >
            <TickAnimation className="h-20 w-20" />
          </TickWrapper>
        </>
      ) : null}
    </AnimatePresence>
  );
}

interface ICardWithActionProps {
  actions: ICardAction[];
  cardIcon: ICardIcon;
  children: ReactNode;
  className?: string;
  isSuccessVisible: boolean;
  onHideSuccessPopup?: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Rends a card with additional action points such as options to edit the content
 * of the card.
 */
export function CardWithActions({
  actions,
  cardIcon,
  children,
  className = '',
  isSuccessVisible,
  onHideSuccessPopup,
}: ICardWithActionProps) {
  return (
    <CardWithActionsWrapper className={`group ${className}`}>
      <CardIcon options={cardIcon} />
      {children}
      <CardActions actions={actions} isVisible={!isSuccessVisible} />
      <CardSuccessPopup
        isVisible={isSuccessVisible}
        onHide={onHideSuccessPopup}
      />
    </CardWithActionsWrapper>
  );
}
