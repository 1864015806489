import React from 'react';
import { Transition, Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface IIDropdownMenuOption {
  key: string;
  label: string;
}

interface IDropdownMenuProps {
  buttonClassName?: string;
  containerClassName?: string;
  dropdownClassName?: string;
  dropdownStyle?: 'button' | 'link';
  label?: string;
  icon?: JSX.Element;
  onChange?: (option: IIDropdownMenuOption) => void;
  options: IIDropdownMenuOption[];
  defaultOption?: IIDropdownMenuOption;
}

/**
 * -----------------------------------------------------------------------------
 * A Button with a dropdown menu that contains Select options.
 * No option is selected by default.
 * Also renders a toggle button
 */
export function DropdownMenu({
  buttonClassName = '',
  containerClassName = '',
  dropdownClassName = '',
  dropdownStyle = 'button',
  label = 'Sort by',
  icon: DropdownIcon = <ChevronDownIcon width={16} />,
  onChange = () => {},
  defaultOption = {
    key: '',
    label,
  },
  options,
}: IDropdownMenuProps) {
  const [selectedItem, setSelectedItem] = React.useState(defaultOption);

  function handleOnSelect(option: IIDropdownMenuOption) {
    setSelectedItem(option);
    onChange(option);
  }

  return (
    <Listbox value={selectedItem} onChange={handleOnSelect}>
      {
        // eslint-ignore-next-line react/jsx-no-bind
        ({ open }) => (
          <div className={`relative ${containerClassName}`}>
            <Listbox.Button
              className={
                dropdownStyle === 'button'
                  ? `form-btn relative flex w-max flex-1 items-center gap-x-2 rounded-lg border border-gray-100 bg-white text-gray-500  hover:bg-white hover:text-gray-900 focus:border-0 focus:outline-none ${buttonClassName}`
                  : `flex w-max items-center gap-x-2 text-sm font-medium ${buttonClassName}`
              }
            >
              <span className="block truncate rtl:text-right ">
                {selectedItem.label}
              </span>
              {DropdownIcon}
            </Listbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`
                absolute z-10 mt-1 max-h-60 w-full overflow-auto
                rounded-b-lg bg-white py-1 text-xs shadow-md shadow-gray-200
                ring-1 ring-gray-700 ring-opacity-5 focus:outline-none
                ${dropdownClassName}
              `}
              >
                {options.map((choice) => (
                  <Listbox.Option
                    key={choice.key}
                    className="
                    ui-active:bg-red-100  ui-active:text-red-900 relative
                    cursor-default select-none px-5 py-2 text-start text-gray-900
                    transition-all duration-300 ease-linear
                  "
                    value={choice}
                  >
                    <span className="ui-selected:font-medium block truncate text-sm font-normal text-gray-500">
                      {choice.label}
                    </span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )
      }
    </Listbox>
  );
}
