import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import '@keymono/design-system/src/theme/styles/css-imports.css';

import { SiteMetaData } from '@keymono/design-system';

import {
  LocaleProvider,
  useUpdateLocaleDir,
  TLocale,
  NextIntlProvider,
} from '@keymono/i18n';
import { ReactQueryProvider, useAuthentication } from '@keymono/services';

/**
 * -----------------------------------------------------------------------------
 * This is the main entry point of our application. The root layout of all pages.
 */
function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const locale = router.locale as TLocale;
  const accountId = (router.query.accountId || '0') as string;

  // TODO: Reload page when translation is changed to to update the links.

  useUpdateLocaleDir({ locale });
  useAuthentication({
    locale,
    accountId: parseInt(accountId, 10) || 0,
  });

  return (
    <NextIntlProvider messages={pageProps.messages}>
      <ReactQueryProvider>
        <LocaleProvider>
          <Head>
            <SiteMetaData
              appName="Keymono | Console"
              locale={locale}
              description="Orchestrating the noisy world of eCommerce for brands - in one platform. Unify and elevate your performance with Keymono."
              title="Keymono | Console eCommerce App"
            />
          </Head>
          <Component {...pageProps} />
        </LocaleProvider>
      </ReactQueryProvider>
    </NextIntlProvider>
  );
}

export default App;
