import tw, { styled } from 'twin.macro';

/**
 * Base wrapping styled component around the app logo.
 */
export const StyledSVGLogo = styled.svg`
  ${tw`
    h-8
    w-36
    fill-red-600
    hover:(fill-red-700)
    focus:(outline-none)
    focus-visible:(outline-2 outline-offset-1 outline-red-600)
  `};
`;

/**
 * Base wrapping styled component around the app logo.
 */
export const StyledSVGLogomark = styled.svg`
  ${tw`
    h-8
    w-8
    rounded-md
    p-0.5
    fill-red-600
    hover:(fill-red-700)
    focus:(outline-none)
    focus-visible:(outline-2 outline-offset-1 outline-red-600)
  `};
`;
